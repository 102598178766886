import React from 'react';
import { func } from 'prop-types';
import { SVG_TYPE, VALIDATION_MESSAGES } from 'shared/consts';
import { RegularField, SVG } from 'shared/components';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';

const EditCategory = ({ editCategory, category, setManageSubcategoryForm }) => {
  const { REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const validationForm = () =>
    object().shape({
      link: string().required(REQUIRED_FIELD),
      name: string().required(REQUIRED_FIELD),
    });

  const submitForm = async (values, actions) => {
    const response = await editCategory(category.uuid, values);
    if (response.link_exist && response.name_exist) {
      actions.setErrors({ name: 'Nazwa zajęta', link: 'Adres url zajęty' });
    } else if (response?.name_exist) {
      actions.setErrors({ name: 'Nazwa zajęta' });
    } else if (response?.link_exist) {
      actions.setErrors({ link: 'Url zajety' });
    } else if (response?.status === 'success') {
      setManageSubcategoryForm(false);
    }
  };

  return (
    <>
      <ul>
        <Formik
          initialValues={{ name: category?.name, link: category?.link }}
          onSubmit={submitForm}
          validationSchema={validationForm()}
        >
          {({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit} className="new-category-form edit-category-form">
              <Field
                component={RegularField}
                errors={errors}
                name="name"
                placeholder="Nazwa kategorii "
                type="text"
                fullWidth
                icon={SVG_TYPE.CATEGORY}
              />
              <Field
                component={RegularField}
                errors={errors}
                name="link"
                placeholder="link"
                type="text"
                fullWidth
                icon={SVG_TYPE.URL}
              />
              <div
                className="subcategory__item--single-action edit-category-form--margin"
                onClick={() => setManageSubcategoryForm(false)}
              >
                <SVG type={SVG_TYPE.PLUS} className="subcategory__item--single-action--plus-rotate" />
              </div>
              <div className="subcategory__item--single-action" onClick={handleSubmit}>
                <SVG type={SVG_TYPE.EDIT} />
              </div>
            </form>
          )}
        </Formik>
      </ul>
    </>
  );
};

export default EditCategory;

EditCategory.propTypes = {
  category: func.isRequired,
  editCategory: func.isRequired,
  setManageSubcategoryForm: func.isRequired,
};
