import React, { useState } from 'react';

const InputCounter = () => {
  const [amount, setAmount] = useState(1);

  const increaseAmount = () => {
    setAmount(amount + 1);
  };
  const decreaseAmount = () => {
    setAmount(amount - 1);
  };

  return (
    <div className="input-counter">
      <div className={`decrease button ${amount === 1 ? 'button--disabled' : ''}`} onClick={decreaseAmount}>
        -
      </div>
      <input className="input" type="text" value={amount} />
      <div className="increase button" onClick={increaseAmount}>
        +
      </div>
    </div>
  );
};
export default InputCounter;
