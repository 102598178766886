import React, { useState } from "react";
import { func, shape, bool } from "prop-types";
import { Modal } from "shared/components";
import { LOGIN_MANAGE_TYPE } from "shared/consts";
import {
  LoginForm,
  RegistrationForm,
  NewPasswordForm,
  SetPasswordForm,
  ForgotPasswordForm,
} from "./components";

const { LOGIN, REGISTRATION, FORGOT_PASSWORD, RESET_PASSWORD, NEW_PASSWORD } =
  LOGIN_MANAGE_TYPE;

const LoginManage = ({
  actions: {
    registerUser,
    closeModal,
    login,
    sendResetPasswordEmail,
    setNewPassword,
  },
  loginManageActions,
  isModalOpened,
  setLoadingLogin,
}) => {
  const [modalType, setModalType] = useState(loginManageActions);
  const isLoginView = LOGIN === modalType;
  const isRegistrationView = REGISTRATION === modalType;
  const isForgotPasswordView = FORGOT_PASSWORD === modalType;
  const isResetPasswordView = RESET_PASSWORD === modalType;
  const isNewPasswordView = NEW_PASSWORD === modalType;

  const changeModalType = (type) => {
    setModalType(type);
  };

  return (
    <Modal
      opened={isModalOpened}
      className="loginManage"
      closeModal={closeModal}
    >
      {isLoginView && (
        <LoginForm
          setLoadingLogin={setLoadingLogin}
          closeModal={closeModal}
          login={login}
          changeModalType={changeModalType}
        />
      )}
      {isRegistrationView && (
        <RegistrationForm
          registerUser={registerUser}
          changeModalType={changeModalType}
        />
      )}
      {isForgotPasswordView && (
        <ForgotPasswordForm
          closeModal={closeModal}
          sendResetPasswordEmail={sendResetPasswordEmail}
          changeModalType={changeModalType}
        />
      )}
      {isResetPasswordView && (
        <SetPasswordForm changeModalType={changeModalType} />
      )}
      {isNewPasswordView && (
        <NewPasswordForm
          closeModal={closeModal}
          setNewPassword={setNewPassword}
          changeModalType={changeModalType}
        />
      )}
    </Modal>
  );
};

export default LoginManage;

LoginManage.propTypes = {
  actions: shape({
    sendResetPasswordEmail: func.isRequired,
    userRegister: func.isRequired,
    login: func.isRequired,
    setLoadingLogin: func.isRequired,
  }).isRequired,
  closeModal: func.isRequired,
  isModalOpened: bool.isRequired,
  loginManageActions: func.isRequired,
};
