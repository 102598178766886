/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { array, object, func, shape, string } from "prop-types";
import React, { useState, useEffect } from "react";
import { SVG } from "shared/components";
import { SVG_TYPE, VALIDATION_MESSAGES } from "shared/consts";

const ProductDelivery = ({
  deliveries,
  errors,
  deliveryArr,
  setDeliveryArr,
  setFieldValue,
  form,
  field: { name },
  initialValues,
}) => {
  const [showMore, setShowMore] = useState(false);
  const isError = !!(form.touched[name] && errors[name]);

  const handleClick = (item, active) => {
    console.log(item);
    const isChecked = deliveryArr.find((element) => element.uuid === item.uuid);
    if (isChecked && !active) {
      const filtered = deliveryArr.filter(
        (element) => element.uuid !== item.uuid
      );
      setDeliveryArr(filtered);
      if (filtered.length > 0) {
        setFieldValue("deliveries", filtered);
      } else {
        setFieldValue("deliveries", "");
      }
    } else {
      setDeliveryArr([...deliveryArr, item]);
      setFieldValue("deliveries", [...deliveryArr, item]);
    }
  };

  const isActive = (item) => {
    const inArray = deliveryArr.find((element) => element.uuid === item.uuid);
    if (inArray) {
      return "delivery-product__item--active";
    }
    return " ";
  };

  const changePrice = (item, e) => {
    const findItemInDeliveryArr = deliveryArr.find(
      (element) => element === item
    );
    findItemInDeliveryArr.price = e.target.value;
  };

  useEffect(() => {
    if (deliveryArr.length <= 0 && initialValues.deliveries.length > 0) {
      setDeliveryArr(initialValues.deliveries);
    }
  }, [deliveryArr.length, initialValues.deliveries, setDeliveryArr]);

  return (
    <>
      <div className="delivery-product--heading">Metody dostawy*</div>
      <ul
        className={`delivery-product ${
          isError ? "delivery-product--errors" : ""
        }`}
      >
        {deliveries &&
          deliveries.length > 0 &&
          deliveries.map((item, id) => {
            const hiddenElement = id >= 4 && !showMore;
            return (
              <>
                <li
                  className={`delivery-product__item ${isActive(item)} ${
                    hiddenElement ? "delivery-product__item--hidden" : " "
                  }`}
                >
                  <div className="check" onClick={() => handleClick(item)} />
                  <div className="delivery-product__item--icon--wrapper">
                    <SVG
                      className="delivery-product__item--icon"
                      type={SVG_TYPE.DELIVERY}
                    />
                  </div>
                  <p className="delivery-product__item--label"> {item.name} </p>
                  <p className="delivery-product__item--price">
                    <input
                      type="text"
                      placeholder={item.price}
                      onChange={(e) => changePrice(item, e)}
                      onClick={() => handleClick(item, "active")}
                    />
                    zł
                  </p>
                </li>
              </>
            );
          })}
        {isError && (
          <div className="delivery-product--error">
            {VALIDATION_MESSAGES.REQUIRED}
          </div>
        )}
      </ul>
      {!showMore && (
        <div
          className="delivery-product__show-more"
          onClick={() => setShowMore(true)}
        >
          pokaż wszystkie metody dostaw
        </div>
      )}
    </>
  );
};

export default ProductDelivery;

ProductDelivery.propTypes = {
  deliveries: array.isRequired,
  deliveryArr: array.isRequired,
  errors: object.isRequired,
  field: shape({
    name: string,
  }).isRequired,
  form: object.isRequired,
  initialValues: object.isRequired,
  setDeliveryArr: func.isRequired,
  setFieldValue: func.isRequired,
};
