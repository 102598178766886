import React from "react";
import { PageContainer, SVG, Button } from "shared/components";
import { ROUTES, SVG_TYPE } from "shared/consts";
import { useHistory } from "react-router-dom";

const SendChangePasswordPage = () => {
  const history = useHistory();
  const openLoginForm = () => {
    history.push(ROUTES.HOME_PAGE);
  };

  return (
    <PageContainer full className="inactive">
      <div className="inactive">
        <div className="inactive--content">
          <SVG type={SVG_TYPE.SEND_MAIL_CHECK_ILU} />
          <h1 className="inactive--header">Sprawdź skrzynkę mailową!</h1>
          <h1 className="inactive--paragraph">
            Wysłaliśmy Ci link do zmiany hasła
          </h1>
          <Button
            position="center"
            label="Strona główna"
            className="btn--normal"
            onClick={() => openLoginForm()}
          />
        </div>
      </div>
    </PageContainer>
  );
};
export default SendChangePasswordPage;
