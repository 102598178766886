import React from 'react';
import PropTypes from 'prop-types';
import { LOGIN_MANAGE_TYPE, VALIDATION_MESSAGES, SVG_TYPE } from 'shared/consts';
import { RegularField, Button } from 'shared/components';
import { ModalTitle } from 'shared/components/modal/components';
import { Field, Formik } from 'formik';
import { object, string, ref } from 'yup';

const NewPasswordForm = ({ changeModalType, setNewPassword }) => {
  const { REQUIRED_FIELD, SAME_PASSWORD } = VALIDATION_MESSAGES;
  const { LOGIN } = LOGIN_MANAGE_TYPE;

  const validationForm = () =>
    object().shape({
      confirm_password: string()
        .required(REQUIRED_FIELD)
        .oneOf([ref('new_password'), null], SAME_PASSWORD),
      new_password: string().required(REQUIRED_FIELD),
    });

  const submitForm = async values => {
    const resetToken = sessionStorage.getItem('resetToken');
    const data = { ...values, reset_token: resetToken.replace(/['"]+/g, '') };
    const response = await setNewPassword(data);
    if (response.status === 'success') {
      changeModalType(LOGIN);
    }
  };

  return (
    <>
      <ModalTitle title="Wprowadź nowe hasło" />
      <Formik initialValues={{ new_password: '', password: '' }} onSubmit={submitForm} validationSchema={validationForm()}>
        {({ handleSubmit, errors }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Field
                component={RegularField}
                errors={errors}
                icon={SVG_TYPE.LOCK}
                name="new_password"
                placeholder="Nowe Hasło"
                type="password"
                fullWidth
              />
              <Field
                component={RegularField}
                errors={errors}
                icon={SVG_TYPE.LOCK}
                name="confirm_password"
                placeholder="Powtórz nowe hasło"
                type="password"
                fullWidth
              />

              <Button className="btn--default" label="Zapisz" type="default" position="center-full" />
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default NewPasswordForm;

NewPasswordForm.propTypes = {
  changeModalType: PropTypes.func.isRequired,
  setNewPassword: PropTypes.func.isRequired,
};
