import React, { useState } from "react";
import { SVG } from "shared/components";
import { CloseClickOutside } from "shared/utils";
import { SVG_TYPE, ROUTES } from "shared/consts";
import { Pagination } from "shared/components/table/components/";
import { useHistory } from "react-router-dom";
import Plakat from "assets/files/plakat.png";

// const EVENT_STATUS = {
//   WAITING: "Oczekuje na zatwierdzenie",
//   ACCEPTED: "Zaakceptowane",
//   ATTENTION: "Wymaga korekty",
// };

const EventsTable = () => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    if (isOpen === 1) {
      setIsOpen(false);
    } else {
      setIsOpen(1);
    }
  };

  // const handleRedirect = (uuid) => {
  //   history.push(`${ROUTES.SINGLE_EVENT_PAGE}?id=${uuid}`)
  // }

  return (
    <div className="event-elements">
      <div className="event-element event-element--green">
        <div
          className="event-element--onclick"
          onClick={() =>
            history.push(
              `${ROUTES.SINGLE_EVENT_PAGE}?id=178hausjdau7dg8uhjbmnzx`
            )
          }
        />
        <img className="event-element__image" src={Plakat} alt="" />
        <div className="event-element__descript">
          <div className="event-element__heading">
            FNie zapominaj o młodszych klientach. Warsztaty dla dzieci, na
            których mogą uczyć się podstawowych
          </div>
          <div className="event-element__info">
            <div className="event-element__date">
              Data: <span>22.09.2023</span>
            </div>
            <div className="event-element__crafts">
              Rzemiosło: <span>Wszystkie</span>
            </div>
            <div className="event-element__count">
              Ilość miejsc: <span>4/10</span>
            </div>
          </div>
          <div className="settings-dots">
            <div className="settings-dots__wrapper">
              <div className="settigs-dots__content">
                <div
                  className={`icon ${isOpen ? "icon--disabled" : ""}`}
                  onClick={() => handleClick()}
                >
                  <SVG type={SVG_TYPE.DOTS} />
                </div>
                <CloseClickOutside
                  className="settigs-dots__content"
                  onClose={() => setIsOpen(false)}
                >
                  <ul
                    className={`list view ${
                      isOpen === 1 ? "view--active" : ""
                    }`}
                  >
                    <li className="list--item"> Podgląd </li>
                    <li className="list--item"> Edycja </li>
                    <li className="list--item"> Promowanie wydarzenia </li>
                  </ul>
                </CloseClickOutside>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="event-elements-pagination__wrapper">
        <Pagination page={1} pages="4" />
      </div>
    </div>
    // <div className="events-table__wrapper">
    //   <table className="events-table">
    //     <thead>
    //       <tr className="events-table__head">
    //         <th>Nazwa wydarzenia</th>
    //         <th>Status</th>
    //         <th>Typ</th>
    //         <th>Rzemiosło</th>
    //         <th>Data</th>
    //         <th>wyświetlenia</th>
    //         <th>ilość miejsc</th>
    //         <th></th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       <tr className="events-table__single">
    //         <td>
    //           <div className="events-table--name">
    //             Warsztaty Kowalskie: Sztuka Kowalstwa w Twoich Rękach
    //           </div>
    //         </td>
    //         <td>
    //           <div className="events-table-badge__wrapper">
    //             <span className="events-table-badge events-table-badge--orange">
    //               {EVENT_STATUS.WAITING}
    //             </span>
    //           </div>
    //         </td>
    //         <td>Konkurs</td>
    //         <td>Wszystkie</td>
    //         <td>2023-09-15</td>
    //         <td>30</td>
    //         <td>4/10</td>
    //         <td>
    //           <div className="settings-dots">
    //             <div className="settings-dots__wrapper">
    //               <div className="settigs-dots__content">
    //                 <div
    //                   className={`icon ${isOpen ? "icon--disabled" : ""}`}
    //                   onClick={() => handleClick()}
    //                 >
    //                   <SVG type={SVG_TYPE.DOTS} />
    //                 </div>
    //                 <CloseClickOutside
    //                   className="settigs-dots__content"
    //                   onClose={() => setIsOpen(false)}
    //                 >
    //                   <ul
    //                     className={`list view ${
    //                       isOpen === 1 ? "view--active" : ""
    //                     }`}
    //                   >
    //                     <li className="list--item"> Podgląd </li>
    //                     <li className="list--item"> Edytuj </li>
    //                     <li className="list--item"> Promuj </li>
    //                   </ul>
    //                 </CloseClickOutside>
    //               </div>
    //             </div>
    //           </div>
    //         </td>
    //         <td className="events-table--onclick" onClick={()=> handleRedirect('123981723uhasdSKJDAhiauq78123h')} />
    //       </tr>
    //       <tr className="events-table__single">
    //         <td className="events-table--name">
    //           <div className="events-table--name">
    //             Warsztaty Kowalskie: Sztuka Kowalstwa w Twoich Rękach
    //           </div>
    //         </td>
    //         <td>
    //           <div className="events-table-badge__wrapper">
    //             <span className="events-table-badge events-table-badge--green">
    //               {EVENT_STATUS.ACCEPTED}
    //             </span>
    //           </div>
    //         </td>
    //         <td>Konkurs</td>
    //         <td>Wszystkie</td>
    //         <td>2023-09-15</td>
    //         <td>30</td>
    //         <td>4/10</td>
    //         <td>
    //           <div className="settings-dots">
    //             <div className="settings-dots__wrapper">
    //               <div className="settigs-dots__content">
    //                 <div
    //                   className={`icon ${isOpen ? "icon--disabled" : ""}`}
    //                   onClick={() => handleClick()}
    //                 >
    //                   <SVG type={SVG_TYPE.DOTS} />
    //                 </div>
    //                 <CloseClickOutside
    //                   className="settigs-dots__content"
    //                   onClose={() => setIsOpen(false)}
    //                 >
    //                   <ul
    //                     className={`list view ${
    //                       isOpen === 1 ? "view--active" : ""
    //                     }`}
    //                   >
    //                     <li className="list--item"> Podgląd </li>
    //                     <li className="list--item"> Edytuj </li>
    //                     <li className="list--item"> Promuj </li>
    //                   </ul>
    //                 </CloseClickOutside>
    //               </div>
    //             </div>
    //           </div>
    //         </td>
    //         <td className="events-table--onclick" onClick={()=> handleRedirect} />

    //       </tr>
    //       <tr className="events-table__single">
    //         <td className="events-table--name">
    //           <div className="events-table--name">
    //             Warsztaty Kowalskie: Sztuka Kowalstwa w Twoich Rękach
    //           </div>
    //         </td>
    //         <td>
    //           <div className="events-table-badge__wrapper">
    //             <span className="events-table-badge events-table-badge--red">
    //               {EVENT_STATUS.ATTENTION}
    //             </span>
    //           </div>
    //         </td>
    //         <td>Konkurs</td>
    //         <td>Wszystkie</td>
    //         <td>2023-09-15</td>
    //         <td>30</td>
    //         <td>4/10</td>
    //         <td>
    //           <div className="settings-dots">
    //             <div className="settings-dots__wrapper">
    //               <div className="settigs-dots__content">
    //                 <div
    //                   className={`icon ${isOpen ? "icon--disabled" : ""}`}
    //                   onClick={() => handleClick()}
    //                 >
    //                   <SVG type={SVG_TYPE.DOTS} />
    //                 </div>
    //                 <CloseClickOutside
    //                   className="settigs-dots__content"
    //                   onClose={() => setIsOpen(false)}
    //                 >
    //                   <ul
    //                     className={`list view ${
    //                       isOpen === 1 ? "view--active" : ""
    //                     }`}
    //                   >
    //                     <li className="list--item"> Podgląd </li>
    //                     <li className="list--item"> Edytuj </li>
    //                     <li className="list--item"> Promuj </li>
    //                   </ul>
    //                 </CloseClickOutside>
    //               </div>
    //             </div>
    //           </div>
    //         </td>
    //         <td className="events-table--onclick" onClick={()=> handleRedirect} />
    //       </tr>
    //     </tbody>
    //   </table>
    //   <div className="events-table-pagination__wrapper">
    //     <Pagination page={5} pages="10" />
    //   </div>
    // </div>
  );
};

export default EventsTable;
