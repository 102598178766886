import React, { useEffect } from "react";
import { array, func, shape } from "prop-types";
import { DashboardContent } from "screens/dashboard/components";
import { AddEventForm } from "./components";

const AddEventPage = ({ actions: { getAllCategories }, categories }) => {
  useEffect(() => {
    if (!categories.length > 0) {
      getAllCategories();
    }
  }, [categories.length, getAllCategories]);

  return (
    <DashboardContent goBack name="Dodaj wydarzenie">
      <AddEventForm categories={categories} />
    </DashboardContent>
  );
};

export default AddEventPage;

AddEventPage.propTypes = {
  actions: shape({
    getAllCategories: func.isRequired,
  }).isRequired,
  categories: array.isRequired,
};
