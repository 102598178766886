import React, { Component } from "react";
import Slider from "react-slick";
import Partner1 from "assets/files/partners/partner1.png";
import Partner2 from "assets/files/partners/partner2.png";
import Partner3 from "assets/files/partners/partner3.png";
import Partner4 from "assets/files/partners/partner4.png";
import Partner5 from "assets/files/partners/partner5.png";
import { SVG_TYPE } from "shared/consts";
import { SVG } from "shared/components";

export default class OurPartners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const PrevArrow = (props) => {
      const { onClick } = props;
      return (
        <div onClick={onClick} className={`slideArrow slideArrow--left`}>
          <SVG type={SVG_TYPE.SLIDE_ARROW} />
        </div>
      );
    };
    const showSlides = () => {
      if (window.innerWidth < 800) {
        return 1;
      } else if (window.innerWidth < 1300) {
        return 3;
      } else if (window.innerWidth < 1500) {
        return 5;
      }
    };
    const NextArrow = (props) => {
      const { onClick } = props;
      return (
        <div onClick={onClick} className={`slideArrow slideArrow--right`}>
          <SVG type={SVG_TYPE.SLIDE_ARROW} />
        </div>
      );
    };
    return (
      <div className="home-page__ourPartners">
        <p className="heading">
          {" "}
          Twórz piękne rzeczy dzięki produktom naszych partnerów:{" "}
        </p>
        <div className="content">
          <Slider
            dots={window.innerWidth < 800 ? true : false}
            asNavFor={this.state.nav1}
            ref={(slider) => (this.slider2 = slider)}
            slidesToShow={showSlides()}
            swipeToSlide={true}
            focusOnSelect={true}
            centerMode={window.innerWidth < 800 ? false : true}
            prevArrow={<PrevArrow />}
            nextArrow={<NextArrow />}
          >
            <div className="slider__partner">
              <img src={Partner1} alt="" />
            </div>
            <div className="slider__partner">
              <img src={Partner2} alt="" />
            </div>
            <div className="slider__partner">
              <img src={Partner3} alt="" />
            </div>
            <div className="slider__partner">
              <img src={Partner4} alt="" />
            </div>
            <div className="slider__partner">
              <img src={Partner5} alt="" />
            </div>
            <div className="slider__partner">
              <img src={Partner4} alt="" />
            </div>
            <div className="slider__partner">
              <img src={Partner5} alt="" />
            </div>
          </Slider>
          <Slider
            swipe={false}
            arrows={false}
            asNavFor={this.state.nav2}
            ref={(slider) => (this.slider1 = slider)}
          >
            <div className="slider__descript">
              Purmoll Nasza firma specjalizuje się w dostarczaniu wysokiej
              jakości materiałów ze stali dla różnych branż i zastosowań.
              Posiadamy szeroki wybór produktów, w tym blach, rur, prętów,
              kształtowników i innych elementów z metalu. Nasza oferta jest
              skierowana do klientów indywidualnych oraz przedsiębiorstw z
              różnych sektorów, takich jak budownictwo, przemysł czy produkcja
              maszyn.
            </div>
            <div className="slider__descript">
              Apator Nasza firma specjalizuje się w dostarczaniu wysokiej
              jakości materiałów ze stali dla różnych branż i zastosowań.
              Posiadamy szeroki wybór produktów, w tym blach, rur, prętów,
              kształtowników i innych elementów z metalu. Nasza oferta jest
              skierowana do klientów indywidualnych oraz przedsiębiorstw z
              różnych sektorów, takich jak budownictwo, przemysł czy produkcja
              maszyn.
            </div>
            <div className="slider__descript">
              Vidaron Nasza firma specjalizuje się w dostarczaniu wysokiej
              jakości materiałów ze stali dla różnych branż i zastosowań.
              Posiadamy szeroki wybór produktów, w tym blach, rur, prętów,
              kształtowników i innych elementów z metalu. Nasza oferta jest
              skierowana do klientów indywidualnych oraz przedsiębiorstw z
              różnych sektorów, takich jak budownictwo, przemysł czy produkcja
              maszyn.
            </div>
            <div className="slider__descript">
              Splat Nasza firma specjalizuje się w dostarczaniu wysokiej jakości
              materiałów ze stali dla różnych branż i zastosowań. Posiadamy
              szeroki wybór produktów, w tym blach, rur, prętów, kształtowników
              i innych elementów z metalu. Nasza oferta jest skierowana do
              klientów indywidualnych oraz przedsiębiorstw z różnych sektorów,
              takich jak budownictwo, przemysł czy produkcja maszyn.
            </div>
            <div className="slider__descript">
              Noyals Nasza firma specjalizuje się w dostarczaniu wysokiej
              jakości materiałów ze stali dla różnych branż i zastosowań.
              Posiadamy szeroki wybór produktów, w tym blach, rur, prętów,
              kształtowników i innych elementów z metalu. Nasza oferta jest
              skierowana do klientów indywidualnych oraz przedsiębiorstw z
              różnych sektorów, takich jak budownictwo, przemysł czy produkcja
              maszyn.
            </div>
            <div className="slider__descript">
              Nasza firma specjalizuje się w dostarczaniu wysokiej jakości
              materiałów ze stali dla różnych branż i zastosowań. Posiadamy
              szeroki wybór produktów, w tym blach, rur, prętów, kształtowników
              i innych elementów z metalu. Nasza oferta jest skierowana do
              klientów indywidualnych oraz przedsiębiorstw z różnych sektorów,
              takich jak budownictwo, przemysł czy produkcja maszyn.
            </div>
            <div className="slider__descript">
              Nasza firma specjalizuje się w dostarczaniu wysokiej jakości
              materiałów ze stali dla różnych branż i zastosowań. Posiadamy
              szeroki wybór produktów, w tym blach, rur, prętów, kształtowników
              i innych elementów z metalu. Nasza oferta jest skierowana do
              klientów indywidualnych oraz przedsiębiorstw z różnych sektorów,
              takich jak budownictwo, przemysł czy produkcja maszyn.
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}
