import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const Heading = () => (
  <div className="add-product-photo__header">
    <SVG type={SVG_TYPE.CAMERA} />
    <div className="add-product-photo__header--content">
      <p className="heading">Dodaj zdjęcia*</p>
      <p className="paragraph">Możesz dodać maksymalnie 8 zdjęć w formacie JPG, PNG</p>
    </div>
  </div>
);

export default Heading;
