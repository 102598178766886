import React from "react";
import { string } from "prop-types";
import Face from "assets/files/face.jpg";

const UserIcon = ({ className }) => (
  <div className={`user-icon ${className}`}>
    <img className="user-icon--img" alt="user" src={Face} />
  </div>
);

export default UserIcon;

UserIcon.propTypes = {
  className: string.isRequired,
};
