const NOTIFY_TYPE = {
  DELIVERY_ADD: 'Dodano metodę dostawy',
  DELIVERY_DELETE: 'Usunięto metodę dostawy',
  DELIVERY_EDIT: 'Pomyślna edycja metody dostawy',
  SOMETHING_WRONG: 'Ups, coś poszło nie tak',
  ADD_NEW_ADDRESS: 'Dodano nowy adres',
  PRODUCT_ADD: 'Dodano nowy produkt',
};

export default NOTIFY_TYPE;
