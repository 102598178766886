import React from "react";
import { object, func } from "prop-types";
import { SVG_TYPE } from "shared/consts";
import { SVG } from "shared/components";
import { CloseClickOutside } from "shared/utils";

const Advertisement = ({ data, setManageModal }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const advertisementManage = (text, buttonText, product) => {
    setManageModal({ isOpen: true, text, buttonText, product });
  };
  const handleClick = () => {
    if (isOpen === data.id) {
      setIsOpen(false);
    } else {
      setIsOpen(data.id);
    }
  };
  return (
    <div className="advertisement">
      <div className="image--wrapper">
        <img className="image" src={`${data.file[0].miniature_path}`} alt="" />
        {/* <img className="image" src={data.path} alt="" /> */}
      </div>
      <div className="mid">
        <div className="advertisement--name">{data.title}</div>
        <div className="advertisement--counts">
          <div className="advertisement--count">
            ilość wystawionych: <span>{data.amount}</span>
          </div>
          {/* <div className="advertisement--count"> ilość sprzedanych: <span>4</span> </div> */}
          <div className="advertisement--count">
            data ostatniej sprzedaży: <span>04.22.2022</span>
          </div>
        </div>
      </div>
      <div className="right">
        <div className="advertisement--price"> {data.price} zł </div>
        <div className="advertisement-settings">
          <div className="settings-dots">
            <div
              className={`advertisement-settings__content ${
                isOpen ? "icon--disabled" : ""
              }`}
              onClick={() => handleClick()}
            >
              <SVG
                className={`advertisement-settings-icon`}
                type={SVG_TYPE.DOTS}
              />
            </div>
            <div className="settings-dots__wrapper">
              <div className="settigs-dots__content">
                <CloseClickOutside
                  className="settigs-dots__content"
                  onClose={() => setIsOpen(false)}
                >
                  <ul
                    className={`list view ${
                      isOpen === data.id ? "view--active" : ""
                    }`}
                  >
                    <li className="list--item"> Edytuj ogłoszenie </li>
                    <li
                      className="list--item"
                      onClick={() =>
                        advertisementManage(
                          "zakończyć",
                          "zakończ",
                          `${data.title} ${data.price} zł`
                        )
                      }
                    >
                      {" "}
                      Zakończ sprzedaż{" "}
                    </li>
                    <li
                      className="list--item"
                      onClick={() =>
                        advertisementManage(
                          "wstrzymać",
                          "wstrzymaj",
                          `${data.title} ${data.price} zł`
                        )
                      }
                    >
                      {" "}
                      Wstrzymaj sprzedaż{" "}
                    </li>
                    <li
                      className="list--item"
                      onClick={() =>
                        advertisementManage(
                          "usunąć",
                          "usuń",
                          `${data.title} ${data.price} zł`
                        )
                      }
                    >
                      {" "}
                      Usuń ogłoszenie{" "}
                    </li>
                  </ul>
                </CloseClickOutside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advertisement;

Advertisement.propTypes = {
  data: object.isRequired,
  setManageModal: func.isRequired,
};
