import React, { useState } from 'react';
// import ProductsElements from 'shared/consts/ProductsElements';
import { Table } from 'shared/components';
import { array } from 'prop-types';
import { ManageAdvertisementModal } from 'screens/my-advertisements-page/components';
import getAdvertisementColumns from '../getAdvertisementColumns';

const AdvertisementsList = ({ myProducts }) => {
  const [manageModal, setManageModal] = useState({ isOpen: false, text: '', buttonText: '', product: '' });
  return (
    <>
      <ManageAdvertisementModal manageModal={manageModal} setManageModal={setManageModal} />
      <div className="advertisements-list">
        {myProducts.length > 0 && <Table data={myProducts} columns={getAdvertisementColumns(setManageModal)} />}
      </div>
    </>
  );
};

export default AdvertisementsList;

AdvertisementsList.propTypes = {
  myProducts: array.isRequired,
};
