import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { registerUser, sendResetPasswordEmail, setNewPassword } from 'store/actions/user';
import { login } from 'store/actions/auth';
import * as ViewManagementActions from 'store/actions/viewManagementActions';
import { getIsModalOpened } from 'store/selectors/viewManagementSelector';
import { isMobile as checkIsMobile } from 'shared/utils';
import LoginManage from './LoginManage.main';

// const mapDispatchToProps = {
//   closeModal: ViewManagementActions.closeModal,
//   openModal: ViewManagementActions.openModal,
// };

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setNewPassword: data => setNewPassword(data),
      sendResetPasswordEmail: email => sendResetPasswordEmail(email),
      registerUser: data => registerUser(data),
      login: data => login(data),
      closeModal: ViewManagementActions.closeModal,
    },
    dispatch,
  ),
});

function mapStateToProps(state) {
  return {
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginManage);
