import * as React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";

class Modal extends React.Component {
  constructor(props) {
    super(props);
    const { opened } = this.props;
    this.state = {
      isOpened: opened || false,
      isExitAnimate: false,
    };
  }

  componentDidMount() {
    const { onOpen } = this.props;
    if (onOpen) onOpen();
  }

  shouldComponentUpdate(nextProps) {
    const { opened } = this.props;
    if (nextProps.opened !== opened) {
      this.setOpened(nextProps.opened);
    }
    return true;
  }

  componentWillUnmount() {
    const { onClose } = this.props;
    if (onClose) onClose();
  }

  setOpened = (isOpened) => {
    const { onOpenedChange } = this.props;
    this.setState(
      { isOpened },
      () => onOpenedChange && onOpenedChange(isOpened)
    );
  };

  submit = () => {
    const { onSubmit } = this.props;
    if (onSubmit) onSubmit();
    this.setOpened(false);
  };

  toggle = () => {
    const { isOpened } = this.state;
    this.setOpened(!isOpened);
  };

  close = () => {
    const { onClose } = this.props;
    this.setState({ isExitAnimate: true });
    onClose();
    setTimeout(() => {
      this.setOpened(false);
      this.setState({ isExitAnimate: false });
      this.props.closeModal();
    }, 280);
  };

  handleChildClick = (e) => {
    e.stopPropagation();
  };

  render() {
    const { isOpened, isExitAnimate } = this.state;
    const { children, className, closeModal } = this.props;

    return (
      <>
        {isOpened && (
          <div className="modal" onClick={this.close}>
            <div
              className={`modal__inner modal-enter ${
                isExitAnimate ? "modal-exit" : ""
              } modal__inner--${className || ""}`}
              onClick={this.handleChildClick}
            >
              {closeModal && (
                <div className="close" onClick={this.close}>
                  <SVG type={SVG_TYPE.CLOSE} />
                </div>
              )}
              <div className="modal__content">{children}</div>
            </div>
          </div>
        )}
      </>
    );
  }
}

Modal.defaultProps = {
  className: "",
  onOpen: null,
  onOpenedChange: null,
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeModal: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  onOpenedChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  title: PropTypes.element.isRequired,
  // type: PropTypes.oneOf([MODAL_TYPES.CONFIRM, MODAL_TYPES.DELETE]).isRequired,
};

// eslint-disable-next-line
export default (props) =>
  ReactDOM.createPortal(<Modal {...props} />, document.querySelector("body"));
