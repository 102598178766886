import { addForumQuestion as addForumQuestionConnector } from "shared/connectors/forumConnector";
import { FORUM } from "store/types";

const init = () => ({
  type: FORUM.ADD_FORUM_QUESTION_INIT,
});

export const success = () => ({
  type: FORUM.ADD_FORUM_QUESTION_SUCCESS,
});

const failure = () => ({
  type: FORUM.ADD_FORUM_QUESTION_FAILURE,
});

const addForumQuestion = (data) => async (dispatch) => {
  dispatch(init());
  console.log(data, "data w akcji");
  try {
    const response = await addForumQuestionConnector(data);
    return response.data;
  } catch (err) {
    dispatch(failure());
    return err.response.data;
  }
};

export default addForumQuestion;
