import React from "react";
import PropTypes from "prop-types";

const Checkbox = ({
  field: { name, value },
  label,
  form,
  errors,
  children,
}) => {
  const handleClick = () => {
    value === true
      ? form && form.setFieldValue(name, null)
      : form && form.setFieldValue(name, true);
  };

  return (
    <div className="checkbox">
      <div
        className={`checkbox__label ${
          value === true && "checkbox__label--active"
        }`}
        onClick={handleClick}
      >
        {label}
        {children}
      </div>
      {errors && form.touched && (
        <div className="checkbox__error">
          {form.touched[name] && <span>{errors[name]}</span>}
        </div>
      )}
    </div>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};
