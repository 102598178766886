import React from "react";
import { string, bool } from "prop-types";

const DashboardSectionHeading = ({ name, children, className, margin }) => {
  const isMargin = margin ? "dashboard-section-heading--margin" : "";
  return (
    <div
      className={`dashboard-section-heading ${isMargin} ${
        className ? className : ""
      }`}
    >
      <p className="label"> {name} </p> {children}
    </div>
  );
};

export default DashboardSectionHeading;

DashboardSectionHeading.defaultProps = {
  margin: false,
};

DashboardSectionHeading.propTypes = {
  name: string.isRequired,
  classname: string,
  margin: bool,
};
