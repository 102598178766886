import { func, string } from "prop-types";
import React from "react";
import { Button } from "shared/components";
import { useHistory } from "react-router-dom";
import { ROUTES } from "shared/consts";

const Top = ({ label, type, onClick }) => {
  const history = useHistory();
  const renderButton = () => {
    switch (type) {
      case "dodaj produkt": {
        return (
          <Button
            className="btn--default"
            label="dodaj produkt"
            onClick={() => history.push(ROUTES.ADD_PRODUCT_PAGE)}
          />
        );
      }
      case "dodaj metodę dostawy": {
        return (
          <Button
            className="btn--default "
            label="dodaj metodę dostawy"
            onClick={() => onClick()}
          />
        );
      }
      default: {
        return null;
      }
    }
  };
  return (
    <div className="top">
      <div className="top__header">
        <h1 className="page--heading">{label}</h1>
        {renderButton()}
      </div>
    </div>
  );
};

export default Top;

Top.propTypes = {
  label: string.isRequired,
  onClick: func,
  type: string,
};
Top.defaultProps = {
  onClick: null,
  type: "",
};
