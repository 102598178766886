import React from 'react';
import { SVG } from 'shared/components';
import { string, bool, func } from 'prop-types';

const CircleIcon = ({ type, onClick, isOpen }) => {
  const isOpenClass = isOpen ? 'circle-icon--disabled circle-icon--focused' : '';
  const typeClass = type ? `circle-icon--${type}` : '';

  return (
    <div onClick={onClick} className={`circle-icon ${isOpenClass} ${typeClass}`}>
      <SVG type={type} className={`circle-icon--image circle-icon--image--${type}`} />
    </div>
  );
};

export default CircleIcon;

CircleIcon.prototype = {
  isOpen: false,
};

CircleIcon.propTypes = {
  isOpen: bool.isRequired,
  onClick: func.isRequired,
  type: string.isRequired,
};
