import React from 'react';
import { SVG, InputCounter } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { object } from 'prop-types';

const BasketItem = ({ data }) => (
  <div className="basket--item">
    <div className="basket--item--close">
      <SVG type={SVG_TYPE.CLOSE} />
    </div>
    <div className="image--wrapper">
      <img className="image" src={data.path} alt="" />
    </div>
    <div className="mid">
      <div className="mid--name">{data.name}</div>
      <div className="mid--counter">
        <InputCounter /> <p>z 10 sztuk</p>
      </div>
    </div>
    <div className="basket--item__right">
      <div className="right--price"> {data.price} </div>
      <div className="watched">
        {' '}
        obserwuj <SVG type={SVG_TYPE.HEART} />{' '}
      </div>
    </div>
  </div>
);

export default BasketItem;

BasketItem.propTypes = {
  data: object.isRequired,
};
