import React from 'react';
import * as Connector from 'shared/connectors/authConnector';
import { getAuthorizedUser } from 'store/actions/user';
import { AUTH } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

const init = () => ({
  type: AUTH.LOGIN_INIT,
});

export const success = data => ({
  type: AUTH.LOGIN_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: AUTH.LOGIN_FAILURE,
});

const login = formData => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.login(formData);
    dispatch(success(data));
    await dispatch(getAuthorizedUser());
    toast(<Notify label="Pomyślne logowanie" type={NOTIFY_TYPE.CHECKED} color="green" />);
    return data;
  } catch (err) {
    dispatch(failure());
    if (err.response.data.message === 'User is inactive') {
      toast(
        <Notify
          label="Użytkownik jest nieaktywny, sprawdź pocztę lub wyślij nowy link"
          type={NOTIFY_TYPE.INACTIVE_USER}
          color="red"
        />,
      );
    } else {
      toast(<Notify label="Błędny login lub hasło" type={NOTIFY_TYPE.WARNING} color="red" />);
    }
    return err.response.data;
  }
};

export default login;
