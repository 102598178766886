import React, { useState } from "react";
import { SVG_TYPE, ROUTES, LOGIN_MANAGE_TYPE } from "shared/consts";
import { SVG, Button } from "shared/components";
import { func } from "prop-types";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

const Mobile = ({ openMenu, closeMenu, handleOpenModal, isUserLogged }) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const handleHamburgerClick = () => {
    if (!isOpen) {
      openMenu();
    } else closeMenu();
    setIsOpen(!isOpen);
  };

  return (
    <div className="mobile-menu">
      <div className="menu-top__content">
        <div className="menu-top-mobile">
          <div className="hamburger" onClick={() => handleHamburgerClick()}>
            {isOpen ? (
              <SVG type={SVG_TYPE.CLOSE} />
            ) : (
              <SVG type={SVG_TYPE.HAMBURGER} />
            )}
          </div>
        </div>
      </div>
      <div
        className={`menu-top-mobile__content ${
          isOpen ? "menu-top-mobile__content--opened" : ""
        }`}
      >
        <ul className="menu-top-mobile__content--list">
          <li className="menu-top-mobile__content--item">
            <NavLink
              className="menu-top__item"
              activeClassName="menu-top__item--active"
              exact
              to={ROUTES.HOME_PAGE}
            >
              Strona główna
            </NavLink>
          </li>
          <li className="menu-top-mobile__content--item">
            <NavLink
              className="menu-top__item"
              activeClassName="menu-top__item--active"
              exact
              to={ROUTES.HOME_PAGE}
            >
              Rzemiosło
            </NavLink>
          </li>
          <li className="menu-top-mobile__content--item">
            <NavLink
              className="menu-top__item"
              activeClassName="menu-top__item--active"
              exact
              to={ROUTES.HOME_PAGE}
            >
              Sklep
            </NavLink>
          </li>
          <li className="menu-top-mobile__content--item">
            <NavLink
              className="menu-top__item"
              activeClassName="menu-top__item--active"
              exact
              to={ROUTES.HOME_PAGE}
            >
              Forum
            </NavLink>
          </li>
          <li className="menu-top-mobile__content--item">
            <NavLink
              className="menu-top__item"
              activeClassName="menu-top__item--active"
              exact
              to={ROUTES.HOME_PAGE}
            >
              Kontakt
            </NavLink>
          </li>
          {!isUserLogged ? (
            <li className="menu-top-mobile__content--item">
              <Button
                onClick={() => handleOpenModal(LOGIN_MANAGE_TYPE.LOGIN)}
                label="Zaloguj się"
                className="btn--normal-stroke"
              />
            </li>
          ) : (
            <li className="menu-top-mobile__content--item">
              <Button
                onClick={() => history.push(ROUTES.DASHBOARD)}
                label="Dashboard"
                className="btn--normal-stroke"
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Mobile;

Mobile.propTypes = {
  handleOpenModal: func.isRequired,
  openMenu: func.isRequired,
  closeMenu: func.isRequired,
  isUserLogged: func.isRequired,
};
