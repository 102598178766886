import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LOGIN_MANAGE_TYPE, VALIDATION_MESSAGES, SVG_TYPE, ROUTES } from 'shared/consts';
import { RegularField, Button, SVG } from 'shared/components';
import { ModalTitle } from 'shared/components/modal/components';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';
import { useHistory } from 'react-router-dom';

const ForgotPasswordForm = ({ changeModalType, sendResetPasswordEmail, closeModal }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { EMAIL, REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const { LOGIN } = LOGIN_MANAGE_TYPE;
  const validationForm = () =>
    object().shape({
      email: string().email(EMAIL).required(REQUIRED_FIELD),
    });

  const submitForm = async (values, actions) => {
    setIsLoading(true);
    const response = await sendResetPasswordEmail(values.email);
    if (response?.status === 'success') {
      setIsLoading(false);
      changeModalType(false);
      closeModal();
      history.push(ROUTES.SEND_LINK_NEW_PASSWORD);
    } else if (response?.message === 'Error with password reset, bad email') {
      setIsLoading(false);
      actions.setErrors({ email: ' ' });
    }
  };

  return (
    <>
      <ModalTitle title="Nie pamiętasz hasła?" description="wpisz swój adres email, a my wyślemy Ci kod do resetu hasła" />
      <Formik
        initialValues={{ email: '', password: '', first_name: '' }}
        onSubmit={submitForm}
        validationSchema={validationForm()}
      >
        {({ handleSubmit, errors }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Field
                component={RegularField}
                errors={errors}
                icon={SVG_TYPE.EMAIL}
                label="email"
                name="email"
                placeholder="Adres email"
                type="text"
                fullWidth
              />

              <div className="isLoading">
                <Button className="btn--default" label="Wyślij link" type="default" position="center-full" />
                {isLoading && <SVG type={SVG_TYPE.SPINNER_LOADING} />}
              </div>
              <div className="modal__paragraph--wrapper">
                <p className="modal__paragraph--ligth">wróć do ekranu </p>
                <div className="modal__paragraph--dark modal__paragraph--cursor" onClick={() => changeModalType(LOGIN)}>
                  logowania
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default ForgotPasswordForm;

ForgotPasswordForm.propTypes = {
  changeModalType: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  sendResetPasswordEmail: PropTypes.func.isRequired,
};
