import * as React from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';

const RegularField = ({
  type,
  field: { name, onChange, value },
  form,
  errors,
  placeholder,
  fullWidth,
  className,
  onBlur,
  icon,
  iconPlacement,
  inputOnChange,
  customBorder,
  label,
}) => {
  const isError = !!(form.touched[name] && errors[name]);
  const isCustomErrorLabel = customBorder && isError;
  const fieldClasses = `${customBorder}__input ${isError ? `${customBorder}__input--error` : ''} ${
    !icon ? `${customBorder}--noIcon` : ''
  } ${className}`;
  return (
    <div className={customBorder}>
      <div className={`${customBorder}__container ${fullWidth ? `${customBorder}__container--full-width` : ''}`}>
        <div className={fieldClasses}>
          {type === 'textarea' ? (
            <>
              <textarea
                name={name}
                placeholder={placeholder}
                onBlur={onBlur}
                value={value}
                className={`form__field ${isError && `${customBorder}__input-error`} `}
                onChange={e => {
                  onChange(e);
                  inputOnChange && inputOnChange(e);
                }}
              />
              {customBorder !== 'regular-field' && (
                <div className={`form__label ${isCustomErrorLabel ? 'form__label--error' : ''}`}>{label}</div>
              )}
              {isError && <p className={`${customBorder}__error-label`}> {errors[name]}</p>}
            </>
          ) : (
            <>
              {icon && iconPlacement && (
                <div className={`${customBorder}__icon ${customBorder}__icon--${iconPlacement}`}>
                  <SVG type={icon} className={`${customBorder}__icon--item`} />
                </div>
              )}
              <input
                // autoComplete={name === 'password' ? 'on' : 'new-password'}
                name={name}
                onChange={e => {
                  onChange(e);
                  inputOnChange && inputOnChange(e);
                }}
                placeholder={placeholder}
                type={type}
                value={value}
                onBlur={onBlur}
                className={`form__field ${isError && `${customBorder}__input-error`} `}
              />
              {customBorder !== 'regular-field' && (
                <div className={`form__label ${isCustomErrorLabel ? 'form__label--error' : ' '}`}>{label}</div>
              )}
              {isError && <p className={`${customBorder}__error-label`}> {errors[name]}</p>}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
RegularField.propTypes = {
  className: PropTypes.string,
  customBorder: PropTypes.string,
  errors: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  form: PropTypes.object.isRequired,
  fullWidth: PropTypes.bool,
  icon: PropTypes.string,
  iconPlacement: PropTypes.string,
  inputOnChange: PropTypes.func,
  label: PropTypes.string,
  // label: PropTypes.string.isRequired,
  onBlur: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
};

RegularField.defaultProps = {
  className: '',
  customBorder: 'regular-field',
  fullWidth: false,
  icon: null,
  iconPlacement: 'left',
  inputOnChange: null,
  label: '',
  placeholder: '',
};

export default RegularField;
