export const MENU_TYPES = {
  ACTIVE: "active",
  SOLD: "sold",
  ENDED: "end",
  PAUSED: "paused",
};

export const SUBPAGES = [
  {
    name: "Aktywne",
    type: MENU_TYPES.ACTIVE,
    count: 111,
  },
  {
    name: "Sprzedane",
    type: MENU_TYPES.SOLD,
    count: 0,
  },
  {
    name: "Zakończone",
    type: MENU_TYPES.ENDED,
    count: 4,
  },
  {
    name: "Paused",
    type: MENU_TYPES.PAUSED,
    count: 0,
  },
];
