import React, { useEffect } from "react";
import { array, func, shape, object } from "prop-types";
import { DashboardContent } from "screens/dashboard/components";
import { MyEvents } from "./components";
// import { MyEvents, UsersEvents } from "./components";
// import { USER_ROLE } from "shared/consts";

const EventManagePage = ({
  actions: { getAllCategories },
  categories,
  authorizedUser,
}) => {
  // const isAdmin = authorizedUser?.role === USER_ROLE.ADMIN;

  useEffect(() => {
    if (!categories.length > 0) {
      getAllCategories();
    }
  }, [categories.length, getAllCategories]);

  return (
    <DashboardContent goBack name="Zarządzanie wydarzeniami">
      <MyEvents authorizedUser={authorizedUser} />
      {/* {isAdmin && <UsersEvents authorizedUser={authorizedUser}/> } */}
    </DashboardContent>
  );
};

export default EventManagePage;

EventManagePage.propTypes = {
  actions: shape({
    getAllCategories: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  categories: array.isRequired,
};
