import { SVG_TYPE, ROUTES } from 'shared/consts';

const { HOME, BOOKMARK, ADD, USER, CHAT } = SVG_TYPE;
const { DASHBOARD, MY_DEVICE_PAGE, USER_PAGE, CHAT_PAGE } = ROUTES;

const MENU_ITEMS = [
    {
        path: DASHBOARD,
        label: 'Home',
        icon: HOME,
    },
    {
        path: MY_DEVICE_PAGE,
        label: 'Zakładki',
        icon: BOOKMARK,
    },
    {
        label: 'Dodaj',
        icon: ADD,
    },
    {
        path: USER_PAGE,
        label: 'Uzytkownicy',
        icon: USER,
    },
    {
        path: CHAT_PAGE,
        label: 'Czat',
        icon: CHAT,
    }
];

export default MENU_ITEMS