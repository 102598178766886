import React, { useState } from "react";
import {
  PageContainer,
  SVG,
  Button,
  RegularField,
  Notify,
} from "shared/components";
import { SVG_TYPE, NOTIFY_TYPE, ROUTES } from "shared/consts";
import { Field, Formik } from "formik";
import { object, string } from "yup";
import { func, shape } from "prop-types";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const InactiveTokenPage = ({ actions: { userResendLink } }) => {
  const [isLoading, setIsLoading] = useState(false);
  const histroy = useHistory();
  const validationForm = () =>
    object().shape({
      email: string().email("nieprawidłowy email").required("pole wymagane"),
    });

  const submitForm = async (values, actions) => {
    setIsLoading(true);
    const { notExist, isActivate, resend } = await userResendLink(values.email);
    if (notExist) {
      actions.setErrors({ email: "Brak w bazie" });
      setIsLoading(false);
    } else if (isActivate) {
      setIsLoading(false);
      toast(
        <Notify
          label="Użytkownik jest aktywny"
          type={NOTIFY_TYPE.CHECKED}
          color="green"
        />
      );
    } else if (resend) {
      setIsLoading(false);
      histroy.push(ROUTES.HOME_PAGE);
    }
  };

  return (
    <PageContainer full className="inactive">
      <div className="inactive">
        <div className="inactive--content">
          <SVG type={SVG_TYPE.TIME_MANAGE_ILU} />
          <h1 className="inactive--header">Link wygasł!</h1>
          <h1 className="inactive--paragraph">
            Wprowadź email a następnie wciśnij przycisk aby wygenerować nowy kod
          </h1>
          <Formik
            initialValues={{ email: "" }}
            onSubmit={submitForm}
            validationSchema={validationForm()}
          >
            {({ handleSubmit, errors }) => (
              <>
                <form
                  onSubmit={handleSubmit}
                  className="inactive--form--wrapper"
                >
                  <Field
                    component={RegularField}
                    errors={errors}
                    icon={SVG_TYPE.EMAIL}
                    label="email"
                    name="email"
                    placeholder="Adres email"
                    type="text"
                    fullWidth
                  />
                  <div className="isLoading">
                    <Button
                      iconType={isLoading && SVG_TYPE.SPINNER_LOADING}
                      position="center"
                      label="Wygeneruj kod"
                      className="btn--normal"
                      type="submit"
                    />
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </PageContainer>
  );
};
export default InactiveTokenPage;

InactiveTokenPage.propTypes = {
  actions: shape({
    userResendLink: func.isRequired,
  }).isRequired,
};
