import React from "react";
import ForumList from "./ForumList";
import ForumAddQuestion from "./ForumAddQuestion";
import ForumQuestion from "./ForumQuestion";
import { useLocation } from "react-router-dom";
import { ROUTES } from "shared/consts";

const ForumCenter = ({
  categories,
  getAllCategories,
  addForumQuestion,
  forumQuestions,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const isForum = pathname === ROUTES.FORUM;
  const isAddQuestion = pathname === ROUTES.FORUM_ADD_QUESTION;
  const isQuestionPage = pathname.includes("/forum/pytanie/");

  return (
    <div
      className={`forum-center ${
        isQuestionPage ? "forum-center--question" : ""
      }`}
    >
      {isForum && <ForumList forumQuestions={forumQuestions} />}
      {isAddQuestion && (
        <ForumAddQuestion
          getAllCategories={getAllCategories}
          categories={categories}
          addForumQuestion={addForumQuestion}
        />
      )}
      {isQuestionPage && <ForumQuestion />}
    </div>
  );
};

export default ForumCenter;
