import API from "shared/connectors/config";

export function getForumQuestions() {
  return API.get("/forum/list");
}

export function addForumQuestion(data) {
  console.log("tutututuuu");
  return API.post("/forum/question", { ...data });
}
