import React, { useEffect } from "react";
import { bool, object } from "prop-types";
import {
  DashboardContent,
  DashboardUserTypeView,
} from "screens/dashboard/components";

const Dashboard = ({
  authorizedUser,
  categories,
  actions: { getAuthorizedUser, setAccountType, getAllCategories },
}) => {
  const isProfileTypeNull = authorizedUser?.type === null;

  useEffect(() => {
    !authorizedUser && getAuthorizedUser();
  }, [getAuthorizedUser, authorizedUser]);

  return (
    <>
      <DashboardContent name="Profil">
        {!isProfileTypeNull && (
          <DashboardUserTypeView
            categories={categories}
            getAllCategories={getAllCategories}
            setAccountType={setAccountType}
          />
        )}
      </DashboardContent>
    </>
  );
};
export default Dashboard;

Dashboard.propTypes = {
  // actions: shape({
  //   getAuthUser: func.isRequired,
  // }).isRequired,
  authorizedUser: object.isRequired,
  isMobile: bool.isRequired,
  isModalOpened: bool.isRequired,
};
