import { array, func } from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { ReactSortable } from 'react-sortablejs';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';

const PhotoTiles = ({ fileTypes, filesArr, addFiles, setFilesArr }) => {
  const activeAddingTile = filesArr.length >= 0 && filesArr.length < 8;
  const cancelPhoto = photoIndex => {
    const afterFilter = filesArr.filter((item, index) => index !== photoIndex);
    setFilesArr(afterFilter.length >= 1 ? afterFilter : '');
  };
  return (
    <>
      {filesArr.length > 0 && (
        <div className="photo-field">
          <div className="photo-field__photos">
            <ReactSortable className="photo-field__photos--content" list={filesArr} setList={setFilesArr}>
              {filesArr.map((item, idx) => {
                const first = idx === 0;
                return (
                  <div className="photo-field__photo">
                    <div className="photo-field__photo--close" onClick={() => cancelPhoto(idx)}>
                      <SVG type={SVG_TYPE.CLOSE} />
                    </div>
                    <img className="photo-field__photo--image" src={item.image} alt={item.name} />
                    {first && <div className="photo-field__photo--first"> główne </div>}
                  </div>
                );
              })}
              <FileUploader
                multiple
                name="file"
                types={fileTypes}
                maxSize="5"
                classes={`photo-field__photo ${activeAddingTile ? '' : 'photo-field__photo--hidden'}`}
                onDrop={file => addFiles(file)}
                onSelect={file => addFiles(file)}
                hoverTitle=" "
              >
                <SVG type={SVG_TYPE.PLUS_CIRCLE} />
              </FileUploader>
            </ReactSortable>
          </div>
        </div>
      )}
    </>
  );
};

export default PhotoTiles;

PhotoTiles.propTypes = {
  addFiles: func.isRequired,
  filesArr: array.isRequired,
  fileTypes: array.isRequired,
  setFilesArr: func.isRequired,
};
