import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { createBrowserHistory } from "history";
import viewManagementReducer from "./viewManagementReducer";
import categoryReducer from "./categoryReducer";
import deliveryReducer from "./deliveryReducer";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import productReducer from "./productReducer";
import forumReducer from "./forumReducer";
import addressReducer from "./addressReducer";

export const historyBrowser = createBrowserHistory();

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    addressStore: addressReducer,
    authStore: authReducer,
    userStore: userReducer,
    viewManagementStore: viewManagementReducer,
    categoryStore: categoryReducer,
    deliveryStore: deliveryReducer,
    productStore: productReducer,
    forumStore: forumReducer,
  });

export default rootReducer;
