import EVENT_TYPE from "./eventType";

const EVENT_TYPE_OPTIONS = [
  {
    searchableLabel: "Konkurs",
    label: "Konkurs",
    value: EVENT_TYPE.COMPETITION,
  },
  {
    searchableLabel: "Warsztaty rzemieślnicze",
    label: "Warsztaty rzemieślnicze",
    value: EVENT_TYPE.CRAFTS_WORKSHOP,
  },
];

export default EVENT_TYPE_OPTIONS;
