import { bool } from 'prop-types';
import React from 'react';
import { Button } from 'shared/components';

const DefaultView = ({ isError }) => (
  <div className={`photo-field__wrapper ${isError ? 'photo-field__wrapper--error' : ''}`}>
    <div className="photo-field__content">
      <p className="photo-field__paragraph">Przeciągnij i upuść zdjęcia tutaj</p>
      <p className="photo-field__paragraph">lub</p>
      <Button label="wybierz z dysku" className="btn--default" />
    </div>
  </div>
);

export default DefaultView;

DefaultView.propTypes = {
  isError: bool.isRequired,
};
