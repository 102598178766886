import { number, func, shape, oneOfType, string, bool } from "prop-types";

const Switch = ({
  field: { name, value },
  onChange,
  setFieldValue,
  label,
  className,
  disabled,
}) => {
  return (
    <div className={`switch__wrapper ${className || ""}`}>
      {label && (
        <div
          className={`switch__label ${
            disabled ? "switch__label--disabled" : ""
          }`}
        >
          {label}
        </div>
      )}
      <input
        checked={value}
        className="switch-checkbox"
        type="checkbox"
        id="switch"
      />
      <label
        onClick={() => {
          setFieldValue(name, !value);
          onChange();
        }}
        className="switch-label"
        for="switch"
      >
        Toggle
      </label>
    </div>
  );
};

export default Switch;

Switch.defaultProps = {
  disabled: false,
  string: "",
};

Switch.propTypes = {
  field: shape({
    name: string,
    onChange: func,
    value: oneOfType([string, number]),
  }).isRequired,
  placeholder: string,
  onBlur: func.isRequired,
  disabled: bool,
  onChange: func,
};
