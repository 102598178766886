import { connect } from "react-redux";
import * as ViewManagementActions from "store/actions/viewManagementActions";
import { getIsModalOpened } from "store/selectors/viewManagementSelector";
import { isMobile as checkIsMobile } from "shared/utils";
import { bindActionCreators } from "redux";
import { getCategoriesAndSubcategories } from "store/selectors/categorySelector";
import ForumPage from "./ForumPage";
import { getAuthorizedUser as getAuthorizedUserSelector } from "store/selectors/userSelector";
import * as UserActions from "store/actions/user";
import { getAllCategories as getAllCategoriesSelector } from "store/selectors/categorySelector";
import { getAllCategories } from "store/actions/category";
import { addForumQuestion, getForumQuestions } from "store/actions/forum";
import { getForumQuestions as getForumQuestionsSelector } from "store/selectors/forumSelector";

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAuthorizedUser: UserActions.getAuthorizedUser,
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
      getAllCategories: () => getAllCategories(),
      addForumQuestion: (data) => addForumQuestion(data),
      getForumQuestions: () => getForumQuestions(),
    },
    dispatch
  ),
});

function mapStateToProps(state) {
  return {
    authorizedUser: getAuthorizedUserSelector(state),
    categoriesAndSubcategories: getCategoriesAndSubcategories(state),
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
    categories: getAllCategoriesSelector(state),
    forumQuestions: getForumQuestionsSelector(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForumPage);
