import { FORUM } from "store/types";

const INITIAL_STATE = {
  forumQuestions: [],
};

const forumReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case FORUM.GET_FORUM_QUESTIONS_SUCCESS: {
      return {
        ...state,
        forumQuestions: payload.data,
      };
    }
    default:
      return state;
  }
};

export default forumReducer;
