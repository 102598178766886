import React from "react";
import { CurvesSection, SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";
import Slider from "react-slick";

const Opinions = () => {
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div onClick={onClick} className={`slideArrow slideArrow--left`}>
        <SVG type={SVG_TYPE.SLIDE_ARROW} />
      </div>
    );
  };
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div onClick={onClick} className={`slideArrow slideArrow--right`}>
        <SVG type={SVG_TYPE.SLIDE_ARROW} />
      </div>
    );
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: window.innerWidth > 800 ? 3 : 1,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };
  return (
    <CurvesSection className="home-page__opinions">
      <div className="home-page__opinions">
        <p className="heading">Co mówią nasi użytkownicy</p>
        <div className="content">
          <Slider {...settings}>
            <div className="single__wrapper">
              <div className="single">
                <div className="top">
                  <SVG type={SVG_TYPE.QUOTES} />
                  <p className="paragraph">
                    Serwis jest bardzo intuicyjny i łatwy w obsłudze. Bardzo
                    podoba mi się wygląd i jego funkcjonalność.
                  </p>
                </div>
                <div className="bottom">
                  <div className="user-icon"></div>
                  <div className="labels">
                    <div className="name">Kamil Usterka</div>
                    <div className="craft">stolarz</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single__wrapper">
              <div className="single single--active">
                <div className="top">
                  <SVG type={SVG_TYPE.QUOTES} />
                  <p className="paragraph">
                    Serwis jest bardzo intuicyjny i łatwy w obsłudze. Bardzo
                    podoba mi się wygląd i jego funkcjonalność.
                  </p>
                </div>
                <div className="bottom">
                  <div className="user-icon"></div>
                  <div className="labels">
                    <div className="name">Kamil Wiertarka</div>
                    <div className="craft">Dekarz</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single__wrapper">
              <div className="single">
                <div className="top">
                  <SVG type={SVG_TYPE.QUOTES} />
                  <p className="paragraph">
                    Serwis jest bardzo intuicyjny i łatwy w obsłudze. Bardzo
                    podoba mi się wygląd i jego funkcjonalność.
                  </p>
                </div>
                <div className="bottom">
                  <div className="user-icon"></div>
                  <div className="labels">
                    <div className="name">Kamil Malczewski</div>
                    <div className="craft">Malarz</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="single__wrapper">
              <div className="single">
                <div className="top">
                  <SVG type={SVG_TYPE.QUOTES} />
                  <p className="paragraph">
                    Serwis jest bardzo intuicyjny i łatwy w obsłudze. Bardzo
                    podoba mi się wygląd i jego funkcjonalność.
                  </p>
                </div>
                <div className="bottom">
                  <div className="user-icon"></div>
                  <div className="labels">
                    <div className="name">Kamil Usterka</div>
                    <div className="craft">stolarz</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single__wrapper">
              <div className="single single--active">
                <div className="top">
                  <SVG type={SVG_TYPE.QUOTES} />
                  <p className="paragraph">
                    Serwis jest bardzo intuicyjny i łatwy w obsłudze. Bardzo
                    podoba mi się wygląd i jego funkcjonalność.
                  </p>
                </div>
                <div className="bottom">
                  <div className="user-icon"></div>
                  <div className="labels">
                    <div className="name">Kamil Wiertarka</div>
                    <div className="craft">Dekarz</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="single__wrapper">
              <div className="single">
                <div className="top">
                  <SVG type={SVG_TYPE.QUOTES} />
                  <p className="paragraph">
                    Serwis jest bardzo intuicyjny i łatwy w obsłudze. Bardzo
                    podoba mi się wygląd i jego funkcjonalność.
                  </p>
                </div>
                <div className="bottom">
                  <div className="user-icon"></div>
                  <div className="labels">
                    <div className="name">Kamil Malczewski</div>
                    <div className="craft">Malarz</div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </CurvesSection>
  );
};

export default Opinions;
