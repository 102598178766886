// import { Products, Top, Filters } from './component';
import React from "react";
import { bool, object, func } from "prop-types";
import { Products } from "./component";
import {
  DashboardContent,
  DashboardSectionHeading,
} from "screens/dashboard/components";
import { SVG } from "shared/components";
import { ROUTES, SVG_TYPE, USER_TYPE } from "shared/consts";
import { useHistory } from "react-router-dom";

const ShopPage = ({ actions: { getAuthorizedUser }, authorizedUser }) => {
  const history = useHistory();
  const isAdmin = authorizedUser?.role === "admin";
  const isCraftsman = authorizedUser?.type === USER_TYPE.CRAFTSMAN;
  const isPartner = authorizedUser?.type === USER_TYPE.PARTNER;

  return (
    // const [isFilterOpen, setIsFilterOpen] = useState(false);
    <>
      {/* <div className="top__wrapper">
        <Top isFilterOpen={isFilterOpen} setIsFilterOpen={setIsFilterOpen} />
        <Filters isFilterOpen={isFilterOpen} />
      </div> */}
      <DashboardContent name="Sklep">
        <DashboardSectionHeading>
          <div className="label">Produkty</div>
          <div className="counters">
            {isAdmin && (
              <div className="counter-wrapper">
                <div
                  className="counter counter--fit"
                  onClick={() => history.push(ROUTES.DELIVERY_PAGE)}
                >
                  <div className="counter-icon__wrapper">
                    <SVG
                      className="counter-icon"
                      type={SVG_TYPE.DELIVERY_ADD}
                    />
                  </div>
                  <div className="counter-label counter-label--icon">
                    Dodaj metodę dostawy
                  </div>
                </div>
              </div>
            )}

            {(isCraftsman || isPartner) && (
              <>
                <div className="counter-wrapper">
                  <div
                    className="counter counter--fit"
                    onClick={() =>
                      history.push(`${ROUTES.MY_ADVERTISEMENTS}?type=active`)
                    }
                  >
                    <div className="counter-icon__wrapper">
                      <SVG className="counter-icon" type={SVG_TYPE.BOARD} />
                    </div>
                    <div className="counter-label counter-label--icon">
                      Zarządzanie produktami
                    </div>
                  </div>
                </div>
                {/* <div className="counter-wrapper">
                  <div
                    className="counter counter--fit"
                    onClick={() => history.push(ROUTES.ADD_PRODUCT_PAGE)}
                  >
                    <div className="counter-icon__wrapper">
                      <SVG
                        className="counter-icon counter-icon--product"
                        type={SVG_TYPE.PRODUCT}
                      />
                    </div>
                    <div className="counter-label counter-label--icon">
                      Dodaj produkt
                    </div>
                  </div>
                </div> */}
              </>
            )}
          </div>
        </DashboardSectionHeading>
        <Products />
      </DashboardContent>
    </>
  );
};
export default ShopPage;

ShopPage.propTypes = {
  isMobile: bool.isRequired,
  isModalOpened: bool.isRequired,
  getAuthorizedUser: func.isRequired,
  authorizedUser: object.isRequired,
};
