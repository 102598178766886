/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { object, string, shape, func } from "prop-types";
import { Heading, PhotoTiles, PhotoDragAndDrop } from "./components";

const AddPhotos = ({ errors, form, setFieldValue, field: { name } }) => {
  const [filesArr, setFilesArr] = useState("");
  const fileTypes = ["jpg", "jpeg", "png"];
  const isError = !!(form.touched[name] && errors[name]);

  useEffect(() => {
    setFieldValue("photos", filesArr);
  }, [filesArr, setFieldValue]);

  const addFiles = async (files) => {
    const filesArray = [...files];
    const allPhotos = filesArray.length + filesArr.length;
    if (allPhotos > 8) {
      const diff = allPhotos - 8;
      filesArray.splice(filesArray.length - diff, diff);
    }
    const updateArray = [];
    if (filesArr.length < 8) {
      await filesArray.forEach((file) =>
        updateArray.push({
          lastModified: file.lastModified,
          name: file.name,
          type: file.type,
          id: file.lastModified,
          image: URL.createObjectURL(file),
          file,
        })
      );
      setFilesArr([...filesArr, ...updateArray]);
    }
  };

  return (
    <div className="add-product-photo__wrapper">
      <Heading />
      <div className="add-product-photo">
        <PhotoDragAndDrop
          fileTypes={fileTypes}
          addFiles={addFiles}
          filesArr={filesArr}
          isError={isError}
          errors={errors}
        />
        <PhotoTiles
          fileTypes={fileTypes}
          filesArr={filesArr}
          addFiles={addFiles}
          setFilesArr={setFilesArr}
        />
      </div>
    </div>
  );
};

export default AddPhotos;

AddPhotos.propTypes = {
  errors: object.isRequired,
  field: shape({
    name: string,
  }).isRequired,
  form: object.isRequired,
  setFieldValue: func.isRequired,
};
