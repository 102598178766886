import React from 'react';
import { Field } from 'formik';
import { array, func } from 'prop-types';
import AddPhotos from '../add-photos/AddPhotos';

const AddPhotosField = ({ errors, setFieldTouched, setFieldValue }) => {
  const onTouched = () => {
    setFieldTouched('photos');
  };
  return (
    <Field
      setFieldValue={setFieldValue}
      component={AddPhotos}
      errors={errors}
      name="photos"
      customBorder="customize-field"
      onBlur={() => onTouched()}
    />
  );
};
export default AddPhotosField;

AddPhotosField.propTypes = {
  errors: array.isRequired,
  setFieldTouched: func.isRequired,
  setFieldValue: func.isRequired,
};
