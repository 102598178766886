import { func, string } from "prop-types";
import React from "react";
import { SUBPAGES } from "../consts";
import { ROUTES } from "shared/consts";
import { Button } from "shared/components";
import { useHistory } from "react-router-dom";

const Top = ({ menuTypes, setMenuTypes }) => {
  const history = useHistory();

  const handleRedirect = (type) => {
    history.push(`${ROUTES.MY_ADVERTISEMENTS}?type=${type}`);
    setMenuTypes(type);
  };

  return (
    <>
      <div className="advertisement-menu">
        <div className="advertisement-menu__content">
          {/* <div className="advertisement-menu__title">Pokaż tylko: </div> */}
          <div className="advertisement-menu__list">
            {SUBPAGES.map(({ type, count, name }) => (
              <div
                className={`advertisement-menu__item ${
                  menuTypes === type ? "advertisement-menu__item--active" : ""
                }`}
                onClick={() => handleRedirect(type)}
              >
                <div className="advertisement-menu__item--name">{name}</div>
                <div className="advertisement-menu__item--count">{count}</div>
              </div>
            ))}
          </div>
          <Button
            className="btn--frame-dark"
            label="dodaj nowy produkt"
            onClick={() => history.push(ROUTES.ADD_PRODUCT_PAGE)}
          />
        </div>
      </div>
    </>
  );
};

export default Top;

Top.propTypes = {
  menuTypes: string.isRequired,
  setMenuTypes: func.isRequired,
};
