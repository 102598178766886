import Calendar from "react-calendar";
import { useState } from "react";
import { SVG } from "shared/components";
import { useHistory } from "react-router-dom";
import { ROUTES, SVG_TYPE } from "shared/consts";

const CalendarSection = ({ authorizedUser }) => {
  const history = useHistory();
  const [value, onChange] = useState(new Date());
  const isAdmin = authorizedUser?.role === "admin";

  return (
    <div className="events-page-section__container events-page-section__container--calendar">
      <div className="events-page-section__header">
        <div className="paragraph">Kalendarz</div>
        {authorizedUser && (
          <div className="counters">
            <div className="counter-wrapper">
              <div
                className="counter counter--fit"
                onClick={() => history.push(ROUTES.ADD_EVENT)}
              >
                <div className="counter-icon__wrapper">
                  <SVG className="counter-icon" type={SVG_TYPE.CALENDAR_ADD} />
                </div>
                <div className="counter-label counter-label--icon">
                  Dodaj wydarzenie
                </div>
              </div>
            </div>
            <div className="counter-wrapper">
              <div
                className="counter counter--fit"
                onClick={() => history.push(ROUTES.EVENT_MANAGE)}
              >
                <div className="counter-icon__wrapper">
                  <SVG className="counter-icon" type={SVG_TYPE.CALENDAR_LIST} />
                </div>
                <div className="counter-label counter-label--icon">
                  {isAdmin ? "Zarządzanie wydarzeniami" : "Moje wydarzenia"}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="events-page-section events-page-section--calendar">
        <div className="events-calendar">
          <Calendar onChange={onChange} value={value} />
        </div>
      </div>
    </div>
  );
};

export default CalendarSection;
