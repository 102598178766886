import { connect } from 'react-redux';
import * as ViewManagementActions from 'store/actions/viewManagementActions';
import { getIsModalOpened } from 'store/selectors/viewManagementSelector';
import { isMobile as checkIsMobile } from 'shared/utils';
import { bindActionCreators } from 'redux';
import { sendMessageContactForm } from 'store/actions/contact';
import ContactPage from './ContactPage.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      sendMessageContactForm: data => sendMessageContactForm(data),
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
    },
    dispatch,
  ),
});

function mapStateToProps(state) {
  return {
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
