import React from 'react';
import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

export const success = (uuid, data) => ({
  type: CATEGORY.EDIT_CATEGORY_SUCCESS,
  payload: {
    uuid,
    data,
  },
});

const editCategory = (uuid, form) => async dispatch => {
  try {
    const linkModified = form.link.replace(/\s/g, '-').replace('/', '');
    const { data } = await Connector.editCategory(uuid, { name: form.name, link: linkModified });
    toast(<Notify label="Pomyślna edycja kategorii" type={NOTIFY_TYPE.EDIT} color="green" />);
    dispatch(success(uuid, data));
    return data;
  } catch (err) {
    return err.response;
  }
};

export default editCategory;
