const VALIDATION_MESSAGES = {
  EMAIL: 'niepoprawny email',
  UNIQUE_EMAIL: 'podany email już istnieje',
  REQUIRED_FIELD: 'pole wymagane',
  WRONG_EMAIL: 'błędny email',
  WRONG_PASSWORD: 'błędne hasło',
  SAME_PASSWORD: 'niepoprawne hasło',
  SAME_PHONE_NUMBER: 'podany numer jest zajęty',
  WRONG_NIP: 'niepoprawny NIP',
  WRONG_REGON: 'niepoprawny REGON',
  REQUIRED_9_DIGITS: 'wymagane 9 cyfr',
  UPLOAD_2_FILES: 'wymagane 2 pliki',
  EMPTY_SPACE: 'nie może być puste',
  REQUIRED: 'pole wymagane',
  MAX_100: 'maksymalnie 100 znaków',
  ONLY_NUMBER: 'tylko cyfry',
  MAX_6: 'maksymalnie 6 cyfr',
  DECIMAL_2: 'maksymalnie dwa miejsca po przecinku',
  FIRST_NO_ZERO: 'błędna cena',
  MAX_6000: 'maksymalnie 6000 znaków',
  MIN_3: 'minimum 3 znaki',
  POST_CODE: 'xx-xxx',
};

export default VALIDATION_MESSAGES;
