import { createSelector } from 'reselect';

const getAuthStore = state => state.authStore;
const getUserStore = state => state.userStore;
const getToken = createSelector(getAuthStore, ({ token }) => token);
const getCounter = createSelector(getAuthStore, ({ counter }) => counter);
const getAuthorizedUser = createSelector(getAuthStore, ({ authorizedUser: { data = {} } }) => data);
const getAuthorizedUserRole = createSelector(getAuthStore, ({ authorizedUser: { data = {} } }) => data);
const getUsersByRole = createSelector(getUserStore, ({ usersByRole: { data = {} } }) => data);
const getUsers = createSelector(getUserStore, ({ users: { data = {} } }) => data);
const getUsersForApplication = createSelector(getUserStore, ({ usersForApplication: { data = {} } }) => data);
const getUserDetails = createSelector(getUserStore, ({ userDetails: { data = {} } }) => data);
const getCustomCheckoutAddress = createSelector(getAuthStore, ({ checkoutAddress: { data = {} } }) => data);

export {
  getUsersForApplication,
  getUserDetails,
  getToken,
  getCounter,
  getAuthorizedUser,
  getAuthorizedUserRole,
  getUsersByRole,
  getUsers,
  getCustomCheckoutAddress,
};
