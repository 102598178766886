import React from 'react';
import { TopBarMore, ProfileTopBar } from 'shared/components/top-bar/components';

const IconsRightSide = () => (
  <div className="top-bar--right-side">
    <ProfileTopBar />
    <TopBarMore />
  </div>
);

export default IconsRightSide;
