import React from 'react';
import { object, bool } from 'prop-types';
import { Button } from 'shared/components';
import { ROUTES, SVG_TYPE } from 'shared/consts';
import { useHistory } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

const Product = ({ data, isLoading }) => {
  const { path, name, nick, price } = data;
  const history = useHistory();
  const redirectToProductPage = () => history.push(ROUTES.PRODUCT);
  return (
    <div className={`product ${isLoading ? 'product--loading' : ''}`}>
      {isLoading ? (
        <>
          <div className="image--wrapper">
            <Skeleton height={220} />
          </div>
          <div className="heading">
            <Skeleton height={28} />
          </div>
          <div className="mid">
            <div className="nick">
              <Skeleton height={30} />
            </div>
            <div className="price">
              <Skeleton height={30} />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="image--wrapper" onClick={redirectToProductPage}>
            <img className="image" src={path} alt="" />
          </div>
          <div className="heading" onClick={redirectToProductPage}>
            {name}
          </div>
          <div className="mid">
            <div className="nick"> {nick} </div>
            <div className="price">{price} zł</div>
          </div>
          <div className="actions">
            <Button className="btn--frame-dark" label="obserwuj" iconType={SVG_TYPE.HEART} />
            <Button label="do koszyka" className="btn--frame-dark" iconType={SVG_TYPE.BASKET} />
          </div>
        </>
      )}
    </div>
  );
};

export default Product;

Product.propTypes = {
  data: object.isRequired,
  isLoading: bool.isRequired,
};
