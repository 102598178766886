import { Modal, ModalTitle, Button, Checkbox } from "shared/components";
import { VALIDATION_MESSAGES } from "shared/consts";
import { bool, func } from "prop-types";
import ClientPhoto from "assets/files/client_3.png";
import CraftsmanPhoto from "assets/files/craftsman_1.png";
import PartnerPhoto from "assets/files/partner_1.png";
import { USER_TYPE } from "shared/consts";
import { Formik, Field } from "formik";
import { string, object } from "yup";

const TYPE = {
  client: "klientem",
  partner: "partnerem",
  craftsman: "rzemieślnikiem",
};

const UserTypeModal = ({ manageModal, setManageModal, setAccountType }) => {
  const closeModal = () => setManageModal({ isOpen: false, type: null });
  const isPartner = manageModal.type === USER_TYPE.PARTNER;
  const isCraftsman = manageModal.type === USER_TYPE.CRAFTSMAN;
  const isClient = manageModal.type === USER_TYPE.CLIENT;
  const agreement = `Zapoznałem się i akceptuje regulamin oraz politykę serwisu`;
  // const categories = [
  //   {
  //     name: 'nazwa',
  //     label: 'nazwa',
  //     value:'nazwa',
  //   },
  //   {
  //     name: 'kolejna',
  //     label: 'kolejna',
  //     value:'kolejna',
  //   },
  //   {
  //     name: 'trzecia',
  //     label: 'trzecia',
  //     value:'trzecia',
  //   }
  // ]
  const handleOnSubmit = async (values) => {
    await setAccountType(values);
  };

  const validationSchema = () =>
    object().shape({
      agreement: string().required(VALIDATION_MESSAGES.REQUIRED).nullable(),
    });

  const initialValues = {
    agreement: "",
    type: manageModal.type,
  };

  return (
    <Modal
      className="user-type-modal"
      opened={manageModal.isOpen}
      onClose={() => closeModal()}
    >
      <div className="user-type-modal">
        <ul className="user-type-modal--left">
          <Formik
            onSubmit={handleOnSubmit}
            validationSchema={validationSchema()}
            initialValues={initialValues}
            enableReinitialize
          >
            {({ handleSubmit, errors }) => (
              <>
                <ModalTitle title={`Zalety bycia ${TYPE[manageModal.type]}`} />
                {isClient && (
                  <>
                    <li className="user-type-modal__text">
                      - Możliwość używania kodów rabatowych.
                    </li>
                    <li className="user-type-modal__text">
                      - Korzystanie z forum.
                    </li>
                    <li className="user-type-modal__text">
                      - Branie udziału w warsztatach.
                    </li>
                    <li className="user-type-modal__text">
                      - Zapisywanie ulubionych produktów.
                    </li>
                    <li className="user-type-modal__text">
                      - Tworzenie zleceń.
                    </li>
                    <Field
                      type="checkbox"
                      label={agreement}
                      name="agreement"
                      errors={errors}
                      component={Checkbox}
                    />
                    <div className="user-type-modal__buttons">
                      <Button
                        label="Anuluj"
                        className="btn--frame-dark"
                        onClick={() => closeModal()}
                      />
                      <Button
                        label="Zostań klientem"
                        className="btn--normal"
                        onClick={() => handleSubmit()}
                      />
                    </div>
                  </>
                )}
                {isCraftsman && (
                  <div>
                    <li className="user-type-modal__text">
                      - Wystawianie swoich wyrobów.
                    </li>
                    <li className="user-type-modal__text">
                      - Tworzenie profilu.
                    </li>
                    <li className="user-type-modal__text">
                      - Warsztaty i szkolenia.
                    </li>
                    <li className="user-type-modal__text">
                      - Partnerstwa biznesowe.
                    </li>
                    <li className="user-type-modal__text">
                      - Feedback od klientów.
                    </li>
                    <li className="user-type-modal__text">
                      - Łatwe zarządzanie.
                    </li>
                    <Field
                      type="checkbox"
                      label={`Zapoznałem się i akceptuje Regulamin oraz politykę serwisu`}
                      name="agreement"
                      errors={errors}
                      component={Checkbox}
                    />
                    <div className="user-type-modal__buttons">
                      <Button
                        label="Anuluj"
                        className="btn--frame-dark"
                        onClick={() => closeModal()}
                      />
                      <Button
                        label="Zostań rzemieślnikiem"
                        className="btn--normal"
                        onClick={() => handleSubmit()}
                      />
                    </div>
                  </div>
                )}
                {isPartner && (
                  <>
                    <li className="user-type-modal__text">
                      - Promocja swoich surowców.
                    </li>
                    <li className="user-type-modal__text">
                      - Współpraca z rzemieślnikami.
                    </li>
                    <li className="user-type-modal__text">
                      - Zwiększenie klientów.
                    </li>
                    <li className="user-type-modal__text">
                      - Statystyki sprzedaży.
                    </li>
                    <li className="user-type-modal__text">
                      - Partnerzy biznesowi.
                    </li>
                    <li className="user-type-modal__text">
                      - Łatwe zarządzanie.
                    </li>
                    <li className="user-type-modal__text">
                      - Ścieżka sprzedaży.
                    </li>
                    <Field
                      type="checkbox"
                      label={
                        "Zapoznałem się i akceptuje regulamin oraz politykę serwisu"
                      }
                      name="agreement"
                      errors={errors}
                      component={Checkbox}
                    />
                    <div className="user-type-modal__buttons">
                      <Button
                        label="Anuluj"
                        className="btn--frame-dark"
                        onClick={() => closeModal()}
                      />
                      <Button
                        label="Zostań Partnerem"
                        className="btn--normal"
                        onClick={() => handleSubmit()}
                      />
                    </div>
                  </>
                )}
              </>
            )}
          </Formik>
        </ul>
        <div className="user-type-modal--right">
          {isClient && <img src={ClientPhoto} alt="client" />}
          {isPartner && <img src={PartnerPhoto} alt="client" />}
          {isCraftsman && <img src={CraftsmanPhoto} alt="client" />}
        </div>
      </div>
    </Modal>
  );
};

export default UserTypeModal;

UserTypeModal.propTypes = {
  manageModal: bool.isRequired,
  setManageModal: func.isRequired,
  setAccountType: func.isRequired,
};
