import ForumTopUsers from "./ForumTopUsers";
import TopArticles from "./ForumTopArticles";
import ForumSidebarProducts from "./ForumSidebarProducts";
import { useLocation } from "react-router-dom";

const ForumSidebarRight = () => {
  const location = useLocation();
  const isNotQuestionPage = !location.pathname.includes("/forum/pytanie/");

  return (
    <div
      className={`forum-sidebar-right ${
        !isNotQuestionPage ? "forum-sidebar-right--question" : ""
      }`}
    >
      {isNotQuestionPage ? (
        <>
          <ForumTopUsers />
          <TopArticles />
        </>
      ) : (
        <>
          <ForumSidebarProducts />
        </>
      )}
    </div>
  );
};

export default ForumSidebarRight;
