/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { SVG } from 'shared/components';

function Button({ label, type, position, className, onClick, iconPlacement, iconType }) {
  const buttonClasses = `btn ${className}`;
  return (
    <div className={`btn-wrapper btn-wrapper--${position}`}>
      <button className={`${buttonClasses} ${iconType ? 'btn--icon' : ''}`} onClick={onClick} type={type}>
        {iconPlacement === 'left' ? (
          <>
            <SVG type={iconType} className="btn--icon--left" /> <p>{label}</p>
          </>
        ) : (
          <>
            <p>{label}</p> <SVG className={`${iconType ? 'btn--icon--right' : ''}`} type={iconType} />
          </>
        )}
      </button>
    </div>
  );
}

Button.propTypes = {
  className: PropTypes.string.isRequired,
  iconPlacement: PropTypes.string,
  iconType: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  position: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

Button.defaultProps = {
  iconPlacement: 'right',
  iconType: '',
  label: '',
  onClick: null,
  position: 'none',
  type: 'button',
};

export default Button;
