import React from 'react';
import { bool } from 'prop-types';
import { PageContainer, Button, InputCounter, SVG, StarRating } from 'shared/components';
import Sculpture from 'assets/files/products/sculpture.jpg';
import { SVG_TYPE } from 'shared/consts';

const ProductPage = () => (
  <>
    <PageContainer className="product-page">
      <div className="top">
        <div className="product-photo__wrapper">
          <img className="product-photo" src={Sculpture} alt="" />
        </div>
        <div className="right">
          <div className="product-detail">
            <h1 className="name">Rzeźba twarzy andrzeja owsiaka stworzona przez Marcina Najmana</h1>
          </div>
          <StarRating rate={3.5} opinion={4} />
          <div className="nick">jurenst012s</div>
          <div className="product-price">1640,00 zł</div>
          <div className="product-descript">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt repellendus placeat distinctio esse commodi nesciunt
            numquam fuga est incidunt, perspiciatis dolorem? Tempore perspiciatis aspernatur laboriosam cum eos unde facilis eius!
          </div>
          <div className="product-actions">
            <div className="product-actions--top">
              <InputCounter />
              <Button className="btn--default" label="DODAJ DO KOSZYKA" />
            </div>
            <div className="product-actions--bottom">
              <div className="product-actions__watch">
                obserwuj <SVG type={SVG_TYPE.HEART} />{' '}
              </div>
              {/* <div className="product-actions__watch">obserwuj <SVG className='svg--heart-active' type={SVG_TYPE.HEART_FULL} /></div> */}
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  </>
);
export default ProductPage;

ProductPage.propTypes = {
  isMobile: bool.isRequired,
  isModalOpened: bool.isRequired,
};
