import React from 'react';
import { addDelivery as addDeliveryConnector } from 'shared/connectors/deliveryConnector';
import { DELIVERY } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE, NOTIFY_LABEL } from 'shared/consts';

const init = () => ({
  type: DELIVERY.ADD_DELIVERY_INIT,
});

export const success = data => ({
  type: DELIVERY.ADD_DELIVERY_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: DELIVERY.ADD_DELIVERY_FAILURE,
});

const addDelivery = form => async dispatch => {
  dispatch(init());
  try {
    const response = await addDeliveryConnector(form);
    dispatch(success(response.data.delivery));
    toast(<Notify label={NOTIFY_LABEL.DELIVERY_ADD} type={NOTIFY_TYPE.DELIVERY} color="green" />);
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast(<Notify label={NOTIFY_LABEL.SOMETHING_WRONG} type={NOTIFY_TYPE.DELIVERY} color="red" />);
    return err.response.data;
  }
};

export default addDelivery;
