import React, { useState } from 'react';
import { func, array } from 'prop-types';
import { AddCategory, NewCategoryForm, CategoriesList } from './components';

const Category = ({
  createCategory,
  allCategories,
  removeCategory,
  createSubcategory,
  removeSubcategory,
  editCategory,
  editSubcategory,
}) => {
  const [categoryForm, setCategoryForm] = useState(false);
  return (
    <div className="category__wrapper">
      <div className="category__content">
        <CategoriesList
          removeSubcategory={removeSubcategory}
          createSubcategory={createSubcategory}
          allCategories={allCategories}
          removeCategory={removeCategory}
          editCategory={editCategory}
          editSubcategory={editSubcategory}
        />
        {categoryForm ? (
          <NewCategoryForm setCategoryForm={setCategoryForm} createCategory={createCategory} />
        ) : (
          <AddCategory categoryForm={categoryForm} setCategoryForm={setCategoryForm} />
        )}
      </div>
    </div>
  );
};

export default Category;

Category.propTypes = {
  allCategories: array.isRequired,
  createCategory: func.isRequired,
  createSubcategory: func.isRequired,
  editCategory: func.isRequired,
  editSubcategory: func.isRequired,
  removeCategory: func.isRequired,
  removeSubcategory: func.isRequired,
};
