import React from 'react';
import { createCategory as createCategoryConnector } from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

const init = () => ({
  type: CATEGORY.ADD_CATEGORY_INIT,
});

export const success = data => ({
  type: CATEGORY.ADD_CATEGORY_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CATEGORY.ADD_CATEGORY_FAILURE,
});

const createCategory = form => async dispatch => {
  dispatch(init());
  try {
    const response = await createCategoryConnector(form);
    dispatch(success(response.data.category));
    toast(<Notify label="Dodano kategorię" type={NOTIFY_TYPE.CATEGORY} color="green" />);
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast(<Notify label="Ups, coś poszło nie tak" type={NOTIFY_TYPE.CATEGORY} color="red" />);
    return err.response.data;
  }
};

export default createCategory;
