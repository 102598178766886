import React from "react";
import { array, bool, func, shape } from "prop-types";
import { DashboardContent } from "screens/dashboard/components";
import BackgroundArticle from "assets/files/carpentertools2.png";
import Face from "assets/files/face.jpg";
import { SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";

const ArticlePage = () => {
  return (
    <DashboardContent name="Artykuł">
      <div className="article-page">
        <div className="article-page__top">
          <div className="article-page-image__wrapper">
            <img
              className="article-page-image"
              src={BackgroundArticle}
              alt=""
            />
          </div>
          <div className="article-page-author__wrapper">
            <div className="article-page-author__heading">Autor:</div>
            <div className="article-page-author-box">
              <div className="article-page-author-box__top">
                <div className="article-page-author-box__avatar-wrapper">
                  <img
                    className="article-page-author-box__avatar"
                    src={Face}
                    alt=""
                  />
                </div>
                <div className="article-page-author-box__name">kacpero</div>
                <div className="article-page-author-box__crafts">
                  żywica, stolarstwo, garncarstwo
                </div>
                <div className="article-page-author-box__social">
                  <div className="article-page-author-box__single-social">
                    <SVG type={SVG_TYPE.INSTAGRAM} />
                  </div>
                  <div className="article-page-author-box__single-social">
                    <SVG type={SVG_TYPE.FACEBOOK} />
                  </div>
                </div>
              </div>
              <div className="article-page-author-box__bottom">
                <div className="article-page-author-box__button">
                  <p>Pozostałe artykuły</p>
                </div>
                <div className="article-page-author-box__button">
                  <p>Zobacz produkty</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="article-page__mid">
          <div className="article-page__mid-left">
            <div className="article-page__mid-heading">
              10 Najlepszych Narzędzi dla Nowoczesnego Stolarza
            </div>
            <div className="article-page__mid-data">Wrzesień 03.2023</div>
            <div className="article-page__mid-content">
              <div className="article-page__mid-paragraph">
                Stolarstwo to sztuka tworzenia wyjątkowych, trwałych i
                funkcjonalnych przedmiotów z drewna. Nowoczesny stolarz korzysta
                z tradycyjnych technik, ale także z nowoczesnych narzędzi i
                technologii, które ułatwiają pracę i pozwalają osiągnąć jeszcze
                lepsze rezultaty. Jeśli jesteś pasjonatem stolarstwa lub dopiero
                rozpoczynasz swoją przygodę z tym rzemiosłem, to warto
                zastanowić się nad wyposażeniem swojego warsztatu. Poniżej
                przedstawiamy listę 10 najlepszych narzędzi dla nowoczesnego
                stolarza.
              </div>
              <ul className="article-page__mid-list">
                <div className="article-page__mid-list-item">
                  1. piła stołowa
                </div>
                <div className="article-page__mid-list-item">
                  Piła stołowa jest niezastąpionym narzędziem w warsztacie
                  stolarza. Pozwala precyzyjnie przycinać deski i inne materiały
                  drewniane. Wybierając piłę stołową, zwróć uwagę na jej moc,
                  precyzję i funkcje bezpieczeństwa.
                </div>

                <div className="article-page__mid-list-item">2. Frezarka</div>
                <div className="article-page__mid-list-item">
                  Frezarka umożliwia tworzenie ozdobnych rowków, nacięć i innych
                  detali na drewnie. Dostępne są różne rodzaje frezarek, w tym
                  ręczne i stołowe. Wybierz model, który najlepiej odpowiada
                  Twoim potrzebom.
                </div>

                <div className="article-page__mid-list-item">
                  3. Cyfrowy miernik laserowy
                </div>
                <div className="article-page__mid-list-item">
                  Precyzja jest kluczowa w stolarstwie, dlatego cyfrowy miernik
                  laserowy jest nieocenionym narzędziem. Pomaga dokładnie
                  zmierzyć długość, szerokość i głębokość drewnianych elementów.
                </div>

                <div className="article-page__mid-list-item">
                  4. Wiertarko-wkrętarka
                </div>
                <div className="article-page__mid-list-item">
                  Wiertarko-wkrętarka to narzędzie wielofunkcyjne, które ułatwia
                  wiercenie otworów i wkręcanie śrub. Wybierz model z regulowaną
                  prędkością obrotową i baterią o dużej pojemności.
                </div>

                <div className="article-page__mid-list-item">
                  5. Dłuto elektryczne
                </div>
                <div className="article-page__mid-list-item">
                  Dłuto elektryczne jest niezbędne do precyzyjnego wyrywania i
                  wycinania drewnianych detali. Dostępne są różne rodzaje dłut,
                  w tym także te przeznaczone do rzeźbienia.
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </DashboardContent>
  );
};

export default ArticlePage;

ArticlePage.propTypes = {
  actions: shape({
    getUsers: func.isRequired,
    isMobile: bool.isRequired,
    isModalOpened: bool.isRequired,
  }).isRequired,
  users: array.isRequired,
};
