import React from "react";
import { bool, func, shape, object } from "prop-types";
import HomePage from "screens/home-page/HomePage.container";
import Dashboard from "screens/dashboard/Dashboard.container";
import ContactPage from "screens/contact-page/ContactPage.container";
// import ShopPage from "screens/shop-page/ShopPage.container";
import ProductPage from "screens/product-page/ProductPage.container";
import InactiveTokenPage from "screens/inactive-token-page/InactiveTokenPage.container";
import ActivateUserPage from "screens/activate-page/ActivateUserPage.container";
import SendChangePasswordPage from "screens/send-change-password-page/SendChangePasswordPage.container";
import InactiveAccountPage from "screens/inactive-account-page/InactiveAccountPage.container";
import { getAuthorizedUser as getAuthorizedUserSelector } from "store/selectors/userSelector";
import { ROUTES } from "shared/consts";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { bindActionCreators } from "redux";
import { UnderConstruction, MenuTop, Footer } from "shared/components";
import * as UserActions from "store/actions/user";
import { ToastContainer } from "react-toastify";
import {
  getIsDarMode,
  getIsBlurred,
  getIsMenuOpened,
} from "../store/selectors/viewManagementSelector";

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { password: sessionStorage.getItem("unlock") || "false" };
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.actions.getAuthorizedUser();
    }
  }
  render() {
    const { pathname } = window.location;
    const {
      HOME_PAGE,
      CRAFTS,
      CONTACT,
      PRODUCT,
      INACTIVE_TOKEN,
      ACTIVATE_PAGE,
      SEND_LINK_NEW_PASSWORD,
      INACTIVE_ACCOUNT,
      SHOP,
      USERS,
      DELIVERY_PAGE,
      ADD_PRODUCT_PAGE,
      MY_ADVERTISEMENTS,
      CATEGORY,
      EVENT_MANAGE,
      FORUM,
      ADD_EVENT,
      EVENTS,
      SINGLE_EVENT_PAGE,
      CATEGORY_ARTICLE,
      FORUM_ADD_QUESTION,
    } = ROUTES;
    const { isDarkMode, isBlurred, isMenuOpened } = this.props;
    const mainWrapperClass = `main-wrapper ${isDarkMode ? "" : "dark"} ${
      isMenuOpened ? "stop-scrolling" : ""
    }`;
    const dashboardViews = pathname.includes("dashboard");
    const profileViews = pathname.includes("profil");
    const articleViews = pathname.includes("artykul");
    const forumViews = pathname.includes("forum");
    const pageWrapperClass = `page-wrapper ${
      dashboardViews ? "page-wrapper--dashboard" : ""
    }`;
    const isAccessibilityShow =
      !dashboardViews &&
      !profileViews &&
      pathname !== INACTIVE_ACCOUNT &&
      pathname !== INACTIVE_TOKEN &&
      pathname !== SHOP &&
      pathname !== USERS &&
      pathname !== MY_ADVERTISEMENTS &&
      pathname !== ADD_PRODUCT_PAGE &&
      pathname !== DELIVERY_PAGE &&
      pathname !== CRAFTS &&
      pathname !== CATEGORY_ARTICLE &&
      pathname !== CATEGORY &&
      pathname !== SINGLE_EVENT_PAGE &&
      pathname !== ADD_EVENT &&
      pathname !== FORUM &&
      pathname !== FORUM_ADD_QUESTION &&
      pathname !== EVENT_MANAGE &&
      pathname !== EVENTS &&
      !articleViews &&
      !forumViews &&
      pathname !== SEND_LINK_NEW_PASSWORD &&
      pathname !== ACTIVATE_PAGE;

    return (
      <div className={mainWrapperClass}>
        {this.state.password === "true" ? (
          <div id="page-wrapper" className={pageWrapperClass}>
            <ToastContainer
              hideProgressBar
              autoClose={5000}
              position="bottom-right"
            />
            {isAccessibilityShow && <MenuTop />}
            <Switch location={window.location}>
              <Route component={HomePage} exact path={HOME_PAGE} />
              <Route component={ContactPage} exact path={CONTACT} />
              <Route component={ProductPage} exact path={PRODUCT} />
              <Route
                component={InactiveTokenPage}
                exact
                path={INACTIVE_TOKEN}
              />
              <Route component={ActivateUserPage} exact path={ACTIVATE_PAGE} />
              <Route
                component={SendChangePasswordPage}
                exact
                path={SEND_LINK_NEW_PASSWORD}
              />
              <Route
                component={InactiveAccountPage}
                exact
                path={INACTIVE_ACCOUNT}
              />
              <Dashboard />
            </Switch>
            {isAccessibilityShow && <Footer />}
            {isBlurred && <div className="main-wrapper--blurred" />}
          </div>
        ) : (
          <UnderConstruction
            unlock={() => this.setState({ password: "true" })}
          />
        )}
      </div>
    );
  }
}

Routes.propTypes = {
  authorizedUser: object.isRequired,
  isBlurred: bool.isRequired,
  isMenuOpened: bool.isRequired,
  isDarkMode: bool.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAuthorizedUser: UserActions.getAuthorizedUser,
    },
    dispatch
  ),
});

const mapStateToProps = (state) => ({
  authorizedUser: getAuthorizedUserSelector(state),
  isDarkMode: getIsDarMode(state),
  isBlurred: getIsBlurred(state),
  isMenuOpened: getIsMenuOpened(state),
});

Routes.propTypes = {
  actions: shape({
    getAuthUser: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
