import { Button } from "shared/components";
import { ROUTES } from "shared/consts";
import { useHistory } from "react-router-dom";

const ForumHeader = () => {
  const history = useHistory();
  return (
    <div className="event-menu advertisement-menu">
      <div className="advertisement-menu__content">
        {/* <div className="advertisement-menu__title">Pokaż tylko: </div> */}
        <div className="advertisement-menu__list">
          <div className="advertisement-menu__item">
            <div className="advertisement-menu__item--name">Wszystkie</div>
            <div className="advertisement-menu__item--count">{0}</div>
          </div>
        </div>
        <Button
          className="btn--frame-dark"
          label="zadaj pytanie"
          onClick={() => history.push(ROUTES.FORUM_ADD_QUESTION)}
        />
      </div>
    </div>
  );
};

export default ForumHeader;
