import React from 'react';
import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

const init = () => ({
  type: CATEGORY.REMOVE_SUBCATEGORY_INIT,
});

export const success = (subcategoryUuid, categoryUuid) => ({
  type: CATEGORY.REMOVE_SUBCATEGORY_SUCCESS,
  payload: {
    subcategoryUuid,
    categoryUuid,
  },
});

const failure = () => ({
  type: CATEGORY.REMOVE_SUBCATEGORY_FAILURE,
});

const removeSubcategory = (uuid, categoryUuid) => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.removeSubcategory(uuid);
    dispatch(success(data.uuid, categoryUuid));
    toast(<Notify label="Usunięto podkategorię" type={NOTIFY_TYPE.BIN} color="green" />);
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response.data;
  }
};

export default removeSubcategory;
