import React from 'react';
import { func } from 'prop-types';
import { SVG_TYPE, VALIDATION_MESSAGES } from 'shared/consts';
import { RegularField, SVG } from 'shared/components';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';

const EditDelivery = ({ editDelivery, delivery, setDeliveryManage }) => {
  const { REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const validationForm = () =>
    object().shape({
      name: string().required(REQUIRED_FIELD),
      price: string().required(REQUIRED_FIELD),
    });

  const submitForm = async values => {
    const response = await editDelivery(delivery.uuid, values);
    if (response?.status === 'success') {
      setDeliveryManage(false);
    }
  };

  return (
    <>
      <ul>
        <Formik
          initialValues={{ name: delivery?.name, price: delivery?.price }}
          onSubmit={submitForm}
          validationSchema={validationForm()}
        >
          {({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit} className="new-category-form new-delivery-form edit-category-form">
              <Field
                component={RegularField}
                errors={errors}
                name="name"
                placeholder="Nazwa metody dostawy"
                type="text"
                fullWidth
                icon={SVG_TYPE.DELIVERY_ADD}
              />
              <Field
                component={RegularField}
                errors={errors}
                name="price"
                placeholder="cena"
                type="text"
                fullWidth
                icon={SVG_TYPE.PRICE_LABEL}
              />
              <div
                className="subcategory__item--single-action edit-category-form--margin"
                onClick={() => setDeliveryManage(false)}
              >
                <SVG type={SVG_TYPE.PLUS} className="subcategory__item--single-action--plus-rotate" />
              </div>
              <div className="subcategory__item--single-action" onClick={handleSubmit}>
                <SVG type={SVG_TYPE.EDIT} />
              </div>
            </form>
          )}
        </Formik>
      </ul>
    </>
  );
};

export default EditDelivery;

EditDelivery.propTypes = {
  delivery: func.isRequired,
  editDelivery: func.isRequired,
  setDeliveryManage: func.isRequired,
};
