import React from 'react';
import { SVG_TYPE } from 'shared/consts';
import { bool, func, object } from 'prop-types';
import { SVG } from 'shared/components';
import { CloseClickOutside } from 'shared/utils';

const SettingsDots = ({ isOpen, setIsOpen, data }) => {
  const handleClick = () => {
    if (isOpen === data.id) {
      setIsOpen(false);
    } else {
      setIsOpen(data.id);
    }
  };
  return (
    <div className="settings-dots__wrapper">
      <div className="settigs-dots__content">
        <div className={`icon ${isOpen ? 'icon--disabled' : ''}`} onClick={() => handleClick()}>
          <SVG type={SVG_TYPE.DOTS} />
        </div>
        <CloseClickOutside className="settigs-dots__content" onClose={() => setIsOpen(false)}>
          <ul className={`list view ${isOpen === data.id ? 'view--active' : ''}`}>
            <li className="list--item"> Edytuj użytkownika </li>
            <li className="list--item"> Historia zakupów </li>
            <li className="list--item"> Zablokuj użytkownika </li>
          </ul>
        </CloseClickOutside>
      </div>
    </div>
  );
};

export default SettingsDots;

SettingsDots.propTypes = {
  data: object.isRequired,
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
};
