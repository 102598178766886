import { VIEW_MANAGEMENT } from 'store/types';

function setDarkMode(isDarkMode) {
  isDarkMode ? localStorage.setItem('isDarkMode', true) : localStorage.setItem('isDarkMode', false);
  return {
    type: isDarkMode ? VIEW_MANAGEMENT.DARK_MODE_ON : VIEW_MANAGEMENT.DARK_MODE_OFF,
  };
}

export default setDarkMode;
