/* eslint-disable react-hooks/exhaustive-deps */
// import { array, bool, func, shape } from "prop-types";
import React from "react";
import { DashboardContent } from "screens/dashboard/components";
import Plakat from "assets/files/plakat.png";
import { DashboardSectionHeading } from "screens/dashboard/components";
import { Button } from "shared/components";

const SingleEventPage = ({
  actions: { getAuthorizedUser },
  authorizedUser,
}) => {
  return (
    <>
      <DashboardContent goBack name="Wydarzenie ">
        <DashboardSectionHeading name="Warsztaty" />
        <div className="single-event">
          <div className="single-event__content">
            <div className="single-event--top">
              <div className="single-event__image-wrapper">
                <img className="single-event__image" src={Plakat} alt="" />
              </div>
              <div>
                <div className="single-event__heading">
                  Festiwal Rzemiosła: Twórcze Warsztaty Sztuki Rękodzielniczej
                </div>
                <div className="single-event__info">
                  <div className="single-event__info--left">
                    <div className="single-event__date">
                      Data wydarzenia: <span>22.09.2023</span>
                    </div>
                    <div className="single-event__crafts">
                      Rzemiosło: <span>Wszystkie</span>
                    </div>
                    <div className="single-event__count">
                      Ilość miejsc: <span>4/10</span>
                    </div>
                  </div>
                </div>
                <div className="single-event__descript">
                  Dołącz do naszego niezwykłego Festiwalu Rzemiosła, gdzie
                  odkryjesz pasjonujący świat rękodzieła i rzemiosła. Nasza
                  impreza to prawdziwa uczta dla miłośników twórczości i
                  tradycji rzemieślniczych. Przygotowaliśmy dla Ciebie
                  różnorodne warsztaty, w tym:
                </div>
              </div>
            </div>

            <ul className="single-event-list">
              <li className="single-event-list-item">
                <div className="single-event-list-item--number">1.</div>
                Zanurz się w dźwięcznym rytmie młota i naucz się tworzyć
                unikalne przedmioty ze stali.
              </li>
              <li className="single-event-list-item">
                <div className="single-event-list-item--number">2.</div>
                Makrama: Odkryj sztukę węzłów i stwórz niesamowite ozdoby przy
                pomocy technik makramy.
              </li>
              <li className="single-event-list-item">
                <div className="single-event-list-item--number">3.</div>
                Garncarstwo: Poznaj tajniki modelowania gliny i własnoręcznie
                ulep ceramiczne dzieła sztuki.
              </li>
              <li className="single-event-list-item">
                <div className="single-event-list-item--number">4.</div>
                Tkactwo Tradycyjne: Zaprojektuj własny wyjątkowy wzór tkaniny i
                stwórz wyjątkowe tekstylne arcydzieła.
              </li>
              <li className="single-event-list-item">
                <div className="single-event-list-item--number">5.</div>
                Rzeźba w Drewnie: Przetransformuj kawałek drewna w piękną
                rzeźbę, odkrywając tajemnice rzeźbiarstwa.
              </li>
              <li className="single-event-list-item">
                <div className="single-event-list-item--number">6.</div>
                Szkło Artystyczne: Zdobądź umiejętności tworzenia pięknych
                przedmiotów ze szkła przy pomocy technik fusingu i malowania na
                szkle.
              </li>
            </ul>
            <div className="single-event__button">
              <Button
                className="btn--frame-dark"
                onClick={() => null}
                label="Zapisz się na warsztaty"
              />
            </div>
          </div>
        </div>
      </DashboardContent>
    </>
  );
};
export default SingleEventPage;

// CategoryPage.propTypes = {
//   actions: shape({
//     createCategory: func.isRequired,
//     createSubcategory: func.isRequired,
//     getAllCategoriesAndSubcategories: func.isRequired,
//     removeCategory: func.isRequired,
//     removeSubcategory: func.isRequired,
//     editSubcategory: func.isRequired,
//     editCategory: func.isRequired,
//   }).isRequired,
//   categoriesAndSubcategories: array.isRequired,
//   editCategory: func.isRequired,
//   isMobile: bool.isRequired,
//   isModalOpened: bool.isRequired,
// };
