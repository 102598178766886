import React, { useState, useEffect } from "react";
import { array, bool, func, shape } from "prop-types";
import { USER_ROLE } from "shared/consts";
import { Counters, UsersTable } from "./components";
import {
  DashboardContent,
  DashboardSectionHeading,
} from "screens/dashboard/components";

const UsersPage = ({ actions: { getUsers }, users }) => {
  const [userSelection, setUserSelection] = useState(USER_ROLE.CUSTOMER);
  useEffect(() => {
    getUsers({ role: userSelection });
  }, [getUsers, userSelection]);

  return (
    <DashboardContent name="Użytkownicy">
      <DashboardSectionHeading name="Lista użytkowników">
        <Counters
          users={users}
          userSelection={userSelection}
          setUserSelection={setUserSelection}
        />
      </DashboardSectionHeading>
      <UsersTable users={users.users} userSelection={userSelection} />
    </DashboardContent>
  );
};

export default UsersPage;

UsersPage.propTypes = {
  actions: shape({
    getUsers: func.isRequired,
    isMobile: bool.isRequired,
    isModalOpened: bool.isRequired,
  }).isRequired,
  users: array.isRequired,
};
