import React from "react";

const Header = () => (
  <div className="header">
    <p className="first-line">witaj w strefie</p>
    <p className="second-line">RZEMIEŚLNIKA</p>
    <p className="descript">
      serwisu dla pasjonatów rękodzieła i rzemiosła! Tutaj możesz sprzedawać
      swoje wyjątkowe prace, kupować potrzebne surowce oraz wymieniać się wiedzą
      z innymi na naszym forum. Poznaj nowe techniki i trendy, zadawaj pytania i
      dziel się swoimi pomysłami. w HandArts zawsze znajdziesz inspirację i
      wsparcie.
    </p>
  </div>
);

export default Header;
