import { SVG_TYPE, ROUTES } from "shared/consts";

const USER_MENU = [
  {
    label: "Profil",
    svg_type: SVG_TYPE.USER,
    routes: ROUTES.PROFILE,
  },
  {
    label: "Sklep",
    svg_type: SVG_TYPE.SHOP,
    routes: ROUTES.SHOP,
  },
  {
    label: "Rzemiosło",
    svg_type: SVG_TYPE.CRAFTS,
    routes: ROUTES.CRAFTS,
  },
  {
    label: "Wydarzenia",
    svg_type: SVG_TYPE.CALENDAR,
    routes: ROUTES.EVENTS,
  },
  {
    label: "Forum",
    svg_type: SVG_TYPE.FORUM_ICON,
    routes: ROUTES.FORUM,
  },
];

const ADMIN_MENU = [
  {
    label: "Profil",
    svg_type: SVG_TYPE.USER,
    routes: ROUTES.PROFILE,
  },
  {
    label: "Sklep",
    svg_type: SVG_TYPE.SHOP,
    routes: ROUTES.SHOP,
  },
  {
    label: "Rzemiosło",
    svg_type: SVG_TYPE.CRAFTS,
    routes: ROUTES.CRAFTS,
  },
  {
    label: "Wydarzenia",
    svg_type: SVG_TYPE.CALENDAR,
    routes: ROUTES.EVENTS,
  },
  {
    label: "Użytkownicy",
    svg_type: SVG_TYPE.USERS,
    routes: ROUTES.USERS,
  },
  {
    label: "Forum",
    svg_type: SVG_TYPE.FORUM_ICON,
    routes: ROUTES.FORUM,
  },
];

export { USER_MENU, ADMIN_MENU };
