const USER = {
  SET_ACCOUNT_TYPE_INIT: "SET_ACCOUNT_TYPE_INIT",
  SET_ACCOUNT_TYPE_SUCCESS: "SET_ACCOUNT_TYPE_SUCCESS",
  SET_ACCOUNT_TYPE_FAILURE: "SET_ACCOUNT_TYPE_FAILURE",
  ACTIVATE_ACCOUNT_INIT: "ACTIVATE_ACCOUNT_INIT",
  ACTIVATE_ACCOUNT_SUCCESS: "ACTIVATE_ACCOUNT_SUCCESS",
  ACTIVATE_ACCOUNT_FAILURE: "ACTIVATE_ACCOUNT_FAILURE",
  SEND_RESET_PASSWORD_EMAIL_INIT: "SEND_RESET_PASSWORD_EMAIL_INIT",
  SEND_RESET_PASSWORD_EMAIL_SUCCESS: "SEND_RESET_PASSWORD_EMAIL_SUCCESS",
  SEND_RESET_PASSWORD_EMAIL_FAILURE: "SEND_RESET_PASSWORD_EMAIL_FAILURE",
  SET_NEW_PASSWORD_INIT: "SET_NEW_PASSWORD_INIT",
  SET_NEW_PASSWORD_SUCCESS: "SET_NEW_PASSWORD_SUCCESS",
  SET_NEW_PASSWORD_FAILURE: "SET_NEW_PASSWORD_FAILURE",
  CHECK_TOKEN_INIT: "CHECK_TOKEN_INIT",
  CHECK_TOKEN_SUCCESS: "CHECK_TOKEN_SUCCESS",
  CHECK_TOKEN_FAILURE: "CHECK_TOKEN_FAILURE",
  GET_USERS_BY_ROLE_INIT: "GET_USERS_BY_ROLE_INIT",
  GET_USERS_BY_ROLE_SUCCESS: "GET_USERS_BY_ROLE_SUCCESS",
  GET_USERS_BY_ROLE_FAILURE: "GET_USERS_BY_ROLE_FAILURE",
  USER_SUSPENSION_INIT: "USER_SUSPENSION_INIT",
  USER_SUSPENSION_SUCCESS: "USER_SUSPENSION_SUCCESS",
  USER_SUSPENSION_FAILURE: "USER_SUSPENSION_FAILURE",
  USER_RESEND_LINK_INIT: "USER_RESEND_LINK_INIT",
  USER_RESEND_LINK_SUCCESS: "USER_RESEND_LINK_SUCCESS",
  USER_RESEND_LINK_FAILURE: "USER_RESEND_LINK_FAILURE",
  USER_BY_ADMIN_RESEND_LINK_INIT: "USER_BY_ADMIN_RESEND_LINK_INIT",
  USER_BY_ADMIN_RESEND_LINK_SUCCESS: "USER_BY_ADMIN_RESEND_LINK_SUCCESS",
  USER_BY_ADMIN_RESEND_LINK_FAILURE: "USER_BY_ADMIN_RESEND_LINK_FAILURE",
  GET_USERS_INIT: "GET_USERS_INIT",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  GET_USERS_FOR_APPLICATION_INIT: "GET_USERS_FOR_APPLICATION_INIT",
  GET_USERS_FOR_APPLICATION_SUCCESS: "GET_USERS_FOR_APPLICATION_SUCCESS",
  GET_USERS_FOR_APPLICATION_FAILURE: "GET_USERS_FOR_APPLICATION_FAILURE",
  GET_USER_DETAILS_INIT: "GET_USER_DETAILS_INIT",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  GET_USER_DETAILS_FAILURE: "GET_USER_DETAILS_FAILURE",
  SET_USER_PASSWORD_INIT: "SET_USER_PASSWORD_INIT",
  SET_USER_PASSWORD_SUCCESS: "SET_USER_PASSWORD_SUCCESS",
  SET_USER_PASSWORD_FAILURE: "SET_USER_PASSWORD_FAILURE",
  SET_PASSWORD_INIT: "SET_PASSWORD_INIT",
  SET_PASSWORD_SUCCESS: "SET_PASSWORD_SUCCESS",
  SET_PASSWORD_FAILURE: "SET_PASSWORD_FAILURE",
};

export default USER;
