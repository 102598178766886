import { getMyProducts as getMyProductsConnector } from 'shared/connectors/productConnector';
import { PRODUCT } from 'store/types';

const init = () => ({
  type: PRODUCT.GET_MY_PRODUCTS_INIT,
});

export const success = data => ({
  type: PRODUCT.GET_MY_PRODUCTS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRODUCT.GET_MY_PRODUCTS_FAILURE,
});

const getMyProducts = product => async dispatch => {
  dispatch(init());
  try {
    const response = await getMyProductsConnector(product);
    dispatch(success(response.data));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return err.response.data;
  }
};

export default getMyProducts;
