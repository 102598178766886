import React from 'react';
import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

const init = () => ({
  type: USER.SEND_RESET_PASSWORD_EMAIL_INIT,
});

export const success = () => ({
  type: USER.SEND_RESET_PASSWORD_EMAIL_SUCCESS,
});

const failure = () => ({
  type: USER.SEND_RESET_PASSWORD_EMAIL_FAILURE,
});

const sendResetPasswordEmail = email => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.resetPassword(email);
    toast(<Notify label="Wysłano link do resetu hasła" type={NOTIFY_TYPE.SEND_MAIL} color="green" />);
    dispatch(success());
    return data;
  } catch (err) {
    dispatch(failure());
    if (err.response.data.message === 'User is inactive') {
      toast(<Notify label="Użytkownik nie został aktywowany" type={NOTIFY_TYPE.WARNING} color="red" />);
    } else {
      toast(<Notify label="Nie ma takiego użytkownika" type={NOTIFY_TYPE.WARNING} color="red" />);
    }
    return err.response.data;
  }
};

export default sendResetPasswordEmail;
