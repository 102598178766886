import API from 'shared/connectors/config';

export function addFiles(file, { product_id, fileFormat }) {
  return API.post(
    `/files${product_id ? `?product_id=${product_id}` : ''}${fileFormat ? `&file_format=${fileFormat}` : ''}`,
    file,
  );
}

export function deleteFiles(uuid) {
  return API.delete(`/files/${uuid}`);
}
