import API from 'shared/connectors/config';

export function createCategory(data) {
  return API.post('/categories', data);
}

export function getAllCategoriesAndSubcategories() {
  return API.get('/categories/all');
}

export function getAllCategories() {
  return API.get('/categories');
}

export function getSingleCategory(uuid) {
  return API.get(`/categories/${uuid}`);
}

export function removeCategory(uuid) {
  return API.delete(`/categories/${uuid}`);
}

export function createSubcategory(data) {
  return API.post('/subcategories', data);
}

export function removeSubcategory(uuid) {
  return API.delete(`/subcategories/${uuid}`);
}

export function editCategory(uuid, data) {
  return API.put(`/categories/${uuid}`, data);
}

export function editSubcategory(uuid, data) {
  return API.put(`/subcategories/${uuid}`, data);
}
