/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { array, bool, func, shape } from "prop-types";
import { Category } from "./components";
import { DashboardContent } from "screens/dashboard/components";

const CategoryPage = ({
  categoriesAndSubcategories,
  actions: {
    createCategory,
    getAllCategoriesAndSubcategories,
    removeCategory,
    createSubcategory,
    removeSubcategory,
    editCategory,
    editSubcategory,
  },
}) => {
  useEffect(async () => {
    await getAllCategoriesAndSubcategories();
  }, [getAllCategoriesAndSubcategories]);
  return (
    <>
      <DashboardContent goBack name="Kategorie ">
        <Category
          editSubcategory={editSubcategory}
          editCategory={editCategory}
          createSubcategory={createSubcategory}
          removeCategory={removeCategory}
          createCategory={createCategory}
          allCategories={categoriesAndSubcategories}
          removeSubcategory={removeSubcategory}
        />
      </DashboardContent>
    </>
  );
};
export default CategoryPage;

CategoryPage.propTypes = {
  actions: shape({
    createCategory: func.isRequired,
    createSubcategory: func.isRequired,
    getAllCategoriesAndSubcategories: func.isRequired,
    removeCategory: func.isRequired,
    removeSubcategory: func.isRequired,
    editSubcategory: func.isRequired,
    editCategory: func.isRequired,
  }).isRequired,
  categoriesAndSubcategories: array.isRequired,
  editCategory: func.isRequired,
  isMobile: bool.isRequired,
  isModalOpened: bool.isRequired,
};
