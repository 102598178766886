import Articles1 from "assets/files/carpentertoolwebsite.png";
import Articles2 from "assets/files/articles2.png";
import Articles3 from "assets/files/articles3.png";
import { Button } from "shared/components";
import { ROUTES } from "shared/consts";
import { useHistory } from "react-router-dom";

const CraftsArticles = () => {
  const history = useHistory();
  return (
    <>
      <div className="crafts-articles">
        <div className="crafts-articles__content">
          <div className="crafts-articles__list">
            <div className="crafts-articles__item">
              <div className="crafts-articles__item-image">
                <img src={Articles1} alt="" className="src" />
              </div>
              <div className="crafts-articles__item-text">
                <div className="crafts-articles__item-heading">
                  10 Najlepszych Narzędzi dla Nowoczesnego Stolarza
                </div>
                <div className="crafts-articles__item-description">
                  Odkryj, jakie narzędzia są niezbędne dla każdego stolarza, od
                  tradycyjnych aż po te z nowoczesnymi udogodnieniami
                  technologicznymi.
                </div>
                <Button
                  label="zobacz artykuł"
                  className="btn--frame-dark"
                  onClick={() =>
                    history.push({
                      pathname: ROUTES.ARTICLE.replace(
                        "article",
                        "10-najlepszych-narzedzi-dla-stolarza"
                      ),
                    })
                  }
                />
              </div>
            </div>
            <div className="crafts-articles__item">
              <div className="crafts-articles__item-image">
                <img src={Articles2} alt="" className="btn--frame-dark" />
              </div>
              <div className="crafts-articles__item-text">
                <div className="crafts-articles__item-heading">
                  Jak Zacząć Przygodę z Żywicą – Podstawy dla Początkujących
                </div>
                <div className="crafts-articles__item-description">
                  Zastanawiasz się, jak zacząć pracę z żywicą? Oto podstawy,
                  które pomogą Ci stworzyć swoje pierwsze dzieło.
                </div>
                <Button
                  label="zobacz artykuł"
                  className="btn--frame-dark"
                  onClick={() => null}
                />
              </div>
            </div>
            <div className="crafts-articles__item">
              <div className="crafts-articles__item-image">
                <img src={Articles3} alt="" className="src" />
              </div>
              <div className="crafts-articles__item-text">
                <div className="crafts-articles__item-heading">
                  Sztuka Kowalstwa Artystycznego: Ręka Mistrza w Twoim Domu
                </div>
                <div className="crafts-articles__item-description">
                  Dowiedz się, jak kowalstwo artystyczne może dodać charakteru i
                  stylu Twojemu domowi. Odkryj tajemnice tej starożytnej sztuki.
                </div>
                <Button
                  label="zobacz artykuł"
                  className="btn--frame-dark"
                  onClick={() => null}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CraftsArticles;
