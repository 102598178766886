import React from 'react';
import { addProduct as addProductConnector } from 'shared/connectors/productConnector';
import { PRODUCT } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE, NOTIFY_LABEL } from 'shared/consts';

const init = () => ({
  type: PRODUCT.ADD_PRODUCT_INIT,
});

export const success = data => ({
  type: PRODUCT.ADD_PRODUCT_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: PRODUCT.ADD_PRODUCT_FAILURE,
});

const addProduct = product => async dispatch => {
  dispatch(init());
  try {
    const response = await addProductConnector(product);
    toast(<Notify label={NOTIFY_LABEL.PRODUCT_ADD} type={NOTIFY_TYPE.PRODUCT} color="green" />);
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast(<Notify label={NOTIFY_LABEL.SOMETHING_WRONG} type={NOTIFY_TYPE.PRODUCT} color="red" />);
    return err.response.data;
  }
};

export default addProduct;
