import React from 'react';
import PropTypes from 'prop-types';
import { LOGIN_MANAGE_TYPE } from 'shared/consts';

const { LOGIN } = LOGIN_MANAGE_TYPE;
const SetPasswordForm = ({ changeModalType }) => (
  <div>
    <p>formularz resetu hasła</p>
    <div onClick={() => changeModalType(LOGIN)}> powrót do logowania </div>
  </div>
);

export default SetPasswordForm;

SetPasswordForm.propTypes = {
  changeModalType: PropTypes.func.isRequired,
};
