import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { AnimatedProgressProvider } from "shared/utils";
import { easeQuadInOut } from "d3-ease";

const MonthlySales = () => {
  return (
    <div className="monthly-sales">
      <div className="monthly-sales__CircularProgressbar">
        <div className="monthly-sales__CircularProgressbar__content">
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={66}
            duration={1.4}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              const roundedValue = Math.round(value);
              return (
                <CircularProgressbar
                  value={value}
                  text={`${roundedValue}%`}
                  styles={buildStyles({ pathTransition: "none" })}
                />
              );
            }}
          </AnimatedProgressProvider>
        </div>
      </div>
      <div className="monthly-sales-right">
        <div className="monthly-sales-text">
          Porównanie sprzedaży do poprzedniego miesiąca
        </div>
      </div>
    </div>
  );
};

export default MonthlySales;
