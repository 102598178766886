import React, { useEffect } from "react";
import { array, bool, func, shape, object } from "prop-types";
import { AddProductForm } from "./components";
import { DashboardContent } from "screens/dashboard/components";

const AddProductPage = ({
  actions: {
    getAllCategories,
    getSingleCategory,
    getDeliveries,
    addProduct,
    addFiles,
  },
  categories,
  singleCategory,
  deliveries,
}) => {
  useEffect(() => {
    if (!categories.length > 0) {
      getAllCategories();
    }
  }, [categories.length, getAllCategories]);

  useEffect(() => {
    !deliveries.length > 0 && getDeliveries();
  }, [deliveries.length, getDeliveries]);

  return (
    <>
      <DashboardContent goBack name="Dodaj produkt">
        <div className="add-product-page__wrapper">
          <AddProductForm
            addFiles={addFiles}
            addProduct={addProduct}
            deliveries={deliveries}
            singleCategory={singleCategory}
            getSingleCategory={getSingleCategory}
            categories={categories}
          />
        </div>
      </DashboardContent>
    </>
  );
};
export default AddProductPage;

AddProductPage.propTypes = {
  actions: shape({
    addFiles: func.isRequired,
    getAllCategories: func.isRequired,
    getDeliveries: func.isRequired,
    getSingleCategory: func.isRequired,
  }).isRequired,
  addProduct: func.isRequired,
  categories: array.isRequired,
  deliveries: array.isRequired,
  isMobile: bool.isRequired,
  isModalOpened: bool.isRequired,
  singleCategory: object.isRequired,
};
