import { string, func, boolean } from "prop-types";
import { UserIcon, SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";

const MyAccountBadge = ({
  className,
  onClick,
  isMenuOpen,
  authorizedUser,
  chevrone,
}) => {
  const accountClass = className ? className : null;

  return (
    <div className={`my-account-badge ${accountClass}`}>
      <UserIcon className="my-account-badge--user-icon" />
      <div
        className={`my-account-badge__label ${
          isMenuOpen ? "my-account-badge__label--active" : ""
        }`}
        onClick={() => onClick()}
      >
        <p className="my-account-badge__nickname">
          {authorizedUser ? (
            authorizedUser?.user_name
          ) : (
            <SVG type={SVG_TYPE.LOADING} />
          )}
        </p>
        {chevrone && (
          <SVG
            className={`my-account-badge__chevrone ${
              isMenuOpen ? "my-account-badge__chevrone--active" : ""
            }`}
            type={SVG_TYPE.CHEVRONE}
          />
        )}
      </div>
    </div>
  );
};

export default MyAccountBadge;

MyAccountBadge.propTypes = {
  className: string,
  onClick: func,
  isMenuOpen: boolean,
};
