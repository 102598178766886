import { connect } from "react-redux";
import * as ViewManagementActions from "store/actions/viewManagementActions";
import { getIsModalOpened } from "store/selectors/viewManagementSelector";
import { isMobile as checkIsMobile } from "shared/utils";
import { bindActionCreators } from "redux";
import { getAuthorizedUser as getAuthorizedUserSelector } from "store/selectors/userSelector";
import ProfilePage from "./ProfilePage.main";
import * as UserActions from "store/actions/user";
import { getAllCategories } from "store/actions/category";
import { getAllCategories as getAllCategoriesSelector } from "store/selectors/categorySelector";
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setAccountType: UserActions.setAccountType,
      getAuthorizedUser: UserActions.getAuthorizedUser,
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
      getAllCategories: () => getAllCategories(),
    },
    dispatch
  ),
});

function mapStateToProps(state) {
  return {
    categories: getAllCategoriesSelector(state),
    authorizedUser: getAuthorizedUserSelector(state),
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
