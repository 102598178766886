import React from 'react';
import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

export const success = data => ({
  type: CATEGORY.EDIT_SUBCATEGORY_SUCCESS,
  payload: {
    data,
  },
});

const editSubcategory = (uuid, form) => async dispatch => {
  try {
    const linkModified = form.link.replace(/\s/g, '-').replace('/', '');
    const { data } = await Connector.editSubcategory(uuid, { name: form.name, link: linkModified });
    dispatch(success(data));
    toast(<Notify label="Pomyślna edycja podkategorii " type={NOTIFY_TYPE.EDIT} color="green" />);
    return data;
  } catch (err) {
    return err.response;
  }
};

export default editSubcategory;
