import API from "shared/connectors/config";

export function userRegister(data) {
  return API.post("users", data);
}

export function getAuthorizedUser() {
  return API.get("users/me");
}

export function checkToken(token, which) {
  return API.get(`users/password/reset/check/?${which}=${token}`);
}

export function userResendLink(email) {
  return API.get(`users/resend-link/?email=${email}`);
}

export function activateAccount(code) {
  return API.put(`users/activate/?code=${code.replace(/['"]+/g, "")}`);
}

export function resetPassword(email) {
  return API.get(`users/password/reset/?email=${email}`);
}

export function getUsers(params) {
  return API.get(`users/${params.role ? `?role=${params.role}` : ""}`);
}

export function setNewPassword(data) {
  return API.put("users/password/reset", data);
}

export function setAccountType(data) {
  return API.put("users/me/update", data);
}
