import React from 'react';
import { SVG_TYPE, VALIDATION_MESSAGES } from 'shared/consts';
import { RegularField, SVG } from 'shared/components';
import { Field, Formik } from 'formik';
import { object, string } from 'yup';
import { func } from 'prop-types';

const DeliverItemForm = ({ setDeliveryManage, addDelivery }) => {
  const { REQUIRED_FIELD } = VALIDATION_MESSAGES;

  const validationForm = () =>
    object().shape({
      name: string().required(REQUIRED_FIELD),
      price: string().required(REQUIRED_FIELD),
    });

  const closeForm = () => {
    setDeliveryManage(false);
  };

  const submitForm = async (values, actions) => {
    addDelivery(values);
    actions.resetForm();
  };

  return (
    <div className="delivery__form">
      <Formik initialValues={{ name: '', price: '' }} onSubmit={submitForm} validationSchema={validationForm()}>
        {({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit} className="new-category-form add-delivery-form">
            <Field
              component={RegularField}
              errors={errors}
              name="name"
              placeholder="Nazwa metody dostawy"
              type="text"
              fullWidth
              icon={SVG_TYPE.DELIVERY_ADD}
            />
            <Field component={RegularField} errors={errors} name="price" placeholder="sugerowana cena" type="text" fullWidth />
            <div className="subcategory__item--actions">
              <div className="subcategory__item--single-action" onClick={handleSubmit}>
                <SVG type={SVG_TYPE.PLUS} />
              </div>
              <div
                className="subcategory__item--single-action subcategory__item--single-action--plus-rotate"
                onClick={() => closeForm()}
              >
                <SVG type={SVG_TYPE.PLUS} />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default DeliverItemForm;

DeliverItemForm.propTypes = {
  addDelivery: func.isRequired,
  setDeliveryManage: func.isRequired,
};
