import { func, number, object } from 'prop-types';
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const SubcategoryItem = ({ subcategory, openModal, setManageSubcategoryForm, id, subcategoryIndex }) => (
  <li className="subcategory__item">
    <div className="left">
      <SVG type={SVG_TYPE.SUBCATEGORY} />
      <span>{subcategory.name}</span>
    </div>
    <div className="subcategory__item--actions">
      <div
        className="subcategory__item--single-action"
        onClick={() =>
          setManageSubcategoryForm({ isOpen: true, id, isEditSubcategory: true, subcategory, index: subcategoryIndex })
        }
      >
        <SVG type={SVG_TYPE.EDIT} />
      </div>
      <div className="subcategory__item--single-action" onClick={() => openModal(subcategory, 'subcategory')}>
        <SVG type={SVG_TYPE.MINUS} />
      </div>
    </div>
  </li>
);

export default SubcategoryItem;

SubcategoryItem.propTypes = {
  id: number.isRequired,
  openModal: func.isRequired,
  setManageSubcategoryForm: func.isRequired,
  subcategory: object.isRequired,
  subcategoryIndex: number.isRequired,
};
