import { useState } from "react";
import DatePicker from "react-datepicker";
import { number, func, shape, oneOfType, string, bool } from "prop-types";
import "react-datepicker/dist/react-datepicker.css";

const months = [
  "Styczeń",
  "Luty",
  "Marzec",
  "Kwiecień",
  "Maj",
  "Czerwiec",
  "Lipiec",
  "Sierpień",
  "Wrzesień",
  "Październik",
  "Listopad",
  "Grudzień",
];
const days = ["Pon", "Wt", "Śr", "Czw", "Pt", "Sob", "Niedz"];

const locale = {
  localize: {
    day: (n) => days[n],
    month: (n) => months[n],
  },
  formatLong: {
    date: () => "dd/mm/yyyy",
  },
};

const DatePickerField = ({
  field: { name, onChange, value },
  label,
  form,
  onBlur,
  className,
  disabled,
  placeholder,
  errors,
}) => {
  const [startDate, setStartDate] = useState();
  const isError = form && !!(form.touched[name] && errors[name]);

  return (
    <div className={`date-picker-field__wrapper ${className || ""}`}>
      {label && (
        <div
          className={`date-picker-field__label ${
            disabled ? "date-picker-field__label--disabled" : ""
          }`}
        >
          {label}
        </div>
      )}
      <DatePicker
        onBlur={onBlur}
        placeholder={placeholder}
        locale={locale}
        className={`date-picker-field ${
          isError ? "date-picker-field__input--error" : ""
        } ${className ? `date-picker-field__${className}` : ""}`}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
      />
      {isError && (
        <div className="select-field__error-label">{errors[name]}</div>
      )}
    </div>
  );
};

export default DatePickerField;

DatePickerField.defaultProps = {
  disabled: false,
  string: "",
};

DatePickerField.propTypes = {
  field: shape({
    name: string,
    onChange: func,
    value: oneOfType([string, number]),
  }).isRequired,
  placeholder: string,
  onBlur: func.isRequired,
  disabled: bool,
};
