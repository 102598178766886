/* eslint-disable no-unused-expressions */
import React, { useEffect } from "react";
import { bool, object } from "prop-types";
import { Switch } from "react-router-dom";
import { RoleRoute } from "shared/components";
import { ROUTES } from "shared/consts";
import AddProductPage from "screens/add-product-page/AddProductPage.container";
import MyAdvertisementsPage from "screens/my-advertisements-page/MyAdvertisementsPage.container";
import CategoryPage from "screens/category-page/CategoryPage.container";
import CraftsPage from "screens/crafts-page/CraftsPage.container";
import UsersPage from "screens/users-page/UsersPage.container";
import ArticlePage from "screens/article-page/ArticlePage.container";
import AddEventPage from "screens/add-event-page/AddEventPage.container";
import EventManagePage from "screens/event-manage-page/EventManagePage.container";
// import FavoritesPage from "screens/favorites-page/FavoritesPage.container";
import ProfilePage from "screens/profile-page/ProfilePage.container";
import EventsPage from "screens/events-page/EventsPage.container";
import SingleEventPage from "screens/single-event-page/SingleEventPage.container";
import ForumPage from "screens/forum-page/ForumPage.container";
import DeliveryPage from "screens/delivery-page/DeliveryPage.container";
import ShopPage from "screens/shop-page/ShopPage.container";
import { Sidebar } from "./components";
import { PageContainer } from "shared/components";
import { Route } from "react-router-dom";

const Dashboard = ({ authorizedUser, actions: { getAuthorizedUser } }) => {
  useEffect(() => {
    !authorizedUser && getAuthorizedUser();
  }, [authorizedUser, getAuthorizedUser]);

  return (
    <PageContainer full className="profile-page">
      <div className="content__wrapper">
        <div className={`content`}>
          <Sidebar user={authorizedUser} setMenu={() => null} />
          <Switch location={window.location}>
            <RoleRoute
              component={ProfilePage}
              authorizedUserRole={authorizedUser?.role}
              path={ROUTES.PROFILE}
            />
            <Route
              component={ShopPage}
              authorizedUserRole={authorizedUser?.role}
              path={ROUTES.SHOP}
              exact
            />
            <RoleRoute
              authorizedUserRole={authorizedUser?.role}
              component={UsersPage}
              path={ROUTES.USERS}
              exact
            />
            <RoleRoute
              authorizedUserRole={authorizedUser?.role}
              component={DeliveryPage}
              path={ROUTES.DELIVERY_PAGE}
              exact
            />
            <RoleRoute
              authorizedUserRole={authorizedUser?.role}
              component={MyAdvertisementsPage}
              path={ROUTES.MY_ADVERTISEMENTS}
              exact
            />
            <RoleRoute
              authorizedUserRole={authorizedUser?.type}
              component={AddProductPage}
              exact
              path={ROUTES.ADD_PRODUCT_PAGE}
            />
            <Route
              authorizedUserRole={authorizedUser?.type}
              component={CraftsPage}
              path={ROUTES.CRAFTS}
              exact
            />
            <RoleRoute
              authorizedUserRole={authorizedUser?.role}
              component={CraftsPage}
              path={ROUTES.CATEGORY_ARTICLE}
              exact
            />
            <RoleRoute
              authorizedUserRole={authorizedUser?.role}
              component={CategoryPage}
              path={ROUTES.CATEGORY}
            />
            <Route
              authorizedUserRole={authorizedUser?.role}
              component={ArticlePage}
              path={ROUTES.ARTICLE}
            />
            <Route
              authorizedUserRole={authorizedUser?.role}
              component={EventsPage}
              path={ROUTES.EVENTS}
            />
            <RoleRoute
              authorizedUserRole={authorizedUser?.role}
              component={AddEventPage}
              path={ROUTES.ADD_EVENT}
            />
            <RoleRoute
              authorizedUserRole={authorizedUser?.role}
              component={EventManagePage}
              path={ROUTES.EVENT_MANAGE}
            />
            <Route
              authorizedUserRole={authorizedUser?.role}
              component={SingleEventPage}
              path={ROUTES.SINGLE_EVENT_PAGE}
            />
            <Route
              authorizedUserRole={authorizedUser?.role}
              component={ForumPage}
              path={ROUTES.FORUM}
            />
            <Route
              authorizedUserRole={authorizedUser?.role}
              component={ForumPage}
              path={ROUTES.FORUM_ADD_QUESTION}
            />
            {/* <RoleRoute authorizedUserRole={authorizedUser?.role} component={AddProductPage} exact path={ROUTES.ADD_PRODUCT_PAGE} />
              <RoleRoute component={MyAdvertisementsPage} exact path={ROUTES.MY_ADVERTISEMENTS} /> */}
            {/* ADMIN ONLY */}
            {/* <RoleRoute authorizedUserRole={authorizedUser?.role} component={CategoryPage} exact path={ROUTES.CATEGORY} />
              <RoleRoute authorizedUserRole={authorizedUser?.role} component={DeliveryPage} exact path={ROUTES.DELIVERY_PAGE} />
              <RoleRoute component={FavoritesPage} exact path={ROUTES.FAVORITES} /> */}
            {/* ADMIN ONLY END */}
          </Switch>
        </div>
      </div>
    </PageContainer>
  );
};

export default Dashboard;

Dashboard.propTypes = {
  authorizedUser: object.isRequired,
  isMobile: bool.isRequired,
  isModalOpened: bool.isRequired,
};
