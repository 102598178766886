import { SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";

const ForumLastAdded = () => {
  return (
    <div className="forum-last-added">
      <div className="forum-page__header">Ostatnie tematy</div>
      <div className="forum-last-added__content">
        <div className="forum-last-added-item">
          <div className="forum-last-added-item__top">
            <SVG type={SVG_TYPE.CALENDAR} />
            <div className="forum-last-added-item__name">
              Festiwal Rzemiosła: Twórcze Warsztaty Sztuki Rękodzielniczej
            </div>
          </div>
          <div className="forum-last-added-item__bottom">
            <div className="forum-last-added-item__date">5s temu</div>
          </div>
        </div>
        <div className="forum-last-added-item">
          <div className="forum-last-added-item__top">
            <SVG type={SVG_TYPE.FORUM_ICON} />
            <div className="forum-last-added-item__name">
              Forum to zazwyczaj sekcja, która wyświetla najnowsze wątki lub
              dyskusje, które zostały utworzone lub dodane przez użytkowników.
            </div>
          </div>
          <div className="forum-last-added-item__bottom">
            <div className="forum-last-added-item__date">3h temu</div>
          </div>
        </div>
        <div className="forum-last-added-item">
          <div className="forum-last-added-item__top">
            <SVG type={SVG_TYPE.FORUM_ICON} />
            <div className="forum-last-added-item__name">
              Może zawierać nowe wątki, komentarze do istniejących
            </div>
          </div>
          <div className="forum-last-added-item__bottom">
            <div className="forum-last-added-item__date">12h temu</div>
          </div>
        </div>
        <div className="forum-last-added-item">
          <div className="forum-last-added-item__top">
            <SVG type={SVG_TYPE.FORUM_ICON} />
            <div className="forum-last-added-item__name">
              Festiwal Rzemiosła: Twórcze Warsztaty Sztuki Rękodzielniczej
            </div>
          </div>
          <div className="forum-last-added-item__bottom">
            <div className="forum-last-added-item__date">wczoraj</div>
          </div>
        </div>
        <div className="forum-last-added-item">
          <div className="forum-last-added-item__top">
            <SVG type={SVG_TYPE.FORUM_ICON} />
            <div className="forum-last-added-item__name">
              Ogólnie mówiąc, jest to miejsce, w którym użytkownicy mogą śledzić
              najnowsze dyskusje
            </div>
          </div>
          <div className="forum-last-added-item__bottom">
            <div className="forum-last-added-item__date">3 dni temu</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForumLastAdded;
