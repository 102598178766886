import { SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";
import { useHistory } from "react-router-dom";
import { ROUTES } from "shared/consts";
const CraftsEvents = () => {
  const history = useHistory();
  return (
    <div className="crafts-events">
      <h2 className="crafts-events__title">
        Wydarzenia:
        <span onClick={() => history.push(ROUTES.EVENTS)}>
          <SVG type={SVG_TYPE.CALENDAR} />
        </span>
      </h2>
      <div className="calendar-cart__month">wrzesień</div>
      <div
        className="calendar-cart"
        onClick={() =>
          history.push(`${ROUTES.SINGLE_EVENT_PAGE}?id=178hausjdau7dg8uhjbmnzx`)
        }
      >
        <div className="calendar-cart__date">
          <SVG type={SVG_TYPE.EMPTY_CALENDAR} />
          <div className="calendar-cart__date-number">10</div>
        </div>
        <div className="calendar-cart__descript">
          <div className="calendar-cart__descript-title">
            Warsztaty Kowalskie: Sztuka Kowalstwa w Twoich Rękach
          </div>
          <div className="calendar-cart__descript-second-line">
            Odkryj tajniki kowalstwa podczas niezapomnianych warsztatów
            prowadzonych przez doświadczonych rzemieślników.
          </div>
        </div>
      </div>
      <div
        className="calendar-cart"
        onClick={() =>
          history.push(`${ROUTES.SINGLE_EVENT_PAGE}?id=178hausjdau7dg8uhjbmnzx`)
        }
      >
        <div className="calendar-cart__date">
          <SVG type={SVG_TYPE.EMPTY_CALENDAR} />
          <div className="calendar-cart__date-number">26</div>
        </div>
        <div className="calendar-cart__descript">
          <div className="calendar-cart__descript-title">
            Kurs Makramy: Tworzenie Pięknych Węzłowych Dekoracji
          </div>
          <div className="calendar-cart__descript-second-line">
            Odkryj kreatywność w sztuce makramy i stwórz unikalne ozdoby z
            własnymi rękoma.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CraftsEvents;
