import React from 'react';
import { Field } from 'formik';
import { array, func, object } from 'prop-types';
import ProductDelivery from '../product-delivery/ProductDelivery';

const ProductDeliveryField = ({
  errors,
  setFieldTouched,
  deliveryArr,
  setDeliveryArr,
  setFieldValue,
  deliveries,
  initialValues,
}) => {
  const onTouched = () => {
    setFieldTouched('photos');
  };

  return (
    <Field
      component={ProductDelivery}
      errors={errors}
      name="deliveries"
      customBorder="customize-field"
      deliveryArr={deliveryArr}
      setDeliveryArr={setDeliveryArr}
      setFieldValue={setFieldValue}
      initialValues={initialValues}
      deliveries={deliveries}
      onBlur={() => onTouched()}
    />
  );
};
export default ProductDeliveryField;

ProductDeliveryField.propTypes = {
  deliveries: array.isRequired,
  deliveryArr: array.isRequired,
  errors: array.isRequired,
  initialValues: object.isRequired,
  setDeliveryArr: func.isRequired,
  setFieldTouched: func.isRequired,
  setFieldValue: func.isRequired,
};
