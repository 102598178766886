import * as Connector from "shared/connectors/userConnector";
import { AUTH } from "store/types";

const init = () => ({
  type: AUTH.SET_ACCOUNT_TYPE_INIT,
});

export const success = (data) => ({
  type: AUTH.SET_ACCOUNT_TYPE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: AUTH.SET_ACCOUNT_TYPE_FAILURE,
});

// eslint-disable-next-line consistent-return
const setAccountType = (form) => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await Connector.setAccountType(form);
    dispatch(success(data));
    return data.user;
  } catch (err) {
    console.log(err);
    dispatch(failure());
  }
};

export default setAccountType;
