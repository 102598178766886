import React from 'react';
import { Field, Formik } from 'formik';
import { SVG_TYPE, VALIDATION_MESSAGES } from 'shared/consts';
import { RegularField, SVG } from 'shared/components';
import { object, string } from 'yup';
import PropTypes from 'prop-types';

const EditSubcategoryForm = ({ subcategory, setManageSubcategoryForm, editSubcategory, id }) => {
  const { REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const validationForm = () =>
    object().shape({
      link: string().required(REQUIRED_FIELD),
      name: string().required(REQUIRED_FIELD),
    });

  const submitForm = async (values, actions) => {
    const response = await editSubcategory(subcategory.uuid, values);
    if (response.link_exist && response.name_exist) {
      actions.setErrors({ name: 'Nazwa zajęta', link: 'Adres url zajęty' });
    } else if (response?.name_exist) {
      actions.setErrors({ name: 'Nazwa zajęta' });
    } else if (response?.link_exist) {
      actions.setErrors({ link: 'Url zajety' });
    } else if (response?.status === 'success') {
      setManageSubcategoryForm({ id, isOpen: true });
    }
  };

  return (
    <Formik
      initialValues={{ name: subcategory.name, link: subcategory.link }}
      onSubmit={submitForm}
      validationSchema={validationForm()}
    >
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="new-category-form new-category-form--subcategory">
          <Field
            component={RegularField}
            errors={errors}
            name="name"
            placeholder="Nazwa podkategorii"
            type="text"
            fullWidth
            icon={SVG_TYPE.SUBCATEGORY}
          />
          <Field
            component={RegularField}
            errors={errors}
            name="link"
            placeholder="link"
            type="text"
            fullWidth
            icon={SVG_TYPE.URL}
          />
          <div
            className="subcategory__item--single-action edit-category-form--margin"
            onClick={() => setManageSubcategoryForm({ id, isOpen: true })}
          >
            <SVG type={SVG_TYPE.PLUS} className="subcategory__item--single-action--plus-rotate" />
          </div>
          <div className="subcategory__item--single-action" onClick={handleSubmit}>
            <SVG type={SVG_TYPE.EDIT} />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default EditSubcategoryForm;

EditSubcategoryForm.propTypes = {
  categoryUuid: PropTypes.string.isRequired,
  editSubcategory: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  setManageSubcategoryForm: PropTypes.object.isRequired,
  subcategory: PropTypes.object.isRequired,
};
