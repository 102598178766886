import { useEffect } from "react";
import { DashboardTopBar } from "screens/dashboard/components";
import { bool, func, object, shape, string } from "prop-types";
import { ROUTES } from "shared/consts";

const DashboardContent = ({
  children,
  authorizedUser,
  actions: { logout, getAllCategoriesAndSubcategories, openModal },
  name,
  goBack,
  categoriesAndSubcategories,
  isModalOpened,
}) => {
  useEffect(() => {
    categoriesAndSubcategories.length <= 0 &&
      getAllCategoriesAndSubcategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fullContent = window.location.pathname === ROUTES.CRAFTS;

  return (
    <div className="content--right">
      <div className="content-inner">
        <DashboardTopBar
          isModalOpened={isModalOpened}
          openModal={openModal}
          goBack={goBack}
          logout={logout}
          name={name}
          authorizedUser={authorizedUser}
          categoriesAndSubcategories={categoriesAndSubcategories}
        />
        <div className={`content ${fullContent ? "content--full" : ""}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default DashboardContent;

DashboardContent.defaultProps = {
  goBack: false,
};

DashboardContent.propTypes = {
  actions: shape({
    logout: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  name: string.isRequired,
  goBack: bool,
};
