import { func, number, object } from 'prop-types';
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const DeliveryItem = ({ delivery, setDeliveryManage, id, openModal }) => (
  <li className="delivery--item">
    <div className="delivery--item__icon--wrapper">
      <SVG className="delivery--item__icon" type={SVG_TYPE.DELIVERY} />
    </div>
    <div className="delivery--item__label">{delivery.name}</div>
    <div className="delivery--item__price">{delivery.price} zł</div>
    <div className="delivery--item--actions">
      <div className="delivery--item--action" onClick={() => openModal(delivery)}>
        <SVG type={SVG_TYPE.DELIVERY_DELETE} />
      </div>
      <div className="delivery--item--action" onClick={() => setDeliveryManage({ id, delivery, isEdit: true })}>
        <SVG type={SVG_TYPE.EDIT} />
      </div>
    </div>
  </li>
);

export default DeliveryItem;

DeliveryItem.propTypes = {
  delivery: object.isRequired,
  id: number.isRequired,
  openModal: object.isRequired,
  setDeliveryManage: func.isRequired,
};
