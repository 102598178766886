import { func, number, object } from 'prop-types';
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const CategoryItem = ({ openModal, category, setManageSubcategoryForm, manageSubcategoryForm, id }) => {
  const isActiveCategory = manageSubcategoryForm.isOpen && manageSubcategoryForm.id === id;
  return (
    <div className="category__item">
      <div className="left">
        <SVG type={SVG_TYPE.CATEGORY} />
        {category?.name}
      </div>
      <div className="subcategory__item--actions">
        <div className="subcategory__item--single-action subcategory__item--single-action--no-click">
          {category?.subcategories?.length > 0 && category?.subcategories?.length}
        </div>
        <div className="subcategory__item--single-action" onClick={() => setManageSubcategoryForm({ isEditOpen: true, id })}>
          <SVG type={SVG_TYPE.EDIT} />
        </div>
        <div className="subcategory__item--single-action" onClick={() => openModal(category, 'category')}>
          <SVG type={SVG_TYPE.MINUS} />
        </div>
        <div
          className="subcategory__item--single-action"
          onClick={() => setManageSubcategoryForm({ isOpen: !isActiveCategory, id })}
        >
          <SVG
            type={SVG_TYPE.PLUS}
            className={isActiveCategory ? 'subcategory__item--single-action--plus-rotate' : 'subcategory__item--single-action'}
          />
        </div>
        {/* {category.subcategories.length > 0 && 
          <div
            className="subcategory__item--single-action subcategory__item--single-action--absolute"
            onClick={() => setManageSubcategoryForm({ isOpen: !isActiveCategory, id })}
          >
            <SVG
              type={SVG_TYPE.CHEVRONE}
              className={isActiveCategory ? 'subcategory__item--single-action--chevrone-rotate' : 'subcategory__item--single-action'}
            />
          </div>
        } */}
      </div>
    </div>
  );
};

export default CategoryItem;

CategoryItem.propTypes = {
  category: object.isRequired,
  id: number.isRequired,
  manageSubcategoryForm: object.isRequired,
  openModal: func.isRequired,
  setManageSubcategoryForm: func.isRequired,
};
