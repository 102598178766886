const VIEW_MANAGEMENT = {
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  DARK_MODE_ON: "DARK_MODE_ON",
  DARK_MODE_OFF: "DARK_MODE_OFF",
  BLURRED_ON: "BLURRED_ON",
  BLURRED_OFF: "BLURRED_OFF",
  OPEN_MENU: "OPEN_MENU",
  CLOSE_MENU: "CLOSE_MENU",
};

export default VIEW_MANAGEMENT;
