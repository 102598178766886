import React from 'react';
import { bool, func } from 'prop-types';
import { Modal, ModalTitle, Button, SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const ManageAdvertisementModal = ({ manageModal, setManageModal }) => (
  <Modal opened={manageModal.isOpen}>
    <ModalTitle title={`Czy na pewno chcesz ${manageModal.text} ogłoszenie?`} />
    <div className="category-modal-remove">
      <p className="paragraph">
        <SVG type={SVG_TYPE.PRODUCT} />
        {manageModal?.product}
      </p>
    </div>
    <div className="modal-remove-buttons--wrapper">
      <div className="modal-remove-buttons--content">
        <Button label={manageModal.buttonText} className="btn--default" />
        <Button label="anuluj" className="btn--frame-dark" onClick={() => setManageModal({ isOpen: false })} />
      </div>
    </div>
  </Modal>
);

export default ManageAdvertisementModal;

ManageAdvertisementModal.propTypes = {
  manageModal: bool.isRequired,
  setManageModal: func.isRequired,
};
