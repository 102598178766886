import { PRODUCT } from 'store/types';

const INITIAL_STATE = {
  myProducts: [],
};

const productReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case PRODUCT.GET_MY_PRODUCTS_INIT: {
      return {
        ...state,
      };
    }

    case PRODUCT.GET_MY_PRODUCTS_SUCCESS: {
      return {
        ...state,
        myProducts: payload.data.ownProduct,
      };
    }

    case PRODUCT.GET_MY_PRODUCTS_FAILURE: {
      return {
        ...state,
      };
    }

    default:
      return state;
  }
};

export default productReducer;
