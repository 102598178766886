/* eslint-disable react-hooks/exhaustive-deps */
// import { array, bool, func, shape } from "prop-types";
import React from "react";
import { DashboardContent } from "screens/dashboard/components";
import { Legends, EventsOfTheDay, CalendarSection } from "./components";

const EventsPage = ({ actions: { getAuthorizedUser }, authorizedUser }) => {
  return (
    <>
      <DashboardContent name="Wydarzenia ">
        <div className="events-page--top">
          <CalendarSection authorizedUser={authorizedUser} />
          <Legends />
          <EventsOfTheDay />
        </div>
      </DashboardContent>
    </>
  );
};
export default EventsPage;

// CategoryPage.propTypes = {
//   actions: shape({
//     createCategory: func.isRequired,
//     createSubcategory: func.isRequired,
//     getAllCategoriesAndSubcategories: func.isRequired,
//     removeCategory: func.isRequired,
//     removeSubcategory: func.isRequired,
//     editSubcategory: func.isRequired,
//     editCategory: func.isRequired,
//   }).isRequired,
//   categoriesAndSubcategories: array.isRequired,
//   editCategory: func.isRequired,
//   isMobile: bool.isRequired,
//   isModalOpened: bool.isRequired,
// };
