import React from 'react';
import { func, string } from 'prop-types';
import { SVG, Button } from 'shared/components';

const PageHeader = ({ label, type, onClick, className, btnLabel }) => (
  <div className="page__header">
    <SVG className={`page__header--svg page--header--svg--${type} `} type={type} />
    <h1 className={`page__header--${className}`}> {label} </h1>
    {btnLabel && <Button className="page__header--btn btn--default" label={btnLabel} onClick={onClick} />}
  </div>
);

export default PageHeader;

PageHeader.defaultProps = {
  btnLabel: '',
  className: '',
  onClick: () => null,
};

PageHeader.propTypes = {
  btnLabel: string,
  className: string,
  label: string.isRequired,
  onClick: func,
  type: string.isRequired,
};
