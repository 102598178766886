import React, { useState, useEffect } from 'react';
import { ProductsElements } from 'shared/consts';
import { Product } from 'shared/components';

const Products = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 700);
  }, []);

  return (
    <div className="products">
      <div className="products__content" onClick={() => setIsLoading(true)}>
        {ProductsElements.map(data => (
          <Product isLoading={isLoading} data={data} />
        ))}
      </div>
    </div>
  );
};

export default Products;
