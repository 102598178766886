const NOTIFY_TYPE = {
  CHECKED: 'CHECKED',
  CATEGORY: 'CATEGORY',
  SUBCATEGORY: 'SUBCATEGORY',
  EDIT: 'EDIT',
  BIN: 'BIN',
  WARNING: 'WARNING',
  SEND_MAIL: 'SEND_MAIL',
  INACTIVE_USER: 'INACTIVE_USER',
  USER: 'USER',
  DELIVERY: 'DELIVERY',
  DELIVERY_DELETE: 'DELIVERY_DELETE',
  ADDRESS: 'ADDRESS',
  PRODUCT: 'PRODUCT',
};

export default NOTIFY_TYPE;
