import React from 'react';
import { UserIcon } from 'shared/components';
import { ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';

const ProfileTopBar = () => {
  const history = useHistory();
  return (
    <div className="profile-top-bar">
      <UserIcon />
      <div className="profile-top-bar--text">
        <div className="profile-top-bar--name">Mateusz</div>
        <div onClick={() => history.push(ROUTES.USER_PAGE)} className="profile-top-bar--profile">
          profil
        </div>
      </div>
    </div>
  );
};

export default ProfileTopBar;
