/* eslint-disable react/prop-types */
import React from 'react';
import { SettingsDots } from 'shared/components';
import { bool, func } from 'prop-types';
import { USER_ROLE } from 'shared/consts';
import moment from 'moment';
import { Avatar } from '../../components';

const getClientColumns = (isOpen, setIsOpen) => [
  {
    width: 175,
    Cell: ({ original }) => <Avatar type={USER_ROLE.CLIENT} data={original} />,
  },
  {
    id: 'updated_at',
    accessor: 'updated_at',
    width: 100,
    Cell: ({ original: { updated_at } }) => moment(updated_at).format('YYYY-MM-DD'),
  },
  {
    id: 'last_login',
    accessor: 'last_login',
    width: 100,
  },
  {
    id: 'buy_elements',
    accessor: 'buy_elements',
    width: 100,
  },
  {
    width: 160,
    Cell: ({ original }) => <SettingsDots data={original} isOpen={isOpen} setIsOpen={setIsOpen} />,
    className: 'settings-dots',
  },
];

export default getClientColumns;

getClientColumns.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
};
