import * as React from 'react';
import RTable from 'react-table-v6';
import { arrayOf, shape, string, bool, func, number } from 'prop-types';
import { Pagination } from './components';

const Table = ({
  className = '',
  columns,
  data,
  expanded = {},
  SubComponent,
  filterable,
  activeRowId,
  onActiveUpdate,
  isMobile,
  user,
  ...rest
}) => {
  const userPageSize = () => {
    if (data.length > 10) {
      return 4;
    }
    return data.length;
  };

  return (
    <RTable
      className={className}
      columns={columns}
      data={data}
      defaultFilterMethod={({ id, value }, row) => row[id].toLowerCase().indexOf(value.toLowerCase()) > -1}
      expanded={expanded}
      defaultPageSize={userPageSize()}
      filterable={filterable}
      loadingText=""
      sortable
      SubComponent={SubComponent}
      PaginationComponent={Pagination}
      TheadComponent={() => null}
      getTrProps={(state, rowInfo) => {
        if (rowInfo && rowInfo.original.is_suspended) {
          return {
            className: 'row-user-suspended',
            // style: {
            //   color: '#929292',
            // }
          };
        }
        return {};
      }}
      {...rest}
    />
  );
};

Table.propTypes = {
  activeRowId: number,
  className: string,
  columns: arrayOf(shape({})).isRequired,
  data: arrayOf(shape({})).isRequired,
  expanded: shape({}),
  filterable: bool,
  isMobile: bool,
  onActiveUpdate: func,
  SubComponent: shape({}),
  user: bool,
};
Table.defaultProps = {
  activeRowId: null,
  className: '',
  expanded: {},
  filterable: false,
  isMobile: false,
  onActiveUpdate: undefined,
  SubComponent: undefined,
  user: false,
};
export default Table;
