import { func } from 'prop-types';
import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import { object } from 'yup';

const AddCategory = ({ setCategoryForm, categoryForm }) => (
  <div
    className={`category__item category__item--add ${categoryForm ? 'category__item--active' : ''}`}
    onClick={() => setCategoryForm(!categoryForm)}
  >
    {categoryForm.isOpened ? 'Anuluj' : 'Dodaj nową kategorię'}{' '}
    <div className="subcategory__item--single-action">
      <SVG type={SVG_TYPE.PLUS} className={categoryForm ? 'category__item--cancel' : ''} />
    </div>
  </div>
);

export default AddCategory;

AddCategory.propTypes = {
  categoryForm: object.isRequired,
  setCategoryForm: func.isRequired,
};
