import API from 'shared/connectors/config';

export function addDelivery(data) {
  return API.post('/delivery', data);
}

export function getDeliveries() {
  return API.get('/delivery');
}

export function removeDelivery(uuid) {
  return API.delete(`/delivery/${uuid}`);
}

export function editDelivery(uuid, data) {
  return API.put(`/delivery/${uuid}`, data);
}
