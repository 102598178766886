const EventsOfTheDay = () => {
  return (
    <div className="events-page-section__container events__container">
      <div className="events-page-section__header">Dzisiejsze wydarzenia</div>
      <div className="events-page-section events__day">
        Dziś dzień jest wolny od wydarzeń. Jeśli masz jakieś wydarzenie do
        dodania, kliknij poniższy przycisk 'Dodaj wydarzenie'.
        {/* <div className="calendar-carts">
          <div className="calendar-carts__descript">
            <div className="calendar-carts__descript-title">
              Warsztaty Kowalskie: Sztuka Kowalstwa w Twoich Rękach
            </div>
            <div className="calendar-carts__descript-second-line">
              Odkryj tajniki kowalstwa podczas niezapomnianych warsztatów prowadzonych przez doświadczonych rzemieślników.
            </div>
          </div>
          <div className="calendar-carts__descript">
            <div className="calendar-carts__descript-title">
              Kurs Makramy: Tworzenie Pięknych Węzłowych Dekoracji
            </div>
            <div className="calendar-carts__descript-second-line">
              Odkryj kreatywność w sztuce makramy i stwórz unikalne ozdoby z własnymi rękoma.
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default EventsOfTheDay;
