import { connect } from 'react-redux';
import * as ViewManagementActions from 'store/actions/viewManagementActions';
import { getIsModalOpened } from 'store/selectors/viewManagementSelector';
import { isMobile as checkIsMobile } from 'shared/utils';
import { bindActionCreators } from 'redux';
import ActivateUserPage from './ActivateUserPage.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
    },
    dispatch,
  ),
});

function mapStateToProps(state) {
  return {
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateUserPage);
