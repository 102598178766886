import React from 'react';
import { bool } from 'prop-types';
import { IconsRightSide } from './components';

const TopBar = ({ isMobile }) => {
  console.log(isMobile);
  return (
    <div className="top-bar__wrapper">
      <div className="top-bar">
        <IconsRightSide />
      </div>
    </div>
  );
};

export default TopBar;

TopBar.propTypes = {
  isMobile: bool.isRequired,
};
