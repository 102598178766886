import React, { useState } from 'react';
import { Field, Formik } from 'formik';
import { RegularField, Button, SVG } from 'shared/components';
import { SVG_TYPE, VALIDATION_MESSAGES } from 'shared/consts';
import { object, string } from 'yup';
import { func } from 'prop-types';

const Left = ({ sendMessageContactForm }) => {
  const { EMAIL, REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const [isLoading, setIsLoading] = useState(false);

  const validationForm = () =>
    object().shape({
      content: string().required(REQUIRED_FIELD).min(5, 'minimum 5 znaków'),
      email: string().email(EMAIL).required(REQUIRED_FIELD),
      first_name: string().required(REQUIRED_FIELD),
      phone: string()
        .min(9, 'wymagane 9 cyfr')
        .max(9, 'wymagane 9 cyfr')
        .matches(/^\d{9}$/, 'wymagane 9 cyfr'),
    });

  const submitForm = async (values, actions) => {
    setIsLoading(true);
    const response = await sendMessageContactForm(values);
    if (response.status === 'success') {
      actions.resetForm();
    }
    setIsLoading(false);
  };

  return (
    <div className="contact--box contact--left">
      <div className="top">
        <div className="header">Napisz do nas!</div>
        <p className="paragraph">Wyślij do nas wiadomość z tego formularza a my skontaktujemy się z tobą jak najszybciej.</p>
      </div>
      <Formik
        initialValues={{ first_name: '', email: '', phone: '', content: '' }}
        onSubmit={submitForm}
        validationSchema={validationForm()}
      >
        {({ handleSubmit, errors }) => (
          <>
            <form className="form" onSubmit={handleSubmit}>
              <Field
                component={RegularField}
                errors={errors}
                label="imię"
                name="first_name"
                placeholder="Imię"
                type="text"
                fullWidth
                customBorder="custom-field"
              />
              <Field
                component={RegularField}
                errors={errors}
                label="email"
                name="email"
                placeholder="Adres email"
                type="text"
                fullWidth
                customBorder="custom-field"
              />
              <Field
                component={RegularField}
                errors={errors}
                label="Telefon"
                name="phone"
                placeholder="Telefon"
                type="text"
                fullWidth
                customBorder="custom-field"
              />
              <Field
                component={RegularField}
                errors={errors}
                label="Treść wiadomości"
                name="content"
                placeholder="Treść wiadomości"
                type="textarea"
                fullWidth
                customBorder="custom-textarea-field custom-field"
              />
              <div className="isLoading">
                <Button className="btn--default" label="Wyślij" type="default" position="center-full" />
                {isLoading && <SVG type={SVG_TYPE.SPINNER_LOADING} />}
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default Left;

Left.propTypes = {
  sendMessageContactForm: func.isRequired,
};
