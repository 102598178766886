/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { DashboardContent } from "screens/dashboard/components";
import {
  ForumHeader,
  ForumSidebarLeft,
  ForumCenter,
  ForumSidebarRight,
} from "./components";
import { useLocation } from "react-router-dom";
import { ROUTES } from "shared/consts";

const ForumPage = ({
  actions: {
    getAuthorizedUser,
    getAllCategories,
    addForumQuestion,
    getForumQuestions,
  },
  authorizedUser,
  categories,
  forumQuestions,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const isAddQuestion = pathname === ROUTES.FORUM_ADD_QUESTION;
  const isNotQuestionPage = !pathname.includes("/forum/pytanie/");

  useEffect(() => {
    !authorizedUser && getAuthorizedUser();
  }, []);

  useEffect(() => {
    getForumQuestions();
  }, []);

  return (
    <>
      <DashboardContent goBack={isAddQuestion} name="Forum ">
        {isNotQuestionPage && <ForumHeader />}
        <div className="forum-page__content">
          {isNotQuestionPage && (
            <ForumSidebarLeft authorizedUser={authorizedUser} />
          )}
          <ForumCenter
            forumQuestions={forumQuestions}
            addForumQuestion={addForumQuestion}
            getAllCategories={getAllCategories}
            categories={categories}
          />
          <ForumSidebarRight />
        </div>
      </DashboardContent>
    </>
  );
};
export default ForumPage;
