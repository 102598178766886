import React from "react";
import { NavLink } from "react-router-dom";
import { ROUTES, SVG_TYPE } from "shared/consts";
import { SVG, Button } from "shared/components";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__column">
          <NavLink className="footer__column--item" to={ROUTES.HOME_PAGE}>
            Strona główna
          </NavLink>
          <NavLink className="footer__column--item" to={ROUTES.HOME_PAGE}>
            Rzemiosło
          </NavLink>
          <NavLink className="footer__column--item" to={ROUTES.HOME_PAGE}>
            Sklep
          </NavLink>
          <NavLink className="footer__column--item" to={ROUTES.HOME_PAGE}>
            Forum
          </NavLink>
          <NavLink className="footer__column--item" to={ROUTES.HOME_PAGE}>
            Kontakt
          </NavLink>
        </div>
        <div className="footer__column">
          <NavLink className="footer__column--item" to={ROUTES.HOME_PAGE}>
            Polityka prywatności
          </NavLink>
          <NavLink className="footer__column--item" to={ROUTES.HOME_PAGE}>
            Regulamin
          </NavLink>
          <div className="socials">
            <div className="socials__item">
              <SVG type={SVG_TYPE.YOUTUBE} />
            </div>
            <div className="socials__item">
              <SVG type={SVG_TYPE.INSTAGRAM} />
            </div>
          </div>
        </div>
        <div className="footer__column">
          <Button label="napisz do nas" className="btn--normal-stroke" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
