import React, { useState } from 'react';
import { SVG_TYPE } from 'shared/consts';
import { CircleIcon, Menu } from 'shared/components';
import { MENU_TOP_BAR } from 'shared/components/menu/consts';

const TopBarMore = () => {
  const [isMenuOpen, setisMenuOpen] = useState(false);
  const openMenu = () => {
    setisMenuOpen(true);
  };
  const closeMenu = () => {
    setisMenuOpen(false);
  };
  return (
    <div className="top-bar--more">
      <CircleIcon isOpen={isMenuOpen} onClick={openMenu} type={SVG_TYPE.DROP_DOWN} />
      <Menu position="left" isOpen={isMenuOpen} onClose={closeMenu} menuLists={MENU_TOP_BAR()} />
    </div>
  );
};

export default TopBarMore;
