const Legends = () => {
  return (
    <div className="legend__container events-page-section__container">
      <div className="events-page-section__header">Legenda</div>
      <div className="legend__items">
        <div className="legend__item">
          <div className="events-page-section">
            <div className="legend-symbol legend-symbol--today" />
            <p className="legend-text">Dzisiaj</p>
          </div>
        </div>
        <div className="legend__item">
          <div className="events-page-section">
            <div className="legend-symbol legend-symbol--workshop legend-symbol--small" />
            <p className="legend-text">Warsztaty rzemieślnicze</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legends;
