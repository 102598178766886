import * as React from "react";
import { Route, Redirect } from "react-router-dom";
import { PROTECTED_ROUTES, ROUTES } from "shared/consts";
import PropTypes from "prop-types";

const RoleRoute = ({
  component: Component,
  exact,
  authorizedUserRole,
  location: { pathname },
  ...rest
}) => {
  const protectedRouteRoles = PROTECTED_ROUTES.get(pathname);
  const isAccessGranted = protectedRouteRoles.includes(
    authorizedUserRole || localStorage.getItem("userRole")
  );

  return (
    <Route
      {...rest}
      exact={exact}
      render={(routeProps) =>
        isAccessGranted ? (
          <Component {...routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: ROUTES.LOGIN,
            }}
          />
        )
      }
    />
  );
};

RoleRoute.defaultProps = {
  exact: false,
  location: {},
};

RoleRoute.propTypes = {
  authorizedUserRole: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  exact: PropTypes.bool,
  location: PropTypes.object,
  path: PropTypes.string.isRequired,
};

export default RoleRoute;
