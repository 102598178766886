import { getDeliveries as getDeliveriesConnector } from 'shared/connectors/deliveryConnector';
import { DELIVERY } from 'store/types';

const init = () => ({
  type: DELIVERY.GET_DELIVERIES_INIT,
});

export const success = data => ({
  type: DELIVERY.GET_DELIVERIES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: DELIVERY.GET_DELIVERIES_FAILURE,
});

const getDeliveries = form => async dispatch => {
  dispatch(init());
  try {
    const response = await getDeliveriesConnector(form);
    dispatch(success(response.data.category));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return err.response.data;
  }
};

export default getDeliveries;
