/* eslint-disable react/prop-types */
import React from 'react';
import Advertisement from './advertisement';

const getAdvertisementColumns = setManageModal => [
  {
    width: '100%',
    Cell: ({ original }) => <Advertisement setManageModal={setManageModal} data={original} />,
  },
];

export default getAdvertisementColumns;
