import { connect } from 'react-redux';
import * as ViewManagementActions from 'store/actions/viewManagementActions';
import { getIsModalOpened } from 'store/selectors/viewManagementSelector';
import { isMobile as checkIsMobile } from 'shared/utils';
import { bindActionCreators } from 'redux';
import { addDelivery, editDelivery, getDeliveries, removeDelivery } from 'store/actions/delivery';
import { getDeliveries as getDeliveriesSelector } from 'store/selectors/deliverySelector';
import DeliveryPage from './DeliveryPage.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      editDelivery: (uuid, form) => editDelivery(uuid, form),
      removeDelivery: uuid => removeDelivery(uuid),
      getDeliveries: () => getDeliveries(),
      addDelivery: data => addDelivery(data),
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
    },
    dispatch,
  ),
});

function mapStateToProps(state) {
  return {
    deliveries: getDeliveriesSelector(state),
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryPage);
