import { userRegister } from 'shared/connectors/userConnector';
import { AUTH } from 'store/types';

const init = () => ({
  type: AUTH.REGISTER_INIT,
});

export const success = data => ({
  type: AUTH.REGISTER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: AUTH.REGISTER_FAILURE,
});

const registerUser = dataRegister => async dispatch => {
  dispatch(init());
  try {
    const { data } = await userRegister(dataRegister);
    dispatch(success(data));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response.data;
  }
};

export default registerUser;
