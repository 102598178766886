import { EVENT_STATUS } from "shared/consts";

export const MENU_TYPES = {
  ALL: "all",
  ACTIVE: "active",
  WAITING: "waiting",
  WARNING: "warning",
  ARCHIVED: "archived",
};

export const SUBPAGES = [
  {
    color: "transparent",
    name: EVENT_STATUS.ALL,
    type: MENU_TYPES.ALL,
    count: 0,
  },
  {
    color: "#8ac926",
    name: EVENT_STATUS.ACCEPTED,
    type: MENU_TYPES.ACTIVE,
    count: 0,
  },
  {
    color: "#eaa659",
    name: EVENT_STATUS.WAITING,
    type: MENU_TYPES.WAITING,
    count: 0,
  },
  {
    color: "#ea5959",
    name: EVENT_STATUS.ATTENTION,
    type: MENU_TYPES.WARNING,
    count: 0,
  },
  // {
  //   color: '#c4c4cc',
  //   name: EVENT_STATUS.ATTENTION,
  //   type: MENU_TYPES.WARNING,
  //   count: 0,
  // },
];
