import Slider from "react-slick";
import Partner1 from "assets/files/partners/partner1.png";
import Partner2 from "assets/files/partners/partner2.png";
import Partner3 from "assets/files/partners/partner3.png";
import Partner4 from "assets/files/partners/partner4.png";
import Partner5 from "assets/files/partners/partner5.png";

const settings = {
  infinite: true,
  arrows: false,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const partners = [
  { url: Partner1 },
  { url: Partner2 },
  { url: Partner3 },
  { url: Partner4 },
  { url: Partner5 },
];

const ForumSidebarAdvertisement = () => {
  return (
    <div className="forum-sidebar-advertisement">
      <div class="forum-page__header">Partnerzy</div>
      <div className="forum-sidebar-advertisement__content">
        <div className="forum-sidebar-advertisement__partner-items">
          <Slider {...settings}>
            {partners.map((item) => {
              return (
                <div className="forum-sidebar-advertisement__partner-item">
                  <img src={item.url} alt="" />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default ForumSidebarAdvertisement;
