import React from 'react';
import { object, string } from 'prop-types';
import avatar from 'assets/files/face.jpg';

const Avatar = ({ data, type }) => {
  const { user_name } = data;
  return (
    <div className="avatar">
      <div className={`avatar__image-container ${`avatar--dots--${type}`}`}>
        <img className="avatar__image" src={avatar} alt="" />
      </div>
      <p className="avatar--paragraph">{user_name}</p>
    </div>
  );
};

Avatar.propTypes = {
  data: object.isRequired,
  type: string.isRequired,
};

export default Avatar;
