import React from 'react';
import { SVG_TYPE } from 'shared/consts';
import { SVG } from 'shared/components';
import { number } from 'prop-types';

const StarRating = ({ rate, opinion }) => {
  const showStars = () => {
    if (rate > 0 && rate < 1) {
      return (
        <>
          <SVG type={SVG_TYPE.STAR_HALF} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
        </>
      );
    }
    if (rate >= 1 && rate <= 1.4) {
      return (
        <>
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
        </>
      );
    }
    if (rate >= 1.5 && rate < 1.9) {
      return (
        <>
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_HALF} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
        </>
      );
    }
    if (rate >= 2 && rate <= 2.4) {
      return (
        <>
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
        </>
      );
    }
    if (rate >= 2.5 && rate < 2.9) {
      return (
        <>
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_HALF} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
        </>
      );
    }
    if (rate >= 3 && rate <= 3.4) {
      return (
        <>
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR} />
          <SVG type={SVG_TYPE.STAR} />
        </>
      );
    }
    if (rate >= 3.5 && rate < 3.9) {
      return (
        <>
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_HALF} />
          <SVG type={SVG_TYPE.STAR} />
        </>
      );
    }
    if (rate >= 4 && rate < 4.4) {
      return (
        <>
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR} />
        </>
      );
    }
    if (rate >= 4 && rate < 4.5) {
      return (
        <>
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR} />
        </>
      );
    }
    if (rate >= 4.5 && rate < 4.9) {
      return (
        <>
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_FULL} />
          <SVG type={SVG_TYPE.STAR_HALF} />
        </>
      );
    }
    return null;
  };
  return (
    <div className="star-rating">
      {rate ? (
        <div className="star-rating--content">
          {showStars()}
          <div className="star-rating--rate">
            ( {rate} ) z {opinion} opinii
          </div>
        </div>
      ) : (
        <div className="star-rating--null">ten produkt nie został jeszcze oceniony</div>
      )}
    </div>
  );
};

export default StarRating;

StarRating.propTypes = {
  opinion: number.isRequired,
  rate: number.isRequired,
};
