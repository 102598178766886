const FORUM = {
  GET_FORUM_QUESTIONS_INIT: "GET_FORUM_QUESTIONS_INIT",
  GET_FORUM_QUESTIONS_SUCCESS: "GET_FORUM_QUESTIONS_SUCCESS",
  GET_FORUM_QUESTIONS_FAILURE: "GET_FORUM_QUESTIONS_FAILURE",

  ADD_FORUM_QUESTION_INIT: "ADD_FORUM_QUESTION_INIT",
  ADD_FORUM_QUESTION_SUCCESS: "ADD_FORUM_QUESTION_SUCCESS",
  ADD_FORUM_QUESTION_FAILURE: "ADD_FORUM_QUESTION_FAILURE",
};

export default FORUM;
