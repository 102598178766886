const AUTH = {
  REGISTER_INIT: "REGISTER_INIT",
  REGISTER_SUCCESS: "REGISTER_SUCCESS",
  REGISTER_FAILURE: "REGISTER_FAILURE",
  REGISTER_USER_BY_ADMIN_INIT: "REGISTER_USER_BY_ADMIN_INIT",
  REGISTER_USER_BY_ADMIN_SUCCESS: "REGISTER_USER_BY_ADMIN_SUCCESS",
  REGISTER_USER_BY_ADMIN_FAILURE: "REGISTER_USER_BY_ADMIN_FAILURE",
  LOGIN_INIT: "LOGIN_INIT",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",
  LOGOUT_INIT: "LOGOUT_INIT",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  SET_REFRESH_TOKEN: "SET_REFRESH_TOKEN",
  GET_AUTHORIZED_USER_INIT: "GET_AUTHORIZED_USER_INIT",
  GET_AUTHORIZED_USER_SUCCESS: "GET_AUTHORIZED_USER_SUCCESS",
  GET_AUTHORIZED_USER_FAILURE: "GET_AUTHORIZED_USER_FAILURE",
  CODE_VERIFICATION_INIT: "CODE_VERIFICATION_INIT",
  CODE_VERIFICATION_SUCCESS: "CODE_VERIFICATION_SUCCESS",
  CODE_VERIFICATION_FAILURE: "CODE_VERIFICATION_FAILURE",
  EDIT_USER_SUCCESS: "EDIT_USER_SUCCESS",
  EDIT_USER_FAILURE: "EDIT_USER_FAILURE",
  GET_CHECKOUT_ADDRESS_INIT: "GET_CHECKOUT_ADDRESS_INIT",
  GET_CHECKOUT_ADDRESS_SUCCESS: "GET_CHECKOUT_ADDRESS_SUCCESS",
  GET_CHECKOUT_ADDRESS_FAILURE: "GET_CHECKOUT_ADDRESS_FAILURE",
  GET_USERS_INIT: "GET_USERS_INIT",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  SET_ACCOUNT_TYPE_INIT: "SET_ACCOUNT_TYPE_INIT",
  SET_ACCOUNT_TYPE_SUCCESS: "SET_ACCOUNT_TYPE_SUCCESS",
  SET_ACCOUNT_TYPE_FAILURE: "SET_ACCOUNT_TYPE_FAILURE",
};

export default AUTH;
