import React from 'react';
import { string } from 'prop-types';
import { SVG } from 'shared/components';
import { SVG_TYPE, NOTIFY_TYPE } from 'shared/consts';

const Notify = ({ label, type, color }) => (
  <div className="notify">
    <div className={`notify__icon--wrapper notify__icon--wrapper--${color}`}>
      {type === NOTIFY_TYPE.CHECKED && <SVG className="notify__icon" type={SVG_TYPE.CHECK} />}
      {type === NOTIFY_TYPE.CATEGORY && <SVG className="notify__icon" type={SVG_TYPE.CATEGORY} />}
      {type === NOTIFY_TYPE.SUBCATEGORY && <SVG className="notify__icon" type={SVG_TYPE.SUBCATEGORY} />}
      {type === NOTIFY_TYPE.EDIT && <SVG className="notify__icon" type={SVG_TYPE.EDIT} />}
      {type === NOTIFY_TYPE.BIN && <SVG className="notify__icon" type={SVG_TYPE.BIN} />}
      {type === NOTIFY_TYPE.WARNING && <SVG className="notify__icon" type={SVG_TYPE.WARNING} />}
      {type === NOTIFY_TYPE.INACTIVE_USER && <SVG className="notify__icon" type={SVG_TYPE.WARNING} />}
      {type === NOTIFY_TYPE.SEND_MAIL && <SVG className="notify__icon" type={SVG_TYPE.SEND_MAIL} />}
      {type === NOTIFY_TYPE.USER && <SVG className="notify__icon" type={SVG_TYPE.USER} />}
      {type === NOTIFY_TYPE.DELIVERY && <SVG className="notify__icon" type={SVG_TYPE.DELIVERY_ADD} />}
      {type === NOTIFY_TYPE.DELIVERY_DELETE && <SVG className="notify__icon" type={SVG_TYPE.DELIVERY_DELETE} />}
      {type === NOTIFY_TYPE.ADDRESS && <SVG className="notify__icon" type={SVG_TYPE.ADDRESS} />}
      {type === NOTIFY_TYPE.PRODUCT && <SVG className="notify__icon" type={SVG_TYPE.PRODUCT} />}
      <div className="triangle" />
    </div>
    <p className="notify__label">{label}</p>
  </div>
);

export default Notify;

Notify.propTypes = {
  color: string.isRequired,
  label: string.isRequired,
  type: string.isRequired,
};
