import React from "react";
import { logout } from "shared/connectors/authConnector";
import { AUTH } from "store/types";
import { toast } from "react-toastify";
import { Notify } from "shared/components";
import { NOTIFY_TYPE } from "shared/consts";

export const success = () => ({
  type: AUTH.LOGOUT_SUCCESS,
});

const logoutUser = () => async (dispatch) => {
  try {
    localStorage.removeItem("isUserLogged");
    localStorage.removeItem("userRole");
    toast(
      <Notify label="Wylogowano" type={NOTIFY_TYPE.CHECKED} color="green" />
    );
    await logout();
    dispatch(success());
    return true;
  } catch (err) {
    console.log(err);
  }
};

export default logoutUser;
