import React from 'react';
import { removeDelivery as removeDeliveryConnector } from 'shared/connectors/deliveryConnector';
import { DELIVERY } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE, NOTIFY_LABEL } from 'shared/consts';

const init = () => ({
  type: DELIVERY.REMOVE_DELIVERY_INIT,
});

export const success = data => ({
  type: DELIVERY.REMOVE_DELIVERY_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: DELIVERY.REMOVE_DELIVERY_FAILURE,
});

const removeDelivery = uuid => async dispatch => {
  dispatch(init());
  try {
    const response = await removeDeliveryConnector(uuid);
    dispatch(success(response.data));
    toast(<Notify label={NOTIFY_LABEL.DELIVERY_DELETE} type={NOTIFY_TYPE.DELIVERY_DELETE} color="green" />);
    return response.data;
  } catch (err) {
    dispatch(failure());
    toast(<Notify label={NOTIFY_LABEL.SOMETHING_WRONG} type={NOTIFY_TYPE.DELIVERY_DELETE} color="red" />);
    return err.response.data;
  }
};

export default removeDelivery;
