const CATEGORY = {
  ADD_CATEGORY_INIT: 'ADD_CATEGORY_INIT',
  ADD_CATEGORY_SUCCESS: 'ADD_CATEGORY_SUCCESS',
  EDIT_CATEGORY_SUCCESS: 'EDIT_CATEGORY_SUCCESS',
  EDIT_SUBCATEGORY_SUCCESS: 'EDIT_SUBCATEGORY_SUCCESS',
  ADD_CATEGORY_FAILURE: 'ADD_CATEGORY_FAILURE',
  ADD_SUBCATEGORY_INIT: 'ADD_SUBCATEGORY_INIT',
  ADD_SUBCATEGORY_SUCCESS: 'ADD_SUBCATEGORY_SUCCESS',
  ADD_SUBCATEGORY_FAILURE: 'ADD_SUBCATEGORY_FAILURE',
  GET_ALL_CATEGORIES_AND_SUBCATEGORIES_INIT: 'GET_ALL_CATEGORIES_AND_SUBCATEGORIES_INIT',
  GET_ALL_CATEGORIES_AND_SUBCATEGORIES_SUCCESS: 'GET_ALL_CATEGORIES_AND_SUBCATEGORIES_SUCCESS',
  GET_ALL_CATEGORIES_AND_SUBCATEGORIES_FAILURE: 'GET_ALL_CATEGORIES_AND_SUBCATEGORIES_FAILURE',
  GET_ALL_CATEGORIES_INIT: 'GET_ALL_CATEGORIES_INIT',
  GET_ALL_CATEGORIES_SUCCESS: 'GET_ALL_CATEGORIES_SUCCESS',
  GET_ALL_CATEGORIES_FAILURE: 'GET_ALL_CATEGORIES_FAILURE',
  GET_SINGLE_CATEGORY_INIT: 'GET_SINGLE_CATEGORY_INIT',
  GET_SINGLE_CATEGORY_SUCCESS: 'GET_SINGLE_CATEGORY_SUCCESS',
  GET_SINGLE_CATEGORY_FAILURE: 'GET_SINGLE_CATEGORY_FAILURE',
  REMOVE_CATEGORY_INIT: 'REMOVE_CATEGORY_INIT',
  REMOVE_CATEGORY_SUCCESS: 'REMOVE_CATEGORY_SUCCESS',
  REMOVE_CATEGORY_FAILURE: 'REMOVE_CATEGORY_FAILURE',
  REMOVE_SUBCATEGORY_INIT: 'REMOVE_SUBCATEGORY_INIT',
  REMOVE_SUBCATEGORY_SUCCESS: 'REMOVE_SUBCATEGORY_SUCCESS',
  REMOVE_SUBCATEGORY_FAILURE: 'REMOVE_SUBCATEGORY_FAILURE',
};

export default CATEGORY;
