/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Field } from "formik";
import { RegularField, SelectField } from "shared/components";
import { array, func, object } from "prop-types";
import { categoriesOptions } from "screens/add-product-page/utils";

const TitleAndCategories = ({
  initialValues,
  errors,
  categories,
  getSingleCategory,
  singleCategory,
  setFieldValue,
  setFieldTouched,
}) => {
  const [subcategoriesOptions, setSubcategoriesOptions] = useState([]);
  const emptySubcategories = subcategoriesOptions.length === 0;

  useEffect(async () => {
    if (singleCategory) {
      const subcategories = singleCategory?.subcategories;
      const subcategoriesOptionsArr = [];
      (await subcategories) &&
        subcategories.forEach((item) => {
          subcategoriesOptionsArr.push({
            searchableLabel: item.name,
            label: item.name,
            value: item.uuid,
          });
          setSubcategoriesOptions(subcategoriesOptionsArr);
        });
    }
    initialValues.category_uuid &&
      setFieldValue("subcategory_uuid", initialValues.subcategory_uuid);
  }, [
    initialValues.category_uuid,
    initialValues.subcategory_uuid,
    setFieldValue,
    singleCategory,
  ]);

  const categoryOnChange = async (e) => {
    if (e) {
      await getSingleCategory(e.value);
    } else {
      setSubcategoriesOptions([]);
      setFieldValue("subcategory_uuid", "");
    }
  };

  useEffect(async () => {
    await getSingleCategory(initialValues.category_uuid);
  }, [getSingleCategory, initialValues.category_uuid]);

  const handleOnBlur = (name) => {
    setFieldTouched(name);
  };

  return (
    <>
      <div className="addProductForm--basic">
        <Field
          component={RegularField}
          errors={errors}
          label="Tytuł ogłoszenia*"
          name="title"
          placeholder="Nazwa produktu który sprzedajesz"
          type="text"
          customBorder="customize-field"
          onBlur={() => handleOnBlur("title")}
        />
        <Field
          className="addProductForm__select"
          component={SelectField}
          placeholder="wybierz"
          label="Kategoria*"
          options={categoriesOptions(categories)}
          errors={errors}
          name="category_uuid"
          onChange={(e) => categoryOnChange(e)}
          onBlur={() => handleOnBlur("category_uuid")}
        />
        <Field
          className={`addProductForm__select ${
            (emptySubcategories && !initialValues.category_uuid) ||
            !initialValues.category_uuid
              ? "addProductForm__select--disabled"
              : ""
          }`}
          component={SelectField}
          placeholder="wybierz"
          label="Podkategoria*"
          options={subcategoriesOptions}
          errors={errors}
          name="subcategory_uuid"
          onBlur={() => handleOnBlur("subcategory_uuid")}
        />
        <Field
          component={RegularField}
          errors={errors}
          label="Cena*"
          name="price"
          placeholder="0,00"
          type="text"
          customBorder="customize-field"
          onBlur={() => handleOnBlur("price")}
        />
        <Field
          component={RegularField}
          errors={errors}
          label="Ilość sztuk*"
          name="amount"
          placeholder="0"
          type="text"
          customBorder="customize-field"
          onBlur={() => handleOnBlur("amount")}
        />
        <Field
          component={RegularField}
          errors={errors}
          label="Lokalizacja*"
          name="location"
          placeholder="Lokalizacja produktu"
          type="text"
          customBorder="customize-field"
          onBlur={() => handleOnBlur("location")}
        />
      </div>
    </>
  );
};

export default TitleAndCategories;

TitleAndCategories.propTypes = {
  categories: array.isRequired,
  errors: object.isRequired,
  getSingleCategory: func.isRequired,
  initialValues: object.isRequired,
  setFieldTouched: func.isRequired,
  setFieldValue: func.isRequired,
  singleCategory: object.isRequired,
};
