import { func } from 'prop-types';
import { SVG, Button } from 'shared/components';
import { SVG_TYPE, ROUTES } from 'shared/consts';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import BasketItems from '../basket-items';

const Basket = ({ closeBasket }) => {
  const history = useHistory();
  useEffect(() => {
    const basket = document.getElementById('basket-modal');
    const right = basket.getElementsByClassName('right');
    const top = basket.getElementsByClassName('top');
    basket.classList.add('basket-modal--active');
    setTimeout(() => {
      right[0].classList.add('right--sticky');
      top[0].classList.add('top--sticky');
    }, 300);
  }, []);

  document.addEventListener('mouseup', e => {
    const container = document.getElementById('basket-modal');
    if (!container.contains(e.target)) {
      const basket = document.getElementById('basket-modal');
      basket.classList.remove('basket-modal--active');
      setTimeout(() => {
        closeBasket();
      }, 100);
    }
  });

  return (
    <div id="basket-modal" className="basket-modal">
      <div className="left">
        <div className="top">
          <div
            className="back"
            onClick={() => {
              history.push(ROUTES.SHOP);
              closeBasket();
            }}
          >
            {' '}
            <SVG type={SVG_TYPE.CHEVRONE} /> wróć do zakupów
          </div>
          <h1 className="basket-modal--h1">Koszyk</h1>
        </div>
        <BasketItems />
      </div>
      <div className="right">
        <div className="right--close" onClick={() => closeBasket()}>
          <SVG type={SVG_TYPE.CLOSE} />
        </div>
        <div className="basket-modal--h1"> Podsumowanie</div>
        <div className="right__summary">
          <div className="right__summary--top">
            <div className="right__summary--item">
              <p>Wartość produktów:</p>
              <span>280 zł</span>
            </div>
            <div className="right__summary--item">
              <p>Dostawa:</p>
              <span>80 zł</span>
            </div>
          </div>
          <div className="right__summary--bottom">
            <div className="right__summary--item">
              <p>Łącznie:</p>
              <span>360 zł</span>
            </div>
            <Button className="btn--default" label="PODSUMOWANIE" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Basket;

Basket.propTypes = {
  closeBasket: func.isRequired,
};
