import { ADDRESS } from 'store/types';
// import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  addresses: [],
};

const addressReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case ADDRESS.GET_ADDRESSES_BY_ID_INIT: {
      return {
        ...state,
        addresses: [],
      };
    }
    case ADDRESS.GET_ADDRESSES_BY_ID_SUCCESS: {
      return {
        ...state,
        addresses: payload.data,
      };
    }
    case ADDRESS.GET_ADDRESSES_BY_ID_FAILURE: {
      return {
        ...state,
        addresses: [],
      };
    }
    case ADDRESS.ADD_ADDRESS_SUCCESS: {
      return {
        ...state,
        addresses: [...state.addresses, payload.data],
      };
    }
    case ADDRESS.REMOVE_ADDRESS_SUCCESS: {
      return {
        ...state,
        addresses: state.addresses.filter(item => item.uuid !== payload.data.uuid),
      };
    }
    case ADDRESS.EDIT_ADDRESS_SUCCESS: {
      const newState = state.addresses;
      const address = newState.find(item => item.uuid === payload.uuid);
      console.log(address);
      address.first_name = payload.form.firstName;
      address.last_name = payload.form.lastName;
      address.house_number = payload.form.houseNumber;
      address.apartment_number = payload.form.apartmentNumber;
      address.phone = payload.form.phone;
      address.post_code = payload.form.postCode;
      address.street = payload.form.street;
      address.city = payload.form.city;
      address.uuid = payload.form.uuid;
      return {
        ...state,
        addresses: [...newState],
      };
    }
    default:
      return state;
  }
};

export default addressReducer;
