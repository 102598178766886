import React from "react";
import { SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";
import Face from "assets/files/face.jpg";

const articlesData = [
  {
    number: 1,
    user_name: "JerzyKostek",
    title: "Jak zrobić piękne witraże w domu",
    comments: 32,
    views: 134,
  },
  {
    number: 2,
    user_name: "Trylson",
    title: "Sztuka wyrobu mebli drewnianych - poradnik",
    comments: 45,
    views: 210,
  },
  {
    number: 3,
    user_name: "Pointer",
    title: "Najlepsze narzędzia do obróbki metalu",
    comments: 18,
    views: 76,
  },
  {
    number: 4,
    user_name: "Szyfliczski",
    title: "DIY: Jak zbudować własny stół drewniany",
    comments: 52,
    views: 192,
  },
  {
    number: 5,
    user_name: "Pointeres",
    title: "Techniki malarskie dla początkujących",
    comments: 27,
    views: 105,
  },
];

const ForumTopArticles = () => {
  return (
    <div className="from-top-articles">
      <div class="forum-page__header">Top wątki</div>
      <div className="forum-top-articles__content">
        {articlesData.map((article, index) => (
          <div key={index} className="forum-top-articles__item">
            <div className="forum-top-articles__item-top">
              <div className="forum-top-articles__item-number">
                {article.number}
              </div>
              <div className="forum-top-articles__item-title">
                {article.title}
              </div>
            </div>
            <div className="forum-top-articles__item-bottom">
              <div className="forum-top-articles__item-avatar">
                <img
                  className="forum-top-articles__item-avatar-icon"
                  src={Face}
                  alt=""
                />
                <div className="forum-top-articles__item-avatar-text">
                  {article.user_name}
                </div>
              </div>
              <div className="forum-top-articles__item-comment">
                <SVG
                  className="forum-top-articles__item-comment-icon"
                  type={SVG_TYPE.COMMENT}
                />
                <div className="forum-top-articles__item-view-number">
                  {article.comments}
                </div>
              </div>
              <div className="forum-top-articles__item-view">
                <SVG
                  className="forum-top-articles__item-view-icon"
                  type={SVG_TYPE.EYE}
                />
                <div className="forum-top-articles__item-view-number">
                  {article.views}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ForumTopArticles;
