import React, { useEffect, useState } from "react";
import { SVG_TYPE } from "shared/consts";
import { SVG } from "shared/components";
import { toast } from "react-toastify";

const DropFileInput = ({
  field,
  form,
  onFileDrop,
  errors,
  className = "",
  accept,
  label,
  removeAvatar,
}) => {
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const fileInputRef = React.useRef(null);
  const fileExtension =
    file && (file.name?.split(".")?.pop()?.toLocaleUpperCase() || file.extname);

  const handleDrag = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragExit = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    onFileDrop(e.dataTransfer.files);
    form.setFieldValue(field.name, e.dataTransfer.files);
  };

  const handleFileSelection = (e) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size <= 20 * 1024 * 1024) {
        // 20MB limit
        onFileDrop(files);
        form.setFieldValue(field.name, files);
      } else {
        toast.error("Plik jest zbyt duży, maksymalnie 20MB", {
          closeButton: true,
          autoClose: 3000,
        });
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleRemoveFile = () => {
    removeAvatar();
    setFile(null);
    fileInputRef.current.value = "";
    form.setFieldValue(field.name, null);
  };

  useEffect(() => {
    setFile(!!field?.value?.length ? field.value[0] : field.value);
  }, [field.value]);

  return (
    <div
      className={`drop-file-input__wrapper ${className} ${
        form.touched[field.name] && errors[field.name]
          ? "drop-file-input__wrapper--error"
          : ""
      }`}
    >
      {label ? (
        <label className="input__label" htmlFor={field.name}>
          {label}
        </label>
      ) : null}
      <div
        className={`drop-file-input ${
          file ? "drop-file-input--file-inside" : ""
        } ${dragging ? "drop-file-input--dragging" : ""}`}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDragExit}
        onDrop={handleDrop}
      >
        <div
          className={`drop-file-input__content ${
            file ? "drop-file-input__content--file" : ""
          }`}
        >
          {file ? (
            <>
              <div
                className="summary__file"
                key={`${file.name} ${file.lastModified}`}
              >
                <SVG type={fileExtension} />
                {file.name}
              </div>
              <span
                onClick={() => handleRemoveFile()}
                className="drop-file-input__remove"
              >
                <SVG type={SVG_TYPE.CLOSE} />
              </span>
            </>
          ) : (
            <>
              Przeciągnij i upuść lub
              <button
                type="button"
                className="drop-file-input__button"
                onClick={handleButtonClick}
              >
                Wybierz z dysku
              </button>
            </>
          )}
          <input
            type="file"
            accept={accept}
            ref={fileInputRef}
            className="drop-file-input__input"
            onChange={handleFileSelection}
          />
        </div>
      </div>
      {errors && form.touched && (
        <div className="input__error">
          {form.touched[field.name] && <span>{errors[field.name]}</span>}
        </div>
      )}
    </div>
  );
};

export default DropFileInput;
