/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { string, object } from "prop-types";
import { Table } from "shared/components";
import { USER_ROLE } from "shared/consts";
import {
  getClientColumns,
  getDistributorColumns,
  getCraftsmanColumns,
} from "./columns";
import {
  ClientHeaderTable,
  DistributorHeaderTable,
  CraftsmanHeaderTable,
} from "./components";

const UsersTable = ({ userSelection, users }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="user-table">
      <div className="user-table__content">
        {userSelection === USER_ROLE.CUSTOMER && users && users.length > 0 && (
          <>
            <ClientHeaderTable />
            <Table
              user
              data={users}
              columns={getClientColumns(isOpen, setIsOpen)}
            />
          </>
        )}
        {userSelection === USER_ROLE.DISTRIBUTOR && users && users.length > 0 && (
          <>
            <DistributorHeaderTable />
            <Table
              user
              data={users}
              columns={getDistributorColumns(isOpen, setIsOpen)}
            />
          </>
        )}
        {userSelection === USER_ROLE.CRAFTSMAN && users && users.length > 0 && (
          <>
            <CraftsmanHeaderTable />
            <Table
              user
              data={users}
              columns={getCraftsmanColumns(isOpen, setIsOpen)}
            />
          </>
        )}
      </div>
    </div>
  );
};

UsersTable.propTypes = {
  users: object.isRequired,
  userSelection: string.isRequired,
};

export default UsersTable;
