import Face from "assets/files/face.jpg";
import { SVG, Button } from "shared/components";
import { SVG_TYPE } from "shared/consts";

const user = () => {
  return (
    <div className="forum-question__top">
      <div className="forum-question__top-left">
        <img className="forum-question__top-avatar" src={Face} alt="" />
      </div>
      <div className="forum-question__top-right">
        <div className="forum-question__top-right__user-name">Kacpero</div>
        <div className="forum-question__top-right__bottom">
          <div className="forum-question__top-right__bottom-crafts">
            Żywica, Stolarstwo
          </div>
          <div className="forum-question__top-right__bottom-date">
            <SVG type={SVG_TYPE.CALENDAR} />
            <span>dzisiaj</span>
            {/* <span>wczoraj</span> */}
            {/* <span>przedwczoraj</span> */}
            {/* <span>07.09.2022</span> */}
            <p>o</p>
            <span>10:25</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ForumQuestion = () => {
  return (
    <div className="forum-question">
      {user()}
      <div className="forum-question-content">
        <div className="forum-question-content__top">
          <div className="forum-question-content__top-icon">P:</div>
          <div className="forum-question-content__top-title">
            Poszukuję Porady w Zakresie Wyboru Narzędzi Rzemieślniczych dla
            Początkującego
          </div>
        </div>
        <div className="forum-question-content__center">
          <div className="forum-question-content__center-left"></div>
          <div className="forum-question-content__center-right">
            Jestem całkowitym początkującym w świecie rzemiosła i marzę o
            rozpoczęciu swojej przygody w tworzeniu własnych przedmiotów.
            Jednakże mam problem z wyborem odpowiednich narzędzi. Jestem
            całkowicie zielony w tej dziedzinie, więc potrzebuję Waszej mądrości
            i doświadczenia. Czy moglibyście podzielić się swoimi radami i
            wskazówkami w zakresie wyboru podstawowych narzędzi rzemieślniczych
            dla osoby początkującej? Chodzi mi o to, jakie narzędzia są
            niezbędne na początek, jakie marki są godne uwagi, a także gdzie
            najlepiej je zakupić. Z góry dziękuję za Waszą pomoc! Chcę zacząć
            swoją przygodę z rzemiosłem z solidnym zestawem narzędzi i Wasza
            wiedza będzie dla mnie nieoceniona.
          </div>
        </div>
        <div className="forum-question-content__bottom">
          <div className="forum-question-content__bottom__tags">
            <SVG type={SVG_TYPE.TAG} />
            Stolarstwo, narzędzia, pomoc
          </div>
        </div>
        <div className="forum-question-content__buttons">
          <Button
            label="Odpowiedz"
            className="btn--frame-dark"
            onClick={() => null}
          />
          <Button
            label="Mam taki sam problem (10)"
            className="btn--frame-dark"
            onClick={() => null}
          />
        </div>
      </div>
      <div className="forum-question-answer">
        <div className="forum-question-answer__heading">Wybrana odpowiedź</div>
        {user()}
        <div className="forum-question-answer__text">
          Cześć! To świetnie, że chcesz rozpocząć swoją przygodę w świecie
          rzemiosła. Wybór odpowiednich narzędzi jest kluczowy dla sukcesu w tej
          dziedzinie. Oto kilka podstawowych narzędzi, które mogą Ci się przydać
          na początek: Piła ręczna: Dobra piła ręczna będzie niezbędna do cięcia
          drewna. Polecamy wybór piły o średniej wielkości, która jest
          uniwersalna do wielu zastosowań. Młotek: Młotek z odpowiednim uchwytem
          zapewni precyzyjne wbijanie gwoździ i wkładanie kołków. Wkrętarka
          elektryczna: To narzędzie znacznie ułatwia pracę z drewnem,
          szczególnie przy montażu mebli i innych projektów. Wybierz model z
          zestawem różnych końcówek.
        </div>
        <div className="forum-question-answer__heading">
          Wszystkie odpowiedzi
        </div>
        <div className="forum-question-answer__single">
          {user()}
          <div className="forum-question-answer__text">
            Cześć! To świetnie, że chcesz rozpocząć swoją przygodę w świecie
            rzemiosła. Wybór odpowiednich narzędzi jest kluczowy dla sukcesu w
            tej dziedzinie.
          </div>
        </div>
        <div className="forum-question-answer__single">
          {user()}
          <div className="forum-question-answer__text">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Culpa
            necessitatibus iure a minus, ipsum delectus totam expedita dolorem
            fuga porro provident corporis sit ullam voluptatum qui impedit.
            Laborum, minus earum.
          </div>
        </div>
        <div className="forum-question-answer__single">
          {user()}
          <div className="forum-question-answer__text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum,
            adipisci! Explicabo sapiente reprehenderit consequuntur porro non
            amet fugiat cupiditate totam, placeat neque est accusamus, sint,
            incidunt in temporibus sit ratione.
          </div>
        </div>
        <div className="forum-question-answer__single">
          {user()}
          <div className="forum-question-answer__text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
            voluptatum quaerat omnis dolorem, debitis expedita voluptates
            eveniet consectetur voluptate cum id ipsum cupiditate. Voluptate,
            sequi adipisci! Exercitationem expedita necessitatibus culpa.
          </div>
        </div>
        <div className="forum-question-answer__single">
          {user()}
          <div className="forum-question-answer__text">
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Esse eaque
            aliquid deserunt placeat, unde alias veritatis reiciendis a
            repellendus laudantium suscipit pariatur magnam, ut dignissimos
            voluptas ratione modi inventore dolore.
          </div>
        </div>
        <div className="forum-question-answer__single">
          {user()}
          <div className="forum-question-answer__text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Corporis
            odit cum ullam eos maiores. Quaerat iste, sapiente fugit non beatae,
            minima a velit commodi repellendus, dolorum ipsam dolor possimus
            ipsum!
          </div>
        </div>
        <div className="forum-question-answer__single">
          {user()}
          <div className="forum-question-answer__text">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde, fuga
            maxime molestiae reprehenderit omnis molestias. Iusto corrupti,
            magnam deleniti vel pariatur quis dolorem mollitia atque sunt
            laboriosam, vero exercitationem consequatur?
          </div>
        </div>
        <div className="forum-question-answer__single">
          {user()}
          <div className="forum-question-answer__text">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore sit
            repudiandae ut! Ea, quae quos! Reiciendis eligendi quos ducimus, rem
            dolorem eius officiis ipsum dolores, fugit fuga minus, facere
            minima!
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForumQuestion;
