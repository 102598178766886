import { connect } from "react-redux";
import * as ViewManagementActions from "store/actions/viewManagementActions";
import { getIsModalOpened } from "store/selectors/viewManagementSelector";
import { isMobile as checkIsMobile } from "shared/utils";
import { bindActionCreators } from "redux";
import { getAuthorizedUser as getAuthorizedUserSelector } from "store/selectors/userSelector";
import DashboardCountent from "./DashboardContent.main";
import * as UserActions from "store/actions/user";
import { logout } from "store/actions/auth";
import { getAllCategoriesAndSubcategories } from "store/actions/category";
import { getCategoriesAndSubcategories as getCategoriesAndSubcategoriesSelector } from "store/selectors/categorySelector";

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      logout: () => logout(),
      getAuthorizedUser: UserActions.getAuthorizedUser,
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
      getAllCategoriesAndSubcategories: getAllCategoriesAndSubcategories,
    },
    dispatch
  ),
});

function mapStateToProps(state) {
  return {
    authorizedUser: getAuthorizedUserSelector(state),
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
    categoriesAndSubcategories: getCategoriesAndSubcategoriesSelector(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardCountent);
