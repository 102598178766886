import React, { useRef, useEffect } from "react";

const useOutsideAlerter = (ref, props) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        ref.current.lastChild.classList.contains("view--active")
      ) {
        props.onClose();
      }
    };
    document.addEventListener("mouseup", handleClickOutside);
    return () => {
      document.removeEventListener("mousup", handleClickOutside);
    };
  }, [ref, props]);
};

const CloseClickOutside = (props) => {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props);
  return (
    <div className={props.className} ref={wrapperRef}>
      {props.children}
    </div>
  );
};

export default CloseClickOutside;
