import React from "react";
import PropTypes from "prop-types";
import {
  LOGIN_MANAGE_TYPE,
  VALIDATION_MESSAGES,
  SVG_TYPE,
  ROUTES,
} from "shared/consts";
import { RegularField, Button } from "shared/components";
import { ModalTitle } from "shared/components/modal/components";
import { Field, Formik } from "formik";
import { object, string } from "yup";
import { useHistory } from "react-router-dom";

const LoginForm = ({ changeModalType, login, closeModal, setLoadingLogin }) => {
  localStorage.removeItem("loginForm");
  const history = useHistory();
  const { EMAIL, REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const { FORGOT_PASSWORD, REGISTRATION } = LOGIN_MANAGE_TYPE;
  const validationForm = () =>
    object().shape({
      email: string().email(EMAIL).required(REQUIRED_FIELD),
      password: string().required(REQUIRED_FIELD),
    });

  const submitForm = async (values, { setErrors }) => {
    setLoadingLogin(true);
    const response = await login(values);
    setLoadingLogin(false);
    if (response?.token) {
      closeModal();
      history.push(ROUTES.PROFILE);
    } else if (response?.message === "błędny login lub hasło") {
      setErrors({ email: " ", password: " " });
    } else if (response?.message === "User is inactive") {
      closeModal();
      history.push(ROUTES.INACTIVE_ACCOUNT);
    }
  };

  return (
    <>
      <ModalTitle title="Logowanie" />
      <Formik
        initialValues={{
          email: "wilczewskikacper@gmail.com",
          password: "asdasdasd",
        }}
        onSubmit={submitForm}
        validationSchema={validationForm()}
      >
        {({ handleSubmit, errors }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Field
                component={RegularField}
                errors={errors}
                icon={SVG_TYPE.EMAIL}
                label="email"
                name="email"
                placeholder="Adres email"
                type="text"
                fullWidth
              />
              <Field
                component={RegularField}
                errors={errors}
                icon={SVG_TYPE.LOCK}
                label="hasło"
                name="password"
                placeholder="Hasło"
                type="password"
                fullWidth
              />
              <div
                className="modal__paragraph--ligth modal__paragraph--cursor"
                onClick={() => changeModalType(FORGOT_PASSWORD)}
              >
                Nie pamiętasz hasła?
              </div>
              <Button
                className="btn--default"
                label="Zaloguj się"
                type="default"
                position="center-full"
              />
              <div className="modal__paragraph--wrapper">
                <p className="modal__paragraph--ligth">
                  Nie masz jeszcze konta?
                </p>
                <div
                  className="modal__paragraph--dark modal__paragraph--cursor"
                  onClick={() => changeModalType(REGISTRATION)}
                >
                  Załóż tutaj
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;

LoginForm.propTypes = {
  changeModalType: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  setLoadingLogin: PropTypes.func.isRequired,
};
