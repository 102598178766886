import React from 'react';

const DistributorHeaderTable = () => (
  <div className="table-header--wrapper client">
    <p className="table-header nickname">nick</p>
    <p className="table-header update-at">dołączył</p>
    <p className="table-header last-login">ostatnie logowanie</p>
  </div>
);

export default DistributorHeaderTable;
