import { useState } from "react";
import { Formik, Field } from "formik";
import {
  Button,
  RegularField,
  SelectField,
  DatePickerField,
  Switch,
} from "shared/components";
import { VALIDATION_MESSAGES } from "shared/consts";
import { array, object, string } from "yup";
import { EVENT_TYPE, EVENT_TYPE_OPTIONS } from "../const";
import { categoriesOptions } from "screens/add-product-page/utils";

const AddEventForm = ({ categories }) => {
  const [eventType, setEventType] = useState("");
  const [isEventOneDay, setIsEventOneDay] = useState(true);

  const handleOnSubmit = async (values) => {
    console.log(values);
  };
  const isCompetition = eventType === EVENT_TYPE.COMPETITION;
  const isCraftsWorkshop = eventType === EVENT_TYPE.CRAFTS_WORKSHOP;
  const formText = {
    event_title: `${isCompetition ? "Tytuł konkursu:*" : ""} ${
      isCraftsWorkshop ? "Tytuł warsztatów*" : ""
    }`,
    event_title_placeholder: `${
      isCompetition ? "Wprowadź krótki, ale opisowy tytuł konkursu." : ""
    } ${
      isCraftsWorkshop ? "Wprowadź krótki, ale opisowy tytuł warsztatów." : ""
    }`,
    craft_category_placeholder: `${
      isCompetition ? "Wybierz rzemiosło którego dotyczy konkurs" : ""
    } ${isCraftsWorkshop ? "Wybierz rzemiosło którego dotyczą warsztaty" : ""}`,
    button_label: `${isCompetition ? "Dodaj konkurs" : ""} ${
      isCraftsWorkshop ? "Dodaj warsztaty" : ""
    }`,
    switch_label: `${
      isEventOneDay ? "Wydarzenie jednodniowe" : "Wydarzenie kilkudniowe"
    }`,
  };

  const validationSchema = () =>
    object().shape({
      title: string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_MESSAGES.EMPTY_SPACE)
        .max(100, VALIDATION_MESSAGES.MAX_100),
      event_type: string().required(VALIDATION_MESSAGES.REQUIRED),
      category_uuid: string().required(VALIDATION_MESSAGES.REQUIRED),
      user_count: string().required(VALIDATION_MESSAGES.REQUIRED),
      crafts_shop_descript: string().required(VALIDATION_MESSAGES.REQUIRED),
      reward: string().required(VALIDATION_MESSAGES.REQUIRED),
      evaluation_criteria: string().required(VALIDATION_MESSAGES.REQUIRED),
      start_date: string().required(VALIDATION_MESSAGES.REQUIRED),
    });
  const initialValues = {
    title: "",
    event_type: "",
    category_uuid: "",
    user_count: "",
    crafts_shop_descript: "",
    reward: "",
    evaluation_criteria: "",
    one_day_event: false,
    start_date: "",
  };

  return (
    <div className="add-event-form">
      <Formik
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema()}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          handleSubmit,
          setFieldValue,
          errors,
          setFieldTouched,
          validateField,
        }) => {
          const handleOnBlur = (name) => {
            setFieldTouched(name);
          };
          return (
            <form className="add-event-form__wrapper" onSubmit={handleSubmit}>
              <div className="add-event-form__box">
                <Field
                  className="add-event-form--type"
                  component={SelectField}
                  placeholder="Wybierz typ"
                  label="Typ wydarzenia*"
                  options={EVENT_TYPE_OPTIONS}
                  errors={errors}
                  name="event_type"
                  onChange={(e) => setEventType(e.value)}
                  onBlur={() => handleOnBlur("event_type")}
                />
                <div
                  className={`add-event-form__hide-element ${
                    eventType ? "add-event-form__hide-element--active" : ""
                  }`}
                >
                  <Field
                    className="add-event-form--crafts-workshop"
                    component={SelectField}
                    placeholder={formText.craft_category_placeholder}
                    label="Rzemiosło*"
                    options={categoriesOptions(categories)}
                    errors={errors}
                    name="category_uuid"
                    // onChange={(e) => setEventType(e.value)}
                    onBlur={() => handleOnBlur("category_uuid")}
                  />
                  <Field
                    component={RegularField}
                    errors={errors}
                    label={formText.event_title}
                    name="title"
                    placeholder={formText.event_title_placeholder}
                    type="text"
                    customBorder="customize-field"
                    onBlur={() => handleOnBlur("title")}
                    className="add-event-form--title"
                  />

                  <Field
                    component={DatePickerField}
                    placeholder={"Wybierz datę"}
                    label="Data rozpoczęcia*"
                    errors={errors}
                    name="start_date"
                    onBlur={() => handleOnBlur("start_date")}
                    className="add-event-form--date"
                  />
                  <Field
                    setFieldValue={setFieldValue}
                    component={Switch}
                    label={formText.switch_label}
                    errors={errors}
                    name="one_day_event"
                    onChange={(e) => setIsEventOneDay(!isEventOneDay)}
                    onBlur={() => handleOnBlur("one_day_event")}
                  />
                  {!isEventOneDay && (
                    <Field
                      component={DatePickerField}
                      placeholder={"Wybierz datę"}
                      label="Data zakończenia*"
                      errors={errors}
                      name="end_date"
                      onChange={(e) => setEventType(e.value)}
                      onBlur={() => handleOnBlur("end_date")}
                    />
                  )}
                  {isCraftsWorkshop && (
                    <>
                      <Field
                        component={RegularField}
                        errors={errors}
                        label="Ilość uczestników*"
                        name="user_count"
                        type="text"
                        customBorder="customize-field"
                        onBlur={() => handleOnBlur("user_count")}
                        className="add-event-form--user-count"
                      />
                      <Field
                        component={RegularField}
                        errors={errors}
                        label="Opis warsztatów*"
                        name="crafts_shop_descript"
                        type="textarea"
                        customBorder="customize-field"
                        onBlur={() => handleOnBlur("user_count")}
                      />
                    </>
                  )}
                  {isCompetition && (
                    <>
                      <Field
                        component={RegularField}
                        errors={errors}
                        label="Nagroda*"
                        name="reward"
                        placeholder="Wymień nagrody, które zostaną przyznane zwycięzcom, wraz z ich wartościami i sposobem przekazania."
                        type="textarea"
                        customBorder="customize-field"
                        onBlur={() => handleOnBlur("reward")}
                      />
                      <Field
                        component={RegularField}
                        errors={errors}
                        label="Kryteria oceny*"
                        name="evaluation_criteria"
                        placeholder="Określ, jakie kryteria będą brane pod uwagę przy ocenie projektów. To może obejmować oryginalność, kreatywność, technikę wykonania itp."
                        type="textarea"
                        customBorder="customize-field"
                        onBlur={() => handleOnBlur("evaluation_criteria")}
                      />
                    </>
                  )}
                  <Button
                    label={formText.button_label}
                    className="add-event-form__submit btn--frame-dark"
                    type="submit"
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddEventForm;

AddEventForm.propTypes = {
  categories: array.isRequired,
};
