import ForumTags from "./ForumTags";
import ForumLastAdded from "./ForumLastAdded";
import ForumSIdebarAdvertisement from "./ForumSidebarAdvertisement";
import { useLocation } from "react-router-dom";
import { ROUTES } from "shared/consts";

const ForumSidebarLeft = ({ authorizedUser }) => {
  const location = useLocation();
  const isForum = location.pathname === ROUTES.FORUM;
  const isAddQuestion = location.pathname === ROUTES.FORUM_ADD_QUESTION;

  return (
    <div className="forum-sidebar-left">
      {isForum && <ForumTags authorizedUser={authorizedUser} />}
      {isAddQuestion && <ForumSIdebarAdvertisement />}
      <ForumLastAdded />
    </div>
  );
};

export default ForumSidebarLeft;
