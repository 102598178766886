import React, { useState } from "react";
import { bool, func } from "prop-types";
import {
  DeliveryItem,
  DeliveryItemForm,
  EditDelivery,
} from "screens/delivery-page/components";
import { SVG, Modal, ModalTitle, Button } from "shared/components";
import { SVG_TYPE } from "shared/consts";

const DeliveryList = ({
  isAddDelivery,
  setDeliveryManage,
  addDelivery,
  deliveries,
  deleteDelivery,
  deliveryManage,
  editDelivery,
  addNewDelivery,
}) => {
  const [modalManage, setModalManage] = useState({ isModalOpen: false });
  const closeModal = async () => {
    setModalManage({ isModalOpen: false });
  };

  const openModal = (data) => {
    setModalManage({ isModalOpen: true, data });
  };

  const handleRemoveDelivery = () => {
    deleteDelivery(modalManage.data.uuid);
    closeModal();
  };

  return (
    <>
      <Modal opened={modalManage.isModalOpen}>
        <ModalTitle title="Czy na pewno chcesz usunąć metodę dostawy?" />
        <div className="category-modal-remove">
          <p className="paragraph">
            <SVG type={SVG_TYPE.DELIVERY} />
            {modalManage?.data?.name}
          </p>
          <p className="paragraph">
            <SVG type={SVG_TYPE.PRICE_LABEL} />
            {modalManage?.data?.price} zł
          </p>
        </div>
        <div className="modal-remove-buttons--wrapper">
          <div className="modal-remove-buttons--content">
            <Button
              label="usuń"
              className="btn--default"
              onClick={() => handleRemoveDelivery()}
            />
            <Button
              label="anuluj"
              className="btn--frame-dark"
              onClick={() => closeModal()}
            />
          </div>
        </div>
      </Modal>
      <ul className="delivery--list">
        <Button
          className="btn--frame-dark"
          onClick={() => addNewDelivery()}
          label="Dodaj nową"
        />
        {isAddDelivery && (
          <DeliveryItemForm
            addDelivery={addDelivery}
            setDeliveryManage={setDeliveryManage}
          />
        )}
        {deliveries.length > 0 &&
          deliveries.map((delivery, id) => {
            const isEdit = deliveryManage?.isEdit && deliveryManage.id === id;
            return (
              <>
                {isEdit ? (
                  <EditDelivery
                    delivery={delivery}
                    editDelivery={editDelivery}
                    setDeliveryManage={setDeliveryManage}
                  />
                ) : (
                  <DeliveryItem
                    openModal={openModal}
                    id={id}
                    setDeliveryManage={setDeliveryManage}
                    delivery={delivery}
                  />
                )}
              </>
            );
          })}
      </ul>
    </>
  );
};

export default DeliveryList;

DeliveryList.propTypes = {
  addDelivery: func.isRequired,
  deleteDelivery: func.isRequired,
  deliveries: func.isRequired,
  deliveryManage: func.isRequired,
  editDelivery: func.isRequired,
  addNewDelivery: func.isRequired,
  isAddDelivery: bool.isRequired,
  setDeliveryManage: func.isRequired,
};
