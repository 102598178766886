import { array, bool, func, object } from 'prop-types';
import React from 'react';
import { FileUploader } from 'react-drag-drop-files';
import DefaultView from '../default-view';

const PhotoDragAndDrop = ({ filesArr, fileTypes, addFiles, errors, isError }) => (
  <>
    {filesArr.length === 0 && (
      <FileUploader
        multiple
        name="file"
        types={fileTypes}
        maxSize="5"
        classes="photo-field"
        onDrop={file => addFiles(file)}
        onSelect={file => addFiles(file)}
        hoverTitle=" "
      >
        {filesArr.length === 0 && <DefaultView isError={isError} />}
        {isError && <div className="add-product-photo--error">{errors?.photos}</div>}
      </FileUploader>
    )}
  </>
);

export default PhotoDragAndDrop;

PhotoDragAndDrop.propTypes = {
  addFiles: func.isRequired,
  errors: object.isRequired,
  filesArr: array.isRequired,
  fileTypes: array.isRequired,
  isError: bool.isRequired,
};
