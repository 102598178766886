import { connect } from 'react-redux';
import * as ViewManagementActions from 'store/actions/viewManagementActions';
import { getIsModalOpened } from 'store/selectors/viewManagementSelector';
import { isMobile as checkIsMobile } from 'shared/utils';
import { bindActionCreators } from 'redux';
import { getAllCategories, getSingleCategory } from 'store/actions/category';
import {
  getAllCategories as getAllCategoriesSelector,
  getSingleCategory as getSingleCategorySelector,
} from 'store/selectors/categorySelector';
import { getDeliveries as getDeliveriesSelector } from 'store/selectors/deliverySelector';
import { getDeliveries } from 'store/actions/delivery';
import { addProduct } from 'store/actions/product';
import { addFiles } from 'store/actions/file';
import AddProductPage from './AddProductPage.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      addFiles: (file, uuid, params) => addFiles(file, uuid, params),
      addProduct: form => addProduct(form),
      getDeliveries: () => getDeliveries(),
      getAllCategories: () => getAllCategories(),
      getSingleCategory: uuid => getSingleCategory(uuid),
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
    },
    dispatch,
  ),
});

function mapStateToProps(state) {
  return {
    deliveries: getDeliveriesSelector(state),
    singleCategory: getSingleCategorySelector(state),
    categories: getAllCategoriesSelector(state),
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProductPage);
