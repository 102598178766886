import React from 'react';
import PropTypes from 'prop-types';

const ModalTitle = ({ title, description, position }) => (
  <div className="modal__top">
    <p className={`modal__top--title modal__top--title--${position}`}>{title}</p>
    {description && <p className="modal__top--description">{description}</p>}
  </div>
);

export default ModalTitle;

ModalTitle.defaultProps = {
  description: '',
};

ModalTitle.propTypes = {
  description: PropTypes.string,
  position: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
