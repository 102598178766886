import React, { useState } from "react";
import EpoxyLamg from "assets/files/products/epoxy_lamp.jpg";
import Knife from "assets/files/products/knife.jpg";
import Case from "assets/files/products/case_wood.jpg";
import Catcher from "assets/files/products/dream_catcher.jpg";
import { SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";

const PRODUCT_LIST = [
  {
    id: 1,
    image: EpoxyLamg,
  },
  {
    id: 2,
    image: Knife,
  },
  {
    id: 3,
    image: Case,
  },
  {
    id: 4,
    image: Catcher,
  },
  {
    id: 5,
    image: Knife,
  },
  {
    id: 6,
    image: Case,
  },
];

const Shop = () => {
  const [activeItem, setActiveItem] = useState({ image: "", id: null });
  return (
    <div className="home-page__shop">
      <div className="content">
        <div className="heading">Sklep z produktami rzemieślniczymi </div>
        <div className="box">
          <div className="left">
            <img src={activeItem.image} alt="" />
          </div>
          <div className="right">
            <div className="list__navi">
              <div className="list__navi-item">
                <SVG type={SVG_TYPE.LONG_ARROW_LEFT} />
              </div>
              <div className="list__navi-item">
                <SVG type={SVG_TYPE.LONG_ARROW_RIGHT} />
              </div>
            </div>
            <div className="list">
              {PRODUCT_LIST.map(({ image, id }) => {
                const isActive = activeItem === id;
                return (
                  <div
                    onClick={() => setActiveItem({ image, id })}
                    className={`home-page__shop__product ${
                      isActive ? "home-page__shop__product--active" : ""
                    }`}
                  >
                    <img src={image} alt="" />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
