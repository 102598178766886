import { DELIVERY } from 'store/types';
// import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  deliveries: [],
};

const deliveryReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case DELIVERY.GET_DELIVERIES_INIT: {
      return {
        ...state,
        deliveries: [],
      };
    }
    case DELIVERY.GET_DELIVERIES_SUCCESS: {
      return {
        ...state,
        deliveries: payload.data,
      };
    }
    case DELIVERY.GET_DELIVERIES_FAILURE: {
      return {
        ...state,
        deliveries: [],
      };
    }
    case DELIVERY.ADD_DELIVERY_SUCCESS: {
      console.log(payload);
      console.log(state.deliveries);
      return {
        ...state,
        deliveries: [...state.deliveries, payload.data],
      };
    }
    case DELIVERY.REMOVE_DELIVERY_SUCCESS: {
      return {
        ...state,
        deliveries: state.deliveries.filter(item => item.uuid !== payload.data.uuid),
      };
    }
    default:
      return state;
  }
};

export default deliveryReducer;
