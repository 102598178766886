import React from 'react';
import { func, shape } from 'prop-types';
import { PageContainer } from 'shared/components';
import { Right, Left } from './components';

const ContactPage = ({ actions: { sendMessageContactForm } }) => (
  <PageContainer full className="contact">
    <Left sendMessageContactForm={sendMessageContactForm} />
    <Right />
  </PageContainer>
);
export default ContactPage;

ContactPage.propTypes = {
  actions: shape({
    sendMessageContactForm: func.isRequired,
  }).isRequired,
};
