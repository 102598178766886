import React from "react";
import { func, string, object } from "prop-types";
import { USER_ROLE, USER_TYPE } from "shared/consts";

const Counters = ({ userSelection, setUserSelection, users }) => (
  <div className="counters">
    <div
      className="counter-wrapper"
      onClick={() => setUserSelection(USER_ROLE.CUSTOMER)}
    >
      <div className="counter counter--client">
        <p className="counter-label-number"> 10 </p>
        <span className="counter-label">Klienci</span>
      </div>
    </div>
    <div
      className="counter-wrapper"
      onClick={() => setUserSelection(USER_TYPE.CRAFTSMAN)}
    >
      <div className="counter counter--craftsman">
        <p className="counter-label-number"> 10 </p>
        <span className="counter-label">Rzemieślnicy</span>
      </div>
    </div>
    <div className="counter-wrapper counter-wrapper--bigger">
      <div
        className="counter counter--distributor"
        onClick={() => setUserSelection(USER_TYPE.PARTNER)}
      >
        <p className="counter-label-number"> 100 </p>
        <span className="counter-label ">Partnerzy</span>
      </div>
    </div>
    <div className="counter-wrapper" onClick={() => setUserSelection("editor")}>
      <div className="counter counter--editor">
        <p className="counter-label-number"> 10 </p>
        <p className="counter-label"> Edytorzy </p>
      </div>
    </div>
    {/* <p className="heading">Pokaż w tabeli:</p> */}
    {/* <div className="counters">
      <div
        className={`counter ${userSelection === USER_ROLE.CLIENT ? 'counter--active' : ''}`}
        onClick={() => setUserSelection(USER_ROLE.CLIENT)}
      >
        <p className="counter__label counter__label--client">Klientów:</p>
        <p className="counter__amount">{users && users.usersLength && users.usersLength.clients}</p>
      </div>
      <div
        className={`counter ${userSelection === USER_ROLE.DISTRIBUTOR ? 'counter--active' : ''}`}
        onClick={() => setUserSelection(USER_ROLE.DISTRIBUTOR)}
      >
        <p className="counter__label counter__label--distributor">Dystrybutorów:</p>
        <p className="counter__amount">{users && users.usersLength && users.usersLength.distributors}</p>
      </div>
      <div
        className={`counter ${userSelection === USER_ROLE.EDITORS ? 'counter--active' : ''}`}
        onClick={() => setUserSelection(USER_ROLE.EDITORS)}
      >
        <p className="counter__label counter__label--editor">Redaktorzy:</p>
        <p className="counter__amount">{users && users.usersLength && users.usersLength.editors}</p>
      </div>
      <div
        className={`counter ${userSelection === USER_ROLE.CRAFTSMAN ? 'counter--active' : ''}`}
        onClick={() => setUserSelection(USER_ROLE.CRAFTSMAN)}
      >
        <p className="counter__label counter__label--craftsman">Rzemieślinków:</p>
        <p className="counter__amount">{users && users.usersLength && users.usersLength.craftsmans}</p>
      </div>
    </div> */}
  </div>
);

export default Counters;

Counters.propTypes = {
  setUserSelection: func.isRequired,
  users: object.isRequired,
  userSelection: string.isRequired,
};
