import React, { useState } from 'react';
import { func, bool, object } from 'prop-types';
import { LOGIN_MANAGE_TYPE, SVG_TYPE, ROUTES, USER_ROLE } from 'shared/consts';
import { SVG } from 'shared/components';
import { useHistory } from 'react-router-dom';

const HeaderMain = ({
  handleOpenModal,
  isMobile,
  blurredOn,
  openModal,
  setIsBasketOpen,
  isUserLogged,
  logout,
  authorizedUser,
}) => {
  const handleClickBasket = () => {
    blurredOn();
    openModal();
    setIsBasketOpen(true);
    const pageWrapper = document.getElementById('page__wrapper');
    pageWrapper.classList.add('page__wrapper--stick');
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isLoggedUser = isUserLogged;
  const history = useHistory();
  const isAdmin = authorizedUser?.role === USER_ROLE.ADMIN;

  const redirectFromMenu = path => {
    setIsMenuOpen(false);
    history.push(path);
  };

  const handleLogout = async () => {
    await logout();
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="headerMain__wrapper">
      <div className="headerMain__content">
        <div className="headerMain__items">
          {!isMobile && (
            <>
              <div className="headerMain__item">
                <a href="wp.pl" className="headerMain__item--link headerMain__item--link--first">
                  Fala
                </a>
              </div>
              <div className="headerMain__item">
                <a href="wp.pl" className="headerMain__item--link headerMain__item--link--first">
                  Vidaron
                </a>
                <div className="headerMain__item--divider headerMain__item--divider--green" />
              </div>
              <div className="headerMain__item">
                <a href="wp.pl" className="headerMain__item--link headerMain__item--link--first">
                  Proline
                </a>
                <div className="headerMain__item--divider headerMain__item--divider--red" />
              </div>
            </>
          )}
        </div>
        <div className="headerMain--icons">
          <div className="headerMain--icon watch" onClick={() => redirectFromMenu(ROUTES.FAVORITES)}>
            <SVG type={SVG_TYPE.HEART} />
          </div>
          <div className="headerMain--icon basket" onClick={() => handleClickBasket()}>
            <SVG type={SVG_TYPE.BASKET} />
          </div>
          {isLoggedUser ? (
            <div className="headerMain--my-account__wrapper">
              <div className="headerMain--my-account" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                moje konto <SVG type={SVG_TYPE.CHEVRONE} className={`${isMenuOpen ? 'headerMain--my-account--active' : ''}`} />
              </div>
              <div id="account-menu" className={`account-menu ${isMenuOpen ? 'account-menu--active' : ''}`}>
                <div className="account-menu--item" onClick={() => redirectFromMenu(ROUTES.PROFILE)}>
                  <SVG type={SVG_TYPE.USER} />
                  <p className="account-menu--item--text">profil</p>
                </div>
                <div className="account-menu--item" onClick={() => redirectFromMenu(ROUTES.MY_ADVERTISEMENTS)}>
                  <SVG type={SVG_TYPE.PRODUCT} />
                  <p className="account-menu--item--text">moje produkty</p>
                </div>
                {/* <div className="account-menu--item">
                  <SVG type={SVG_TYPE.SETTINGS} />
                  <p className="account-menu--item--text">ustawienia</p>
                </div> */}
                {isAdmin && (
                  <>
                    <div className="account-menu--item" onClick={() => redirectFromMenu(ROUTES.CATEGORY)}>
                      <SVG type={SVG_TYPE.CATEGORY} />
                      <p className="account-menu--item--text">kategorie</p>
                    </div>
                    <div className="account-menu--item" onClick={() => redirectFromMenu(ROUTES.DELIVERY_PAGE)}>
                      <SVG type={SVG_TYPE.DELIVERY} />
                      <p className="account-menu--item--text">metody dostawy</p>
                    </div>
                  </>
                )}
                <div className="account-menu--item" onClick={() => handleLogout()}>
                  <SVG type={SVG_TYPE.ONOFF} />
                  <p className="account-menu--item--text account-menu--item--text--red">wyloguj się</p>
                </div>
              </div>
            </div>
          ) : (
            <div id="account-btn" className="headerMain--icon account" onClick={() => handleOpenModal(LOGIN_MANAGE_TYPE.LOGIN)}>
              <SVG type={SVG_TYPE.ACCOUNT} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderMain;
HeaderMain.propTypes = {
  authorizedUser: object.isRequired,
  blurredOff: func.isRequired,
  blurredOn: func.isRequired,
  handleOpenModal: func.isRequired,
  isMobile: bool.isRequired,
  isUserLogged: object.isRequired,
  logout: func.isRequired,
  openModal: bool.isRequired,
  setIsBasketOpen: func.isRequired,
};
