import React from "react";
import { bool } from "prop-types";
import { PageContainer } from "shared/components";
import {
  Crafts,
  Header,
  Shop,
  JoinForum,
  OurPartners,
  Opinions,
} from "./components";

const HomePage = () => (
  <>
    <PageContainer full className="home-page">
      <Header />
      <Crafts />
      <Shop />
      <JoinForum />
      <OurPartners />
      <Opinions />
    </PageContainer>
  </>
);

export default HomePage;

HomePage.propTypes = {
  isMobile: bool.isRequired,
  isModalOpened: bool.isRequired,
};
