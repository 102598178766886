const DELIVERY = {
  ADD_ADDRESS_INIT: 'ADD_ADDRESS_INIT',
  ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
  ADD_ADDRESS_FAILURE: 'ADD_ADDRESS_FAILURE',
  GET_ADDRESSES_BY_ID_INIT: 'GET_ADDRESSES_BY_ID_INIT',
  GET_ADDRESSES_BY_ID_SUCCESS: 'GET_ADDRESSES_BY_ID_SUCCESS',
  GET_ADDRESSES_BY_ID_FAILURE: 'GET_ADDRESSES_BY_ID_FAILURE',
  EDIT_ADDRESS_INIT: 'EDIT_ADDRESS_INIT',
  EDIT_ADDRESS_SUCCESS: 'EDIT_ADDRESS_SUCCESS',
  EDIT_ADDRESS_FAILURE: 'EDIT_ADDRESS_FAILURE',
  REMOVE_ADDRESS_INIT: 'REMOVE_ADDRESS_INIT',
  REMOVE_ADDRESS_SUCCESS: 'REMOVE_ADDRESS_SUCCESS',
  REMOVE_ADDRESS_FAILURE: 'REMOVE_ADDRESS_FAILURE',
};

export default DELIVERY;
