import React from 'react';
import { SVG } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';

const Right = () => (
  <div className="contact--box contact--right">
    <div className="info">
      <div className="info--content">
        <div className="info--head">Kontakt</div>
        <div className="info--item">
          <SVG className="info--icon" type={SVG_TYPE.MAIL} />
          <p className="info--paragraph">rzemieslinicy@gmail.com</p>
        </div>
      </div>
    </div>
    <div className="sidebar">
      <div className="socials">
        <SVG className="socials--icon" type={SVG_TYPE.FACEBOOK} />
        <SVG className="socials--icon" type={SVG_TYPE.INSTAGRAM} />
      </div>
    </div>
  </div>
);

export default Right;
