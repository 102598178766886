import React from 'react';
import { bool, string, array, func } from 'prop-types';
import { CloseClickOutside } from 'shared/utils';
import { MenuItem } from './components';

const Menu = ({ isOpen, position, menuLists, onClose }) => (
  <CloseClickOutside onClose={onClose}>
    <div className={`menu ${isOpen ? 'menu--active' : ''} menu__position--${position}`}>
      <ul className="menu--list">
        {menuLists.map(({ label, className, onClick, icon }) => (
          <MenuItem
            icon={icon}
            label={label}
            className={className}
            onClick={() => {
              onClose();
              onClick();
            }}
          />
        ))}
      </ul>
    </div>
  </CloseClickOutside>
);

export default Menu;

Menu.propTypes = {
  isOpen: bool.isRequired,
  menuLists: array.isRequired,
  onClose: func.isRequired,
  position: string.isRequired,
};
