import * as React from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import { SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";

const SelectField = ({
  field: { name, value },
  form,
  errors,
  placeholder,
  options,
  isCustomLabel,
  label,
  onChange,
  outsideFormChange,
  menuPlacement,
  className,
  disabled,
  onBlur,
  multi,
}) => {
  const isError = form && !!(form.touched[name] && errors[name]);
  const fieldClasses = `select-field__input ${
    isError ? "select-field__input--error" : ""
  }`;
  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <SVG type={SVG_TYPE.CHEVRONE} />
    </components.DropdownIndicator>
  );

  const handleChange = (tempValue) => {
    form && form.setFieldValue(name, tempValue ? tempValue.value : "");
    if (name === "role") {
      form.setFieldValue("position", "");
    }
    onChange && form && onChange(tempValue, form.setFieldValue);
  };

  // Added to deliver onChange handler without react form
  const handleOutisideFormChange = (tempValue) => {
    outsideFormChange && outsideFormChange(tempValue || "");
  };

  const customFilter = (option, searchText) => {
    if (
      option.data.searchableLabel
        ? option.data.searchableLabel
            .toLowerCase()
            .includes(searchText.toLowerCase())
        : option.data.label.toLowerCase().includes(searchText.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  const getValue = () =>
    value || value == null || value === 0
      ? options && options.find((option) => option.value === value)
      : "";

  return (
    <div className={`select-field__wrapper ${className || ""}`}>
      {label && (
        <div
          className={`select-field__label ${
            disabled ? "select-field__label--is-disabled" : ""
          }`}
        >
          {label}
        </div>
      )}
      <div className="select-field">
        <div className="select-field__container">
          <Select
            id={`validate--${name}`}
            className={`react-select ${fieldClasses}`}
            classNamePrefix="react-select"
            components={{ DropdownIndicator }}
            filterOption={isCustomLabel && customFilter}
            isClearable
            isMulti={multi}
            isSearchable
            noOptionsMessage={() => "Brak wyników"}
            onChange={form ? handleChange : handleOutisideFormChange}
            options={options}
            placeholder={placeholder}
            value={getValue()}
            menuPlacement={menuPlacement || "bottom"}
            isDisabled={disabled}
            onBlur={onBlur}
          />
          {isError && (
            <div className="select-field__error-label">{errors[name]}</div>
          )}
        </div>
      </div>
    </div>
  );
};
SelectField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }),
  form: PropTypes.object.isRequired,
  isCustomLabel: PropTypes.bool,
  multi: PropTypes.bool,
  label: PropTypes.string,
  menuPlacement: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.array,
  outsideFormChange: PropTypes.func,
  placeholder: PropTypes.string,
};

SelectField.defaultProps = {
  className: "",
  disabled: false,
  errors: {},
  field: PropTypes.shape({
    name: "",
    onChange: "",
    value: "",
  }),
  isCustomLabel: false,
  label: "",
  menuPlacement: "",
  multi: false,
  onBlur: null,
  onChange: null,
  options: [],
  outsideFormChange: null,
  placeholder: "",
};

export default SelectField;
