import React from 'react';
import { SVG } from 'shared/components';
import { func, string } from 'prop-types';

const MenuItem = ({ label, className, onClick, icon }) => (
  <div onClick={onClick} className={`menu--item ${className ? `menu--item--${className}` : ''}`}>
    <button type="button" className={`menu--button ${className ? `menu--button--${className}` : ''}`}>
      <SVG type={icon} className={`menu--button--icon ${className ? `menu--button--icon--${className}` : ''}`} />
      {label}
    </button>
  </div>
);

export default MenuItem;

MenuItem.defaultProps = {
  className: '',
};

MenuItem.propTypes = {
  className: string,
  icon: string.isRequired,
  label: string.isRequired,
  onClick: func.isRequired,
};
