import { createSelector } from "reselect";
// import { RootState } from 'Store/Reducers/RootReducer';

const getViewManagementStore = (state) => state.viewManagementStore;
// const getIsMenuExpanded = createSelector(getViewManagementStore, state => state.isMenuExpanded);
const getIsModalOpened = createSelector(
  getViewManagementStore,
  (state) => state.isModalOpened
);
const getIsMenuOpened = createSelector(
  getViewManagementStore,
  (state) => state.isMenuOpened
);
const getIsBlurred = createSelector(
  getViewManagementStore,
  (state) => state.isBlurred
);
const getIsDarMode = createSelector(
  getViewManagementStore,
  (state) => state.isDarkMode
);

export { getIsModalOpened, getIsDarMode, getIsBlurred, getIsMenuOpened };
