const categoriesOptions = categories => {
  const categoriesOptionsArr = [];
  categories &&
    categories.forEach(item => {
      categoriesOptionsArr.push({ searchableLabel: item.name, label: item.name, value: item.uuid });
    });
  return categoriesOptionsArr;
};

export default categoriesOptions;
