import * as Connector from 'shared/connectors/fileConnector';
import { FILE } from 'store/types';

const init = () => ({
  type: FILE.ADD_FILE_INIT,
});

export const success = data => ({
  type: FILE.ADD_FILE_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: FILE.ADD_FILE_FAILURE,
});

const addFiles = (file, params) => async dispatch => {
  dispatch(init());
  try {
    await Connector.addFiles(file, params);
    return true;
  } catch (err) {
    dispatch(failure());
    return err.response;
  }
};

export default addFiles;
