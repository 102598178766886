import React from 'react';
import { Field, Formik } from 'formik';
import { SVG_TYPE, VALIDATION_MESSAGES } from 'shared/consts';
import { RegularField, SVG } from 'shared/components';
import { object, string } from 'yup';
import { func, number } from 'prop-types';

const NewSubcategoryForm = ({ createSubcategory, category, setManageSubcategoryForm, id }) => {
  const { REQUIRED_FIELD } = VALIDATION_MESSAGES;
  const validationForm = () =>
    object().shape({
      link: string().required(REQUIRED_FIELD),
      name: string().required(REQUIRED_FIELD),
    });

  const submitForm = async (values, actions) => {
    const response = await createSubcategory({ ...values, category_uuid: category.uuid });
    if (response.link_exist && response.name_exist) {
      actions.setErrors({ name: 'Nazwa zajęta', link: 'Adres url zajęty' });
    } else if (response?.name_exist) {
      actions.setErrors({ name: 'Nazwa zajęta' });
    } else if (response?.link_exist) {
      actions.setErrors({ link: 'Url zajety' });
    } else if (response?.status === 'success') {
      setManageSubcategoryForm({ isOpen: true, id });
      actions.resetForm();
    }
  };

  return (
    <Formik initialValues={{ name: '', link: '' }} onSubmit={submitForm} validationSchema={validationForm()}>
      {({ handleSubmit, errors }) => (
        <form onSubmit={handleSubmit} className="new-category-form new-category-form--subcategory">
          <Field
            component={RegularField}
            errors={errors}
            name="name"
            placeholder="Nowa podkategoria"
            type="text"
            fullWidth
            icon={SVG_TYPE.SUBCATEGORY}
          />
          <Field
            component={RegularField}
            errors={errors}
            name="link"
            placeholder="link"
            type="text"
            fullWidth
            icon={SVG_TYPE.URL}
          />
          <div className="subcategory__item--single-action" onClick={handleSubmit}>
            <SVG type={SVG_TYPE.PLUS} />
          </div>
        </form>
      )}
    </Formik>
  );
};

export default NewSubcategoryForm;

NewSubcategoryForm.propTypes = {
  category: func.isRequired,
  createSubcategory: func.isRequired,
  id: number.isRequired,
  setManageSubcategoryForm: func.isRequired,
};
