import { createSelector } from "reselect";
// import { RootState } from 'Store/Reducers/RootReducer';

const getForumStore = (state) => state.forumStore;
const getForumQuestions = createSelector(
  getForumStore,
  (state) => state.forumQuestions
);

export { getForumQuestions };
