import { connect } from 'react-redux';
import * as ViewManagementActions from 'store/actions/viewManagementActions';
import { getIsModalOpened, getIsDarMode, getIsBlurred } from 'store/selectors/viewManagementSelector';
import { isMobile as checkIsMobile } from 'shared/utils';
import { bindActionCreators } from 'redux';
import { getAuthorizedUser as getAuthorizedUserSelector } from 'store/selectors/userSelector';
import * as UserActions from 'store/actions/user';
import { logout } from 'store/actions/auth';
import { checkToken, activateAccount } from 'store/actions/user';
import NavBar from './NavBar.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      logout: () => logout(),
      checkToken: (token, which) => checkToken(token, which),
      activateAccount: data => activateAccount(data),
      getAuthorizedUser: UserActions.getAuthorizedUser,
      darkMode: ViewManagementActions.darkMode,
      openModal: ViewManagementActions.openModal,
      closeModal: ViewManagementActions.closeModal,
      blurredOn: ViewManagementActions.blurredOn,
      blurredOff: ViewManagementActions.blurredOff,
    },
    dispatch,
  ),
});

const mapStateToProps = state => ({
  authorizedUser: getAuthorizedUserSelector(state),
  isModalOpened: getIsModalOpened(state),
  isMobile: checkIsMobile(),
  isDarkMode: getIsDarMode(state),
  isBlurred: getIsBlurred(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
