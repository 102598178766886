import { connect } from 'react-redux';
import { isMobile as checkIsMobile } from 'shared/utils';
import TopBar from './TopBar.main';

const mapDispatchToProps = () => ({});

const mapStateToProps = () => ({
  isMobile: checkIsMobile(),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
