import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';

export const success = data => ({
  type: CATEGORY.GET_SINGLE_CATEGORY_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CATEGORY.GET_SINGLE_CATEGORY_FAILURE,
});

const getSingleCategory = uuid => async dispatch => {
  // console.log('poszło');
  try {
    const { data } = await Connector.getSingleCategory(uuid);
    dispatch(success(data.category));
    return data.category;
  } catch (err) {
    dispatch(failure());
    return err.response.data;
  }
};

export default getSingleCategory;
