import React from 'react';
import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';
import { toast } from 'react-toastify';
import { NOTIFY_TYPE } from 'shared/consts';
import { Notify } from 'shared/components';

const init = () => ({
  type: USER.USER_RESEND_LINK_INIT,
});

export const success = data => ({
  type: USER.USER_RESEND_LINK_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: USER.USER_RESEND_LINK_FAILURE,
});

// eslint-disable-next-line consistent-return
const userResendLink = email => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.userResendLink(email);
    toast(<Notify label="Sprawdź skrzynkę pocztową" type={NOTIFY_TYPE.SEND_MAIL} color="green" />);
    dispatch(success(data));
    return data;
  } catch (err) {
    dispatch(failure());
    console.log(err.response.data);
    return err.response.data;
  }
};

export default userResendLink;
