import Slider from "react-slick";
import { ROUTES } from "shared/consts";
import { useHistory } from "react-router-dom";

const CraftsCategory = ({ categoriesAndSubcategories }) => {
  const history = useHistory();

  const settings = {
    infinite: true,
    arrows: false,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 1500,
    slidesToShow: 6,
    slidesToScroll: 1,
  };

  const updateCategoryUrl = (item) => {
    history.push(`${ROUTES.CRAFTS}?category=${item.link}`);
  };

  return (
    <div className="crafts-category">
      <div className="crafts-category__content">
        <div className="crafts-category__list">
          <div
            className="crafts-category__item crafts-category__item--active"
            onClick={() => history.push(ROUTES.CRAFTS)}
          >
            Wszystkie
          </div>
          <Slider {...settings}>
            {categoriesAndSubcategories.map((item) => {
              return (
                <div
                  key={item.uuid}
                  className="crafts-category__item"
                  onClick={() => updateCategoryUrl(item)}
                >
                  {item.name}
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default CraftsCategory;
