const DELIVERY = {
  ADD_DELIVERY_INIT: 'ADD_DELIVERY_INIT',
  ADD_DELIVERY_SUCCESS: 'ADD_DELIVERY_SUCCESS',
  ADD_DELIVERY_FAILURE: 'ADD_DELIVERY_FAILURE',
  GET_DELIVERIES_INIT: 'GET_DELIVERIES_INIT',
  GET_DELIVERIES_SUCCESS: 'GET_DELIVERIES_SUCCESS',
  GET_DELIVERIES_FAILURE: 'GET_DELIVERIES_FAILURE',
  REMOVE_DELIVERY_INIT: 'REMOVE_DELIVERY_INIT',
  REMOVE_DELIVERY_SUCCESS: 'REMOVE_DELIVERY_SUCCESS',
  REMOVE_DELIVERY_FAILURE: 'REMOVE_DELIVERY_FAILURE',
  EDIT_DELIVERY_SUCCESS: 'EDIT_DELIVERY_SUCCESS',
};

export default DELIVERY;
