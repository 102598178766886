import Table from 'assets/files/products/table.jpg';
import Sculpture from 'assets/files/products/sculpture.jpg';
import Knife from 'assets/files/products/knife.jpg';
import KnifeBushcraft from 'assets/files/products/knife_bushcraft.jpg';
import EpoxyLamp from 'assets/files/products/epoxy_lamp.jpg';
import EpoxyLamp2 from 'assets/files/products/epoxy_lamp_2.jpg';
import DreamCatcher from 'assets/files/products/dream_catcher.jpg';
import CaseWood from 'assets/files/products/case_wood.jpg';
import Blacksmitch from 'assets/files/products/blacksmith.jpg';

const ProductsElements = [
  {
    id: 1,
    path: Table,
    name: 'Stół z żywicy',
    price: '1800,00',
    is_favorie: false,
    uuid: '8712uyasdhiu127iy3hasudkas',
    nick: 'Patalczyki',
  },
  {
    id: 2,
    path: Sculpture,
    name: 'Rz eźba tw ar zy ma ni fent ure by Ka rina Mali now ski poziomy rozmowy ',
    price: '2000,00',
    is_favorie: true,
    uuid: 'asidji1827uhiasdgh78UI@Hdkuasj',
    nick: 'Krinsky',
  },
  {
    id: 3,
    path: Knife,
    name: 'Nóz kuchenny',
    price: '144,00',
    is_favorie: false,
    uuid: '1289731uiHDASUJKnkjnmzxHdkuasj',
    nick: 'GoodVibes',
  },
  {
    id: 4,
    path: KnifeBushcraft,
    name: 'Nóz prawdziwego bushcraftera',
    price: '209,00',
    is_favorie: false,
    uuid: 'asijdhuih7@i89jasdjbkzxnmcb',
    nick: 'Stolarz',
  },
  {
    id: 5,
    path: EpoxyLamp,
    name: 'Prostokątna lampa z zywicy',
    price: '309,00',
    is_favorie: false,
    uuid: 'asijdhuih7@i89jasdaasdjbkzxnmcb',
    nick: 'Zywicomaniak',
  },
  {
    id: 6,
    path: EpoxyLamp2,
    name: 'Mglista lampa z zywicy',
    price: '509,00',
    is_favorie: true,
    uuid: 'asijdhuih12317@i89jasdjbkzxnmcb',
    nick: 'strylen',
  },
  {
    id: 7,
    path: DreamCatcher,
    name: 'Łapacz snów Karoliński',
    price: '89,00',
    is_favorie: true,
    uuid: 'asdasdazxc@i89jasdjbkzxnmcb',
    nick: 'Parasn',
  },
  {
    id: 8,
    path: CaseWood,
    name: 'Obudowa drawniana na telefon',
    price: '169,00',
    is_favorie: false,
    uuid: 'as1123dasdazxc@i89jasdjbkzxnmcb',
    nick: 'Kulsky',
  },
  {
    id: 9,
    path: Blacksmitch,
    name: 'Zelazna spinka do włosów',
    price: '59,00',
    is_favorie: false,
    uuid: 'as1123zzsdasdazxc@i89jasdjbkzxnmcb',
    nick: 'marcin009',
  },
];

export default ProductsElements;
