import { string } from "prop-types";
import React from "react";
import { isMobile } from "shared/utils";

const PageContainer = (props) => {
  const isMobileClass = `${isMobile() ? `page--mobile` : `page--desktop`}`;
  const idDashboardPage = window.location.pathname.includes("dashboard");
  return (
    <div
      className={`page__inner ${idDashboardPage ? "page__dashboard" : ""} ${
        props.full ? "page--full" : ""
      }`}
    >
      <main className={`page ${props.className} ${isMobileClass}`}>
        {props.children}
      </main>
    </div>
  );
};

export default PageContainer;

PageContainer.propTypes = {
  full: string.isRequired,
};
