import { useState, useEffect } from "react";
import { func, object } from "prop-types";
import React from "react";
import { SVG } from "shared/components";
import { useHistory } from "react-router-dom";
import {
  SVG_TYPE,
  ADMIN_MENU,
  USER_MENU,
  USER_ROLE,
  ROUTES,
  // ROUTES,
} from "shared/consts";

const Sidebar = ({ setMenu, user }) => {
  const [isFit, setIsFit] = useState(true);
  const [sidebarMenu, setSidebarMenu] = useState([]);
  const history = useHistory();
  const isAdmin = user?.role === USER_ROLE.ADMIN;
  // const { pathname } = window.location;
  const roundedElement = window.location.pathname.startsWith(ROUTES.CRAFTS);

  const expandIconClass = `${
    !isFit ? "dashboard-sidebar__hide-icon--left" : ""
  }`;
  const profileSidebarClass = `dashboard-sidebar ${
    isFit ? "dashboard-sidebar--fit" : ""
  }`;

  const handleClick = ({ type, routes }) => {
    setMenu(type);
    history.push(routes);
  };

  useEffect(() => {
    if (user) {
      if (isAdmin) {
        setSidebarMenu(ADMIN_MENU);
      } else {
        setSidebarMenu(USER_MENU);
      }
    }
  }, [user, isAdmin]);

  return (
    <div className={profileSidebarClass}>
      <div className="dashboard-sidebar__top">
        <div className="dashboard-sidebar__curve-element"></div>
        <div
          className="dashboard-sidebar__hide-icon"
          onClick={() => setIsFit(!isFit)}
        >
          <SVG className={expandIconClass} type={SVG_TYPE.CHEVRONE} />
        </div>
      </div>
      <nav className="dashboard-sidebar__menu">
        {user &&
          sidebarMenu &&
          sidebarMenu.map((item, index) => {
            const profileSidebarSingleClass = `dashboard-sidebar__menu--item ${
              window.location.pathname.startsWith(item.routes)
                ? `dashboard-sidebar__menu--item--active ${
                    roundedElement
                      ? "dashboard-sidebar__menu--item--rounded"
                      : ""
                  }`
                : ""
            }`;
            return (
              <>
                <div
                  className={profileSidebarSingleClass}
                  onClick={() => handleClick(item)}
                >
                  <div className="dashboard-sidebar__menu--item-content">
                    <div className="dashboard-sidebar__menu--item-icon">
                      {" "}
                      <SVG type={item.svg_type} />{" "}
                    </div>
                    <p>{item.label}</p>
                  </div>
                </div>
              </>
            );
          })}
      </nav>
    </div>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  user: object.isRequired,
  setMenu: func.isRequired,
};
