const FILE = {
  ADD_FILE_INIT: 'ADD_FILE_INIT',
  ADD_FILE_SUCCESS: 'ADD_FILE_SUCCESS',
  ADD_FILE_FAILURE: 'ADD_FILE_FAILURE',
  DELETE_FILE_INIT: 'DELETE_FILE_INIT',
  DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
  DELETE_FILE_FAILURE: 'DELETE_FILE_FAILURE',
};

export default FILE;
