import { getForumQuestions as getForumQuestionsConnector } from "shared/connectors/forumConnector";
import { FORUM } from "store/types";

const init = () => ({
  type: FORUM.GET_FORUM_QUESTIONS_INIT,
});

export const success = (data) => ({
  type: FORUM.GET_FORUM_QUESTIONS_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: FORUM.GET_FORUM_QUESTIONS_FAILURE,
});

const getForumQuestions = () => async (dispatch) => {
  dispatch(init());
  try {
    const response = await getForumQuestionsConnector();
    dispatch(success(response.data.ForumQuestions));
    return response.data;
  } catch (err) {
    dispatch(failure());
    return err.response.data;
  }
};

export default getForumQuestions;
