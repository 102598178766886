import { SVG_TYPE, ROUTES } from 'shared/consts';
import { useHistory } from 'react-router-dom';
import { func } from 'prop-types';

const MENU_TOP_BAR = () => {
  const history = useHistory();
  const array = [
    {
      label: 'mój profil',
      icon: SVG_TYPE.USER,
      onClick: () => history.push(ROUTES.USER_PAGE),
    },
    {
      label: 'Wyloguj się',
      icon: SVG_TYPE.ONOF,
      className: 'red',
    },
  ];
  return array;
};

export default MENU_TOP_BAR;

MENU_TOP_BAR.propTypes = {
  handleCloseSettings: func.isRequired,
};
