import React from "react";
import { object, string } from "prop-types";

const CurvesSection = (props, { className }) => (
  <div className="curves-section">
    <svg className="curves-section--top" viewBox="0 0 1440 192" fill="none">
      <path d="M0 0L1440 192V0H0Z" fill="#161D2E" />
    </svg>
    <div
      className={`curves-section__content ${
        className ? `curves-section__content--${className}` : ""
      }`}
    >
      {props.children}
    </div>
    <svg
      className="curves-section--bottom"
      viewBox="0 0 1436 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M-4 0L1436 224V256H-4V0Z" fill="#161D2E" />
    </svg>
  </div>
);

export default CurvesSection;

CurvesSection.propTypes = {
  props: object.isRequired,
  className: string.isRequired,
};
