import React from 'react';
import PropTypes from 'prop-types';

const ModalTitle = ({ title, description }) => (
  <div className="modal__top">
    <p className="modal__top--title">{title}</p>
    {description && <p className="modal__top--description">{description}</p>}
  </div>
);

export default ModalTitle;

ModalTitle.defaultProps = {
  description: '',
};

ModalTitle.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};
