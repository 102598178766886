import React from "react";
import { PageContainer, SVG, Button } from "shared/components";
import { ROUTES, SVG_TYPE } from "shared/consts";
import { useHistory } from "react-router-dom";

const InactiveAccountPage = () => {
  const history = useHistory();
  const openLoginForm = () => {
    history.push(ROUTES.INACTIVE_TOKEN);
  };

  return (
    <PageContainer full className="inactive">
      <div className="inactive">
        <div className="inactive--content">
          <SVG type={SVG_TYPE.INACTIVE_ACCOUNT_ILU} />
          <h1 className="inactive--header">Twoje konto jest nieaktywne!</h1>
          <h1 className="inactive--paragraph">
            Sprawdź maila lub wygeneruj nowy kod
          </h1>
          <Button
            position="center"
            label="Wygeneruj nowy kod"
            className="btn--normal"
            onClick={() => openLoginForm()}
          />
        </div>
      </div>
    </PageContainer>
  );
};
export default InactiveAccountPage;
