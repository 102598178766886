import React from 'react';
import * as Connector from 'shared/connectors/deliveryConnector';
import { DELIVERY } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE, NOTIFY_LABEL } from 'shared/consts';

export const success = (uuid, data) => ({
  type: DELIVERY.EDIT_DELIVERY_SUCCESS,
  payload: {
    uuid,
    data,
  },
});

const editDelivery = (uuid, form) => async dispatch => {
  try {
    const { data } = await Connector.editDelivery(uuid, { name: form.name, price: form.price });
    console.log(data);
    toast(<Notify label={NOTIFY_LABEL.DELIVERY_EDIT} type={NOTIFY_TYPE.DELIVERY} color="green" />);
    dispatch(success(uuid, data));
    return data;
  } catch (err) {
    return err.response;
  }
};

export default editDelivery;
