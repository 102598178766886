import { VIEW_MANAGEMENT } from "store/types";

const getDarkMode = JSON.parse(localStorage.getItem("isDarkMode")) === true;

const INITIAL_STATE = {
  isBlurred: false,
  isModalOpened: false,
  isMenuOpened: false,
  isDarkMode: getDarkMode || false,
};

const viewManagementReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case VIEW_MANAGEMENT.BLURRED_ON:
      return {
        ...state,
        isBlurred: true,
      };
    case VIEW_MANAGEMENT.BLURRED_OFF:
      return {
        ...state,
        isBlurred: false,
      };
    case VIEW_MANAGEMENT.OPEN_MODAL:
      return {
        ...state,
        isModalOpened: true,
      };
    case VIEW_MANAGEMENT.CLOSE_MODAL:
      return {
        ...state,
        isModalOpened: false,
      };
    case VIEW_MANAGEMENT.OPEN_MENU:
      return {
        ...state,
        isMenuOpened: true,
      };
    case VIEW_MANAGEMENT.CLOSE_MENU:
      return {
        ...state,
        isMenuOpened: false,
      };
    case VIEW_MANAGEMENT.DARK_MODE_ON:
      return {
        ...state,
        isDarkMode: true,
      };
    case VIEW_MANAGEMENT.DARK_MODE_OFF:
      return {
        ...state,
        isDarkMode: false,
      };
    default:
      return state;
  }
};

export default viewManagementReducer;
