/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Formik, Field } from "formik";
import {
  Button,
  RegularField,
  SelectField,
  Checkbox,
  DropFileInput,
} from "shared/components";
import { VALIDATION_MESSAGES } from "shared/consts";
import { object, string } from "yup";
import { categoriesOptions } from "screens/add-product-page/utils";

const ForumAddQuestion = ({
  categories,
  getAllCategories,
  addForumQuestion,
}) => {
  useEffect(() => {
    categories.length >= 0 && getAllCategories();
  }, []);

  const handleOnSubmit = async (values) => {
    await addForumQuestion(values);
  };

  const validationSchema = () =>
    object().shape({
      title: string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_MESSAGES.EMPTY_SPACE)
        .max(100, VALIDATION_MESSAGES.MAX_100),
      question: string().required(VALIDATION_MESSAGES.REQUIRED),
      category_uuid: string().required(VALIDATION_MESSAGES.REQUIRED),
      agreement: string().required(VALIDATION_MESSAGES.REQUIRED),
      tag: string().required(VALIDATION_MESSAGES.REQUIRED),
    });

  const initialValues = {
    title: "",
    question: "",
    category_uuid: "",
    agreement: "",
    tag: "",
  };

  const allTags = [
    "Drewno",
    "Metal",
    "Narzędzia",
    "Projekty DIY",
    "Inspiracje",
    "Porady",
    "Podstawy Rzemiosła",
    "Inne rzemiosła",
    "Kolekcje",
    "Rzeźba",
    "Malarstwo",
    "Tkanina",
    "Witraż",
    "Zegarmistrzostwo",
    "Stolarstwo artystyczne",
    "Haft",
    "Kwiaty sztuczne",
    "Origami",
    "Wycinanie w papierze",
    "Rysunek",
    "Ceramika",
    "Rzeźba w glinie",
    "Mozaika",
    "Decoupage",
    "Wyroby z miedzi",
    "Lakierowanie",
    "Złotnictwo",
    "Kowalstwo",
    "Plastyka",
    "Koszykarstwo",
    "Druk 3D",
    "Tiffany glass",
    "Wyroby ze skóry",
    "Modelarstwo",
    "Kształcenie",
    "Szycie",
    "Rzemiosło skórzane",
    "Rzemiosło drewniane",
    "Szkło artystyczne",
    "Wyroby z betonu",
    "Zabawki DIY",
    "Wytwarzanie biżuterii",
    "Stolarstwo meblowe",
    "Keramika artystyczna",
    "Rzemiosło włókiennicze",
    "Witrażownictwo",
    "Meble antyczne",
    "Sztuka ludowa",
    "Ozdoby świąteczne",
    "Rzemiosło ceramiczne",
    "Ciesielstwo",
    "Rzemiosło artystyczne",
    "Haftowanie",
    "Pracownie artystyczne",
    "Narzędzia rzemieślnicze",
    "Szkło artystyczne",
    "Cukiernictwo artystyczne",
    "Wyroby z wikliny",
    "Kursy rzemiosła",
    "Restauracja mebli",
    "Sztalugi malarskie",
    "Rękodzieło skórzane",
    "Pracownie rzemieślnicze",
    "Klasy artystyczne",
    "Zegarownictwo",
    "Kursy witrażownictwa",
    "Rzemiosło artystyczne",
    "Pozłacanie",
    "Rzemiosło papierowe",
    "Rzemiosło ceramiczne",
    "Kursy malarstwa",
    "Rzemiosło metalowe",
    "Wyroby ze srebra",
    "Szkło witrażowe",
    "Rzemiosło tekstylne",
    "Kwiaty sztuczne",
    "Rzemiosło kamieniarskie",
    "Szlifowanie szkła",
    "Kowalstwo artystyczne",
    "Rzemiosło artystyczne",
    "Rzemiosło ślusarskie",
    "Ceramika artystyczna",
    "Szkło artystyczne",
    "Rzemiosło gliniane",
    "Złotnictwo artystyczne",
    "Rzemiosło złotnicze",
    "Kowalstwo artystyczne",
    "Sztuka piękna",
    "Kwiaty artystyczne",
    "Witraże artystyczne",
    "Rzemiosło plastyczne",
    "Malarstwo na płótnie",
    "Meble artystyczne",
    "Rzemiosło ceramiczne",
    "Kursy rękodzieła",
    "Rzemiosło włókiennicze",
    "Kursy tkactwa",
    "Kursy haftowania",
    "Rzemiosło hafciarskie",
    "Rzemiosło krawieckie",
    "Kursy garncarstwa",
    "Szkło artystyczne",
    "Rzemiosło szklarskie",
    "Kursy ceramiki",
    "Rzemiosło ceramiczne",
    "Kursy złotnictwa",
    "Kursy kowalstwa",
    "Kursy rzeźbiarstwa",
    "Kursy malarstwa",
    "Kursy tkactwa",
    "Kursy garncarstwa",
    "Kursy ceramiki",
    "Kursy rzemiosła skórzanego",
    "Kursy haftowania",
    "Kursy krawiectwa",
  ];

  const transformedTags = allTags.map((tag) => ({
    value: tag,
    label: tag,
    searchableLabel: true,
  }));

  return (
    <>
      <div class="forum-page__header">Zadaj pytanie</div>
      <div className="forum-add-question">
        <Formik
          onSubmit={handleOnSubmit}
          validationSchema={validationSchema()}
          initialValues={initialValues}
          enableReinitialize
        >
          {({
            handleSubmit,
            setFieldValue,
            errors,
            setFieldTouched,
            validateField,
          }) => {
            const handleOnBlur = (name) => {
              setFieldTouched(name);
            };
            return (
              <>
                <form className="" onSubmit={handleSubmit}>
                  <div className="">
                    <Field
                      component={RegularField}
                      errors={errors}
                      label="Tytuł*"
                      name="title"
                      placeholder="Wprowadź krótki, zwięzły tytuł pytania."
                      type="text"
                      customBorder="customize-field"
                      onBlur={() => handleOnBlur("title")}
                    />
                    <Field
                      className="addProductForm__select"
                      component={SelectField}
                      placeholder="wybierz"
                      label="Kategoria*"
                      options={categoriesOptions(categories)}
                      errors={errors}
                      name="category_uuid"
                      onBlur={() => handleOnBlur("category_uuid")}
                    />
                    <Field
                      component={RegularField}
                      errors={errors}
                      label="Treść pytania*"
                      name="question"
                      placeholder="Napisz tutaj swoje pytanie "
                      type="textarea"
                      customBorder="customize-field"
                      onBlur={() => handleOnBlur("question")}
                    />
                    <Field
                      label="Załączniki"
                      name="file"
                      placeholder="Przeciągnij i upuść lub"
                      required
                      component={DropFileInput}
                      errors={errors}
                      onFileDrop={() => null}
                      accept="image/*"
                    />
                    <Field
                      searchableLabel
                      label="Tagi*"
                      component={SelectField}
                      placeholder="Wybierz tagi"
                      options={transformedTags}
                      errors={errors}
                      multi
                      name="tag"
                      // onChange={(e) => setEventType(e.value)}
                      onBlur={() => handleOnBlur("tag")}
                    />
                    <Field
                      type="checkbox"
                      label={`Zapoznałem się i akceptuje Regulamin forum`}
                      name="agreement"
                      errors={errors}
                      component={Checkbox}
                    />
                    <Button
                      label="zadaj pytanie"
                      className="add-event-form__submit btn--frame-dark"
                      type="submit"
                    />
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default ForumAddQuestion;
