import Background from "assets/files/build.jpg";
import { Field, Formik } from "formik";
import React from "react";
import { RegularField } from "shared/components";
import { SVG_TYPE } from "shared/consts";

const UnderConstruction = ({ unlock }) => {
  const submitForm = (values) => {
    if (values.password === "7153971539") {
      unlock();
      sessionStorage.setItem("unlock", "true");
    }
  };

  return (
    <div className="page__build">
      <img className="page__build--background" src={Background} alt="" />
      <div className="page__build--header">
        <h1>Serwis w budowie</h1>
        <h2>zapraszamy wkrótce</h2>
      </div>
      <Formik initialValues={{ password: "" }} onSubmit={submitForm}>
        {({ handleSubmit, errors }) => (
          <>
            <form onSubmit={handleSubmit}>
              <Field
                component={RegularField}
                errors={errors}
                icon={SVG_TYPE.LOCK}
                label="hasło"
                name="password"
                placeholder="Hasło"
                type="password"
                fullWidth
              />
            </form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default UnderConstruction;
