import { connect } from 'react-redux';
import * as ViewManagementActions from 'store/actions/viewManagementActions';
import { getIsModalOpened } from 'store/selectors/viewManagementSelector';
import { isMobile as checkIsMobile } from 'shared/utils';
import {
  createCategory,
  getAllCategoriesAndSubcategories,
  removeCategory,
  createSubcategory,
  removeSubcategory,
  editCategory,
  editSubcategory,
} from 'store/actions/category';
import { bindActionCreators } from 'redux';
import { getCategoriesAndSubcategories } from 'store/selectors/categorySelector';
import CategoryPage from './CategoryPage.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      createCategory: data => createCategory(data),
      createSubcategory: data => createSubcategory(data),
      removeCategory: uuid => removeCategory(uuid),
      editCategory: (uuid, form) => editCategory(uuid, form),
      editSubcategory: (uuid, form) => editSubcategory(uuid, form),
      removeSubcategory: (uuid, categoryUuid) => removeSubcategory(uuid, categoryUuid),
      getAllCategoriesAndSubcategories: () => getAllCategoriesAndSubcategories(),
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
    },
    dispatch,
  ),
});

function mapStateToProps(state) {
  return {
    categoriesAndSubcategories: getCategoriesAndSubcategories(state),
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage);
