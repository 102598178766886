import React, { useState } from 'react';
import { Field } from 'formik';
import { RegularField } from 'shared/components';
import { array, func } from 'prop-types';

const ProductDescript = ({ errors, setFieldTouched, setFieldValue }) => {
  const [characterCount, setCharacterCount] = useState(false);

  const descriptLength = e => {
    const descriptBox = document.getElementsByClassName('addProductForm--description');
    const textarea = descriptBox[0].getElementsByTagName('textarea');
    setFieldValue('description', e.target.value);
    setCharacterCount(textarea[0].textLength);
  };

  const onTouched = () => {
    setFieldTouched('description');

    characterCount();
  };

  return (
    <div className="addProductForm--description">
      <Field
        component={RegularField}
        errors={errors}
        label="Opis*"
        name="description"
        placeholder="Napisz coś o produkcie który chcesz sprzedać"
        type="textarea"
        customBorder="customize-field"
        inputOnChange={e => descriptLength(e)}
        onBlur={() => onTouched()}
      />
    </div>
  );
};
export default ProductDescript;

ProductDescript.propTypes = {
  errors: array.isRequired,
  setFieldTouched: func.isRequired,
  setFieldValue: func.isRequired,
};
