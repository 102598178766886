/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { func, shape, array } from "prop-types";
import { Top, AdvertisementsList, MonthlySales } from "./components";
// import { MENU_TYPES } from "./components/consts";
import { DashboardContent } from "screens/dashboard/components";
import { useLocation } from "react-router-dom";

const MyAdvertisementsPage = ({ myProducts, actions: { getMyProducts } }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const type = queryParams.get("type");
  const [menuTypes, setMenuTypes] = useState(type);

  useEffect(async () => {
    myProducts.length <= 0 && (await getMyProducts({ status: menuTypes }));
  }, []);

  return (
    <>
      <DashboardContent goBack name="Moje produkty">
        <div className="advertisements-page">
          <div className="advertisements-left-side">
            <Top menuTypes={menuTypes} setMenuTypes={setMenuTypes} />
            <AdvertisementsList myProducts={myProducts} />
          </div>
          <div className="advertisements-right-side">
            <MonthlySales />
          </div>
        </div>
      </DashboardContent>
    </>
  );
};

export default MyAdvertisementsPage;

MyAdvertisementsPage.propTypes = {
  actions: shape({
    getMyProducts: func.isRequired,
  }).isRequired,
  myProducts: array.isRequired,
};
