/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { func, shape } from "prop-types";
import { DeliveryList } from "./components";
import { DashboardContent } from "screens/dashboard/components";

const DELIVERY_MANAGE_TYPE = {
  ADD: "ADD",
};

const DeliveryPage = ({
  actions: { addDelivery, getDeliveries, removeDelivery, editDelivery },
  deliveries,
}) => {
  const [deliveryManage, setDeliveryManage] = useState(false);
  const isAddDelivery = deliveryManage.type === DELIVERY_MANAGE_TYPE.ADD;

  useEffect(async () => {
    await getDeliveries();
  }, []);

  const addNewDelivery = () => {
    setDeliveryManage({ type: DELIVERY_MANAGE_TYPE.ADD });
  };

  const deleteDelivery = (uuid) => {
    removeDelivery(uuid);
  };

  return (
    <>
      {/* <Top /> */}
      <DashboardContent goBack name="Metody dostawy">
        {/* <DashboardSectionHeading label="Metody " /> */}
        {/* <Top
            label="Metody dostawy"
            type="dodaj metodę dostawy"
            onClick={addNewDelivery}
          /> */}
        <DeliveryList
          addNewDelivery={addNewDelivery}
          editDelivery={editDelivery}
          deliveries={deliveries}
          addDelivery={addDelivery}
          setDeliveryManage={setDeliveryManage}
          isAddDelivery={isAddDelivery}
          deleteDelivery={deleteDelivery}
          deliveryManage={deliveryManage}
        />
      </DashboardContent>
    </>
  );
};
export default DeliveryPage;

DeliveryPage.propTypes = {
  actions: shape({
    addDelivery: func.isRequired,
    editDelivery: func.isRequired,
    getDeliveries: func.isRequired,
    removeDelivery: func.isRequired,
  }).isRequired,
  deliveries: func.isRequired,
};
