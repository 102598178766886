import * as Connector from 'shared/connectors/categoryConnector';
import { CATEGORY } from 'store/types';

const init = () => ({
  type: CATEGORY.GET_ALL_CATEGORIES_AND_SUBCATEGORIES_INIT,
});

export const success = data => ({
  type: CATEGORY.GET_ALL_CATEGORIES_AND_SUBCATEGORIES_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: CATEGORY.GET_ALL_CATEGORIES_AND_SUBCATEGORIES_FAILURE,
});

const getAllCategoriesAndSubcategories = () => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.getAllCategoriesAndSubcategories();
    dispatch(success(data.category));
    return data;
  } catch (err) {
    dispatch(failure());
    return err.response.data;
  }
};

export default getAllCategoriesAndSubcategories;
