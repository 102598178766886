import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { SVG_TYPE, USER_ROLE } from "shared/consts";
import { SVG, Modal, ModalTitle, Button } from "shared/components";

const ForumTags = ({ authorizedUser }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isAdmin = authorizedUser?.role === USER_ROLE.ADMIN;
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const allTags = [
    "Drewno",
    "Metal",
    "Narzędzia",
    "Projekty DIY",
    "Inspiracje",
    "Porady",
    "Podstawy Rzemiosła",
    "Inne rzemiosła",
    "Kolekcje",
    "Rzeźba",
    "Malarstwo",
    "Tkanina",
    "Witraż",
    "Zegarmistrzostwo",
    "Stolarstwo artystyczne",
    "Haft",
    "Kwiaty sztuczne",
    "Origami",
    "Wycinanie w papierze",
    "Rysunek",
    "Ceramika",
    "Rzeźba w glinie",
    "Mozaika",
    "Decoupage",
    "Wyroby z miedzi",
    "Lakierowanie",
    "Złotnictwo",
    "Kowalstwo",
    "Plastyka",
    "Koszykarstwo",
    "Druk 3D",
    "Tiffany glass",
    "Wyroby ze skóry",
    "Modelarstwo",
    "Kształcenie",
    "Szycie",
    "Rzemiosło skórzane",
    "Rzemiosło drewniane",
    "Szkło artystyczne",
    "Wyroby z betonu",
    "Zabawki DIY",
    "Wytwarzanie biżuterii",
    "Stolarstwo meblowe",
    "Keramika artystyczna",
    "Rzemiosło włókiennicze",
    "Witrażownictwo",
    "Meble antyczne",
    "Sztuka ludowa",
    "Ozdoby świąteczne",
    "Rzemiosło ceramiczne",
    "Ciesielstwo",
    "Rzemiosło artystyczne",
    "Haftowanie",
    "Pracownie artystyczne",
    "Narzędzia rzemieślnicze",
    "Szkło artystyczne",
    "Cukiernictwo artystyczne",
    "Wyroby z wikliny",
    "Kursy rzemiosła",
    "Restauracja mebli",
    "Sztalugi malarskie",
    "Rękodzieło skórzane",
    "Pracownie rzemieślnicze",
    "Klasy artystyczne",
    "Zegarownictwo",
    "Kursy witrażownictwa",
    "Rzemiosło artystyczne",
    "Pozłacanie",
    "Rzemiosło papierowe",
    "Rzemiosło ceramiczne",
    "Kursy malarstwa",
    "Rzemiosło metalowe",
    "Wyroby ze srebra",
    "Szkło witrażowe",
    "Rzemiosło tekstylne",
    "Kwiaty sztuczne",
    "Rzemiosło kamieniarskie",
    "Szlifowanie szkła",
    "Kowalstwo artystyczne",
    "Rzemiosło artystyczne",
    "Rzemiosło ślusarskie",
    "Ceramika artystyczna",
    "Szkło artystyczne",
    "Rzemiosło gliniane",
    "Złotnictwo artystyczne",
    "Rzemiosło złotnicze",
    "Kowalstwo artystyczne",
    "Sztuka piękna",
    "Kwiaty artystyczne",
    "Witraże artystyczne",
    "Rzemiosło plastyczne",
    "Malarstwo na płótnie",
    "Meble artystyczne",
    "Rzemiosło ceramiczne",
    "Kursy rękodzieła",
    "Rzemiosło włókiennicze",
    "Kursy tkactwa",
    "Kursy haftowania",
    "Rzemiosło hafciarskie",
    "Rzemiosło krawieckie",
    "Kursy garncarstwa",
    "Szkło artystyczne",
    "Rzemiosło szklarskie",
    "Kursy ceramiki",
    "Rzemiosło ceramiczne",
    "Kursy złotnictwa",
    "Kursy kowalstwa",
    "Kursy rzeźbiarstwa",
    "Kursy malarstwa",
    "Kursy tkactwa",
    "Kursy garncarstwa",
    "Kursy ceramiki",
    "Kursy rzemiosła skórzanego",
    "Kursy haftowania",
    "Kursy krawiectwa",
  ];

  const [visibleTagsCount, setVisibleTagsCount] = useState(10);
  const visibleTags = allTags.slice(0, visibleTagsCount);

  const activeTags = searchParams.getAll("tag");
  const tagClickHandler = (tag) => {
    if (activeTags.includes(tag)) {
      activeTags.splice(activeTags.indexOf(tag), 1);
    } else {
      activeTags.push(tag);
    }
    searchParams.delete("tag");
    activeTags.forEach((activeTag) => {
      searchParams.append("tag", activeTag);
    });
    history.push({ search: searchParams.toString() });
  };

  const showMoreTags = () => {
    setVisibleTagsCount((prevCount) => prevCount + 10);
  };

  return (
    <>
      <Modal className="tags" opened={isModalOpen}>
        <ModalTitle title="Zarządzanie tagami" />
        <div className="forum-tags__content ">
          <div
            className="forum-tags__content-close"
            onClick={() => setIsModalOpen(false)}
          >
            <SVG type={SVG_TYPE.CLOSE} />
          </div>
          {allTags.map((tag) => (
            <div key={tag} className="forum-tag forum-tag--noclick">
              {tag}
              <div className="forum-tag-actions">
                <span>
                  <SVG type={SVG_TYPE.EDIT} />
                </span>
                <span>
                  <SVG type={SVG_TYPE.REMOVE} />
                </span>
              </div>
            </div>
          ))}
        </div>
      </Modal>
      <div className="forum-tags">
        <div className="forum-page__header forum-page__header-icon">
          Tagi
          {isAdmin && (
            <span
              className="forum-page__header--icon"
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              <SVG
                className="forum-page__header--icon"
                type={SVG_TYPE.ADD_TAGS}
              />
            </span>
          )}
        </div>
        <div className="forum-tags__content">
          {visibleTags.map((tag) => (
            <div
              key={tag}
              className={`forum-tag ${
                activeTags.includes(tag) ? "forum-tag--active" : ""
              }`}
              onClick={() => tagClickHandler(tag)}
            >
              {tag}
            </div>
          ))}
        </div>
        {visibleTagsCount < allTags.length && (
          <div className="forum-tags__more">
            <Button
              label="pokaż więcej"
              className="btn--frame-dark"
              onClick={showMoreTags}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ForumTags;
