import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';

const init = () => ({
  type: USER.ACTIVATE_ACCOUNT_INIT,
});

export const success = () => ({
  type: USER.ACTIVATE_ACCOUNT_SUCCESS,
});

const failure = () => ({
  type: USER.ACTIVATE_ACCOUNT_FAILURE,
});

// eslint-disable-next-line consistent-return
const activateAccount = userData => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.activateAccount(userData);
    dispatch(success());
    sessionStorage.removeItem('activateToken');
    return data;
  } catch (err) {
    console.log(err);
    dispatch(failure());
  }
};

export default activateAccount;
