import { SVG } from "shared/components";
import { Pagination } from "shared/components/table/components";
import { SVG_TYPE } from "shared/consts";
import Face from "assets/files/face.jpg";

const threadsData = [
  {
    userAvatar: Face,
    username: "User1",
    text: "Jak wykonać piękne rzeźby drewniane? Potrzebuję pomocy w wyborze narzędzi i technik.",
    craft: "Stolarstwo",
    time: "20 minut temu",
    commentsCount: 50,
    viewsCount: 100,
  },
  {
    userAvatar: Face,
    username: "User2",
    text: "Poszukuję porad dotyczących naprawy mebli antycznych. Które kleje i farby są najlepsze?",
    craft: "Stolarstwo",
    time: "1 godzinę temu",
    commentsCount: 30,
    viewsCount: 80,
  },
  {
    userAvatar: Face,
    username: "User3",
    text: "Potrzebuję inspiracji do projektu DIY. Może ktoś podzielić się swoimi pomysłami?",
    craft: "DIY",
    time: "2 godziny temu",
    commentsCount: 20,
    viewsCount: 60,
  },
  {
    userAvatar: Face,
    username: "User4",
    text: "Słyszałem, że narzędzia firmy XYZ są najlepsze do prac metalowych. Czy ktoś może to potwierdzić?",
    craft: "Metalurgia",
    time: "3 godziny temu",
    commentsCount: 15,
    viewsCount: 50,
  },
  {
    userAvatar: Face,
    username: "User5",
    text: "Jakie są najlepsze techniki malowania na płótnie? Chciałbym rozpocząć malowanie akwarelami.",
    craft: "Malarstwo",
    time: "4 godziny temu",
    commentsCount: 25,
    viewsCount: 70,
  },
  {
    userAvatar: Face,
    username: "User6",
    text: "Potrzebuję pomocy w wyborze materiałów do projektu z papieru. Co polecacie?",
    craft: "Papieroplastyka",
    time: "5 godzin temu",
    commentsCount: 10,
    viewsCount: 40,
  },
  {
    userAvatar: Face,
    username: "User7",
    text: "Gdzie można znaleźć dobre kursy rzemieślnicze w mojej okolicy? Czy ktoś ma dobre źródła?",
    craft: "Różne",
    time: "6 godzin temu",
    commentsCount: 8,
    viewsCount: 35,
  },
  {
    userAvatar: Face,
    username: "User8",
    text: "Szukam instrukcji do budowy własnego stołu ogrodowego. Ktoś może mi pomóc?",
    craft: "Stolarstwo",
    time: "7 godzin temu",
    commentsCount: 12,
    viewsCount: 55,
  },
  {
    userAvatar: Face,
    username: "User9",
    text: "Czy ktoś ma doświadczenie w tworzeniu biżuterii ze złota? Chciałbym zacząć tworzyć własne projekty.",
    craft: "Złotnictwo",
    time: "8 godzin temu",
    commentsCount: 18,
    viewsCount: 65,
  },
  {
    userAvatar: Face,
    username: "User10",
    text: "Potrzebuję porady dotyczącej wyboru odpowiednich farb do malowania ceramiki. Co polecacie?",
    craft: "Ceramika",
    time: "9 godzin temu",
    commentsCount: 14,
    viewsCount: 48,
  },
];

const ForumList = ({ forumQuestions }) => {
  return (
    <div className="forum-center--normal">
      <div className="forum-list">
        {threadsData.slice(0, 1).map((thread, index) => (
          <div key={index} className="forum-list-item">
            <div className="forum-list-item__user">
              <img
                className="forum-list-item__user__avatar"
                src={thread.userAvatar}
                alt=""
              />
            </div>
            <div className="forum-list-item__center">
              <div className="forum-list-item__center-text">{thread.text}</div>
              <div className="forum-list-item__center-bottom">
                <div className="forum-list-item__craft">{thread.craft}</div>
                <div className="forum-list-item__time">
                  <SVG type={SVG_TYPE.TIME} /> {thread.time}
                </div>
              </div>
            </div>
            <div className="forum-list-item__right">
              <div className="forum-list-item__comments">
                <SVG type={SVG_TYPE.FORUM_ICON} />
                {thread.commentsCount}
              </div>
              <div className="forum-list-item__views">
                <SVG type={SVG_TYPE.EYE} />
                {thread.viewsCount}
              </div>
            </div>
          </div>
        ))}
        {forumQuestions.length > 0 &&
          forumQuestions.map((thread, index) => (
            <div key={index} className="forum-list-item">
              <div className="forum-list-item__user">
                <img
                  className="forum-list-item__user__avatar"
                  src={thread.userAvatar}
                  alt=""
                />
              </div>
              <div className="forum-list-item__center">
                <div className="forum-list-item__center-text">
                  {thread.title}
                </div>
                <div className="forum-list-item__center-bottom">
                  <div className="forum-list-item__craft">{thread.craft}</div>
                  <div className="forum-list-item__time">
                    <SVG type={SVG_TYPE.TIME} /> {thread.time}
                  </div>
                </div>
              </div>
              <div className="forum-list-item__right">
                <div className="forum-list-item__comments">
                  <SVG type={SVG_TYPE.FORUM_ICON} />
                  {thread.commentsCount}
                </div>
                <div className="forum-list-item__views">
                  <SVG type={SVG_TYPE.EYE} />
                  {thread.viewsCount}
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="forum-center-pagination">
        <Pagination page={1} pages="4" />
      </div>
    </div>
  );
};
export default ForumList;
