import React, { useState } from "react";
import { Formik } from "formik";
import { array, object, string } from "yup";
import {
  TitleAndCategories,
  ProductDescript,
  ProductDeliveryField,
} from "screens/add-product-page/components";
import PropTypes from "prop-types";
import { VALIDATION_MESSAGES } from "shared/consts";
import { Button } from "shared/components";
import AddPhotosField from "../add-photos-field";

const AddProductForm = ({
  categories,
  getSingleCategory,
  singleCategory,
  deliveries,
  addProduct,
  addFiles,
}) => {
  const [deliveryArr, setDeliveryArr] = useState([]);

  const handleOnSubmit = async (values) => {
    const { product } = await addProduct(values);
    if (product.id) {
      values.photos.forEach(async (file) => {
        const fileForm = new FormData();
        fileForm.append("file", file.file);
        await addFiles(fileForm, {
          product_id: product.id,
          fileFormat: file.type,
        });
      });
    }
  };

  const validationSchema = () =>
    object().shape({
      amount: string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .matches(/^\d{0,100}?$/, VALIDATION_MESSAGES.ONLY_NUMBER)
        .matches(/^\d{0,6}?$/, VALIDATION_MESSAGES.MAX_6)
        .min(1, "")
        .test(
          "no-leading-zero",
          VALIDATION_MESSAGES.FIRST_NO_ZERO,
          (value, context) =>
            context?.originalValue && !context?.originalValue?.startsWith("0")
        )
        .matches(/^\d+$/, VALIDATION_MESSAGES.REQUIRED),
      category_uuid: string().required(VALIDATION_MESSAGES.REQUIRED),
      deliveries: array().required(VALIDATION_MESSAGES.REQUIRED),
      description: string()
        .matches(/^[^\s].*/, VALIDATION_MESSAGES.EMPTY_SPACE)
        .min(80, VALIDATION_MESSAGES.REQUIRED)
        .max(6000, VALIDATION_MESSAGES.MAX_6000)
        .required(VALIDATION_MESSAGES.REQUIRED),
      location: string().required(VALIDATION_MESSAGES.REQUIRED),
      photos: array().required(VALIDATION_MESSAGES.REQUIRED),
      price: string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .matches(
          /^\d{0,100}(\.\d{0,99}||,\d{0,99})?$/,
          VALIDATION_MESSAGES.ONLY_NUMBER
        )
        .matches(/^\d{0,6}(\.\d{0,99}||,\d{0,99})?$/, VALIDATION_MESSAGES.MAX_6)
        .matches(
          /^[0-9][0-9]*[(.|,)]?[0-9]{0,2}$/,
          VALIDATION_MESSAGES.DECIMAL_2
        )
        .matches(/^[0-9,.]*$/, " ")
        .min(1, "")
        .test(
          "no-leading-zero",
          VALIDATION_MESSAGES.FIRST_NO_ZERO,
          (value, context) =>
            context.originalValue && !context.originalValue.startsWith("0")
        ),
      subcategory_uuid: string().required(VALIDATION_MESSAGES.REQUIRED),
      title: string()
        .required(VALIDATION_MESSAGES.REQUIRED)
        .matches(/^[^\s].*/, VALIDATION_MESSAGES.EMPTY_SPACE)
        .max(100, VALIDATION_MESSAGES.MAX_100),
    });

  // const initialValues = {
  //   title: '',
  //   category_uuid: '',
  //   description: '',
  //   subcategory_uuid: '',
  //   price: '',
  //   amount: '',
  //   deliveries: '',
  //   location: '',
  //   // photos: '',
  // };

  const initialValues = {
    title: "Nazwa produktu",
    category_uuid: "9b1d1ccb-a678-44a9-85c0-5d980083fa36",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    subcategory_uuid: "f271357b-e136-40bd-8e70-57be00b16f30",
    price: "40",
    amount: "1",
    deliveries: [
      {
        id: 29,
        uuid: "ebb24a27-6f2a-414c-8a2c-cd3a6f9a077b",
        name: "Inpost paczkomat",
        price: "9",
        created_at: "2022-11-11T11:47:49.000+01:00",
        updated_at: "2022-11-11T11:47:49.000+01:00",
      },
      {
        id: 30,
        uuid: "0dabf486-2a52-42a0-b69b-1e77b86e2666",
        name: "Inpost pobranie",
        price: "13",
        created_at: "2022-11-12T22:31:05.000+01:00",
        updated_at: "2022-11-12T22:31:05.000+01:00",
      },
    ],
    location: "Ełk",
    photos: "",
  };

  return (
    <div className="addProductForm">
      {/* <div className="addProductForm__back--wrapper" onClick={() => history.push(ROUTES.MY_ADVERTISEMENTS)}>
        <div className="addProductForm__back--icon">
          <SVG type={SVG_TYPE.CHEVRONE} />
        </div>
        <div className="addProductForm__back--label">wróć</div>
      </div> */}
      <Formik
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema()}
        initialValues={initialValues}
        enableReinitialize
      >
        {({
          handleSubmit,
          setFieldValue,
          errors,
          setFieldTouched,
          validateField,
        }) => (
          <form className="addProductForm__wrapper" onSubmit={handleSubmit}>
            <div className="addProductForm__box addProductForm__box--list">
              <TitleAndCategories
                errors={errors}
                setFieldValue={setFieldValue}
                singleCategory={singleCategory}
                getSingleCategory={getSingleCategory}
                categories={categories}
                setFieldTouched={setFieldTouched}
                initialValues={initialValues}
              />
              <AddPhotosField
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errors={errors}
              />
              <ProductDescript
                validateField={validateField}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errors={errors}
              />
              <ProductDeliveryField
                deliveryArr={deliveryArr}
                setDeliveryArr={setDeliveryArr}
                setFieldValue={setFieldValue}
                errors={errors}
                deliveries={deliveries}
                initialValues={initialValues}
              />
              <Button label="dodaj" className="btn--default" type="submit" />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default AddProductForm;

AddProductForm.propTypes = {
  addFiles: PropTypes.func.isRequired,
  addProduct: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  deliveries: PropTypes.array.isRequired,
  getSingleCategory: PropTypes.func.isRequired,
  singleCategory: PropTypes.array.isRequired,
};
