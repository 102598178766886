/* eslint-disable react/prop-types */
import React from 'react';
import { SettingsDots } from 'shared/components';
import { bool, func } from 'prop-types';
import { USER_ROLE } from 'shared/consts';
import { Avatar } from '../../components';

const getCraftsmanColumns = (isOpen, setIsOpen) => [
  {
    width: 175,
    Cell: ({ original }) => <Avatar type={USER_ROLE.CRAFTSMAN} data={original} />,
  },
  {
    id: 'update_at',
    accessor: 'update_at',
    width: 100,
  },
  {
    id: 'last_login',
    accessor: 'last_login',
    width: 100,
  },
  {
    width: 160,
    Cell: ({ original }) => <SettingsDots data={original} isOpen={isOpen} setIsOpen={setIsOpen} />,
    className: 'settings-dots',
  },
];

export default getCraftsmanColumns;

getCraftsmanColumns.propTypes = {
  isOpen: bool.isRequired,
  setIsOpen: func.isRequired,
};
