import { connect } from 'react-redux';
import * as ViewManagementActions from 'store/actions/viewManagementActions';
import { getIsModalOpened } from 'store/selectors/viewManagementSelector';
import { isMobile as checkIsMobile } from 'shared/utils';
import { bindActionCreators } from 'redux';
import { getUsers } from 'store/actions/user';
import { getUsers as getUsersSelector } from 'store/selectors/userSelector';
import UsersPage from './UsersPage.main';

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      getUsers: params => getUsers(params),
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
    },
    dispatch,
  ),
});

function mapStateToProps(state) {
  return {
    users: getUsersSelector(state),
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
