const PRODUCT = {
  ADD_PRODUCT_INIT: 'ADD_PRODUCT_INIT',
  ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
  ADD_PRODUCT_FAILURE: 'ADD_PRODUCT_FAILURE',
  GET_MY_PRODUCTS_INIT: 'GET_MY_PRODUCTS_INIT',
  GET_MY_PRODUCTS_SUCCESS: 'GET_MY_PRODUCTS_SUCCESS',
  GET_MY_PRODUCTS_FAILURE: 'GET_MY_PRODUCTS_FAILURE',
};

export default PRODUCT;
