import React from 'react';
import * as Connector from 'shared/connectors/userConnector';
import { USER } from 'store/types';
import { toast } from 'react-toastify';
import { Notify } from 'shared/components';
import { NOTIFY_TYPE } from 'shared/consts';

const init = () => ({
  type: USER.SET_NEW_PASSWORD_INIT,
});

export const success = () => ({
  type: USER.SET_NEW_PASSWORD_SUCCESS,
});

const failure = () => ({
  type: USER.SET_NEW_PASSWORD_FAILURE,
});

const setNewPassword = dataForm => async dispatch => {
  dispatch(init());
  try {
    const { data } = await Connector.setNewPassword(dataForm);
    dispatch(success());
    toast(<Notify label="Hasło zmienione" type={NOTIFY_TYPE.USER} color="green" />);
    sessionStorage.removeItem('resetToken');
    sessionStorage.removeItem('emailReset');
    return data;
  } catch (err) {
    dispatch(failure());
    return err;
  }
};

export default setNewPassword;
