import { useState } from "react";
// import { SVG } from "shared/components";
import { ROUTES } from "shared/consts";
// import { ROUTES, USER_ROLE } from "shared/consts";
// import { SVG_TYPE, ROUTES, USER_ROLE, EVENT_STATUS } from "shared/consts";
// import { DashboardSectionHeading } from "screens/dashboard/components";
import { useHistory } from "react-router-dom";
import { EventsTable } from "../components/";
import { SUBPAGES } from "../consts";
import { Button } from "shared/components";

const MyEvents = ({ authorizedUser }) => {
  const [menuTypes, setMenuTypes] = useState("");
  // const [isFilterOpen, setIsFilterOpen] = useState(false);
  const history = useHistory();
  // const [isExpand, setIsExpand] = useState(false);
  // const isAdmin = authorizedUser?.role === USER_ROLE.ADMIN;
  // const adminClass = `${isAdmin ? 'events-table--hiden ' : ''} ${isAdmin && isExpand ? 'events-table--expand' : ''}`;

  const handleRedirect = (type) => {
    history.push(`${ROUTES.EVENT_MANAGE}?type=${type}`);
    setMenuTypes(type);
  };

  return (
    <>
      <div className="event-menu advertisement-menu">
        <div className="advertisement-menu__content">
          {/* <div className="advertisement-menu__title">Pokaż tylko: </div> */}
          <div className="advertisement-menu__list">
            {SUBPAGES.map(({ type, count, name, color }) => (
              <div
                className={`advertisement-menu__item ${
                  menuTypes === type ? "advertisement-menu__item--active" : ""
                }`}
                onClick={() => handleRedirect(type)}
                // style={`background-color: ${color};`}
              >
                <div className="advertisement-menu__item--name">{name}</div>
                <div className="advertisement-menu__item--count">{count}</div>
              </div>
            ))}
          </div>
          <Button
            className="btn--frame-dark"
            label="dodaj wydarzenie"
            onClick={() => history.push(ROUTES.ADD_EVENT)}
          />
        </div>
      </div>
      {/* <DashboardSectionHeading>
        <div className="label" onClick={()=> setIsExpand(!isExpand)}>Moje wydarzenia</div>
        <div className="counters">
          <div className="counter-wrapper">
            <div
              className="counter counter--fit"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <div className="counter-icon__wrapper">
                <SVG className="counter-icon" type={SVG_TYPE.FILTER} />
              </div>
              <div className="counter-label counter-label--icon">
                Filtry
              </div>
            </div>
          </div>
          <div className="counter-wrapper">
            <div
              className="counter counter--fit"
              onClick={() => history.push(ROUTES.ADD_EVENT)}
            >
              <div className="counter-icon__wrapper">
                <SVG className="counter-icon" type={SVG_TYPE.CALENDAR_ADD} />
              </div>
              <div className="counter-label counter-label--icon">
                Dodaj wydarzenie
              </div>
            </div>
          </div>
          
        </div>
      </DashboardSectionHeading> */}
      {/* <div className={`events-filter ${isFilterOpen ? 'events-filter--active' : ''}`}>
        <div className='filter'><div className='filter-color' /> <div className='filter__text'>{EVENT_STATUS.ACCEPTED}</div> </div>
        <div className='filter'><div className='filter-color' /> <div className='filter__text'>{EVENT_STATUS.WAITING} </div> </div>
        <div className='filter'><div className='filter-color' /> <div className='filter__text'>{EVENT_STATUS.ATTENTION} </div> </div>
      </div> */}
      {/* <div className={adminClass}> */}
      <EventsTable />
      {/* </div> */}
    </>
  );
};

export default MyEvents;
