/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { bool } from "prop-types";
// import { Products, Tiles } from './components';
import RzemiosloLato from "assets/files/craftsman.png";
import { DashboardContent } from "screens/dashboard/components";
import {
  CraftsNewUsers,
  CraftsCategory,
  CraftsArticles,
  CraftsEvents,
} from "./components";
import { useLocation } from "react-router-dom";
import { Button } from "shared/components";
import { DashboardSectionHeading } from "screens/dashboard/components";

const CraftsPage = ({
  isMobile,
  categoriesAndSubcategories,
  actions: { getAllCategoriesAndSubcategories },
}) => {
  useEffect(() => {
    !categoriesAndSubcategories && getAllCategoriesAndSubcategories();
  }, []);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const categoryLink = params.get("category");

  const categoryName =
    categoriesAndSubcategories && categoriesAndSubcategories.length > 0
      ? categoriesAndSubcategories.find((item) => item.link === categoryLink)
      : null;

  return (
    <>
      <DashboardContent
        name={`Rzemiosło ${categoryName?.name ? `- ${categoryName.name}` : ""}`}
      >
        <div className="crafts__background">
          <img src={RzemiosloLato} alt="background" />
          <CraftsNewUsers />
          <CraftsCategory
            categoriesAndSubcategories={categoriesAndSubcategories}
          />
          <CraftsEvents />
        </div>
        <DashboardSectionHeading margin>
          <div className="label">
            Artykuły {categoryName?.name ? `- ${categoryName.name}` : ""}
          </div>
          <Button label="Zobacz więcej" className="btn--frame-dark" />
        </DashboardSectionHeading>
        <CraftsArticles />
        {/* <Tiles /> */}
        {/* <Products isMobile={isMobile} /> */}
      </DashboardContent>
    </>
  );
};

export default CraftsPage;

CraftsPage.propTypes = {
  isMobile: bool.isRequired,
};
