import { connect } from "react-redux";
import * as ViewManagementActions from "store/actions/viewManagementActions";
import { getIsModalOpened } from "store/selectors/viewManagementSelector";
import { isMobile as checkIsMobile } from "shared/utils";
import { getAuthorizedUser as getAuthorizedUserSelector } from "store/selectors/userSelector";
import Dashboard from "./Dashboard.main";
import * as UserActions from "store/actions/user";
import { bindActionCreators } from "redux";

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAuthorizedUser: UserActions.getAuthorizedUser,
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
    },
    dispatch
  ),
});

function mapStateToProps(state) {
  return {
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
    authorizedUser: getAuthorizedUserSelector(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
