import React from "react";
import PropTypes from "prop-types";
import { SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";

const Pagination = ({ canNext, canPrevious, page, pages, onPageChange }) => {
  const currentPage = page + 1;
  const pagesArr = [];
  for (let step = 0; step < pages; step++) {
    pagesArr.push(step + 1);
  }
  const goBack = () => {
    onPageChange(page - 1);
  };
  const goNext = () => {
    onPageChange(currentPage);
  };

  return (
    <>
      {pagesArr.length > 1 && (
        <div className="pagination">
          <div
            className={`box button button--previous ${
              canPrevious ? "" : "button--disabled"
            }`}
            onClick={() => goBack()}
          >
            <SVG type={SVG_TYPE.CHEVRONE} />
          </div>
          {pagesArr.map((item) => (
            <div
              onClick={() => onPageChange(item - 1)}
              className={`box ${currentPage === item ? "box--active" : ""}`}
            >
              {item}
            </div>
          ))}
          <div
            className={`box button button--next ${
              canNext ? "" : "button--disabled"
            }`}
            onClick={() => goNext()}
          >
            <SVG type={SVG_TYPE.CHEVRONE} />
          </div>
        </div>
      )}
    </>
  );
};

export default Pagination;

Pagination.propTypes = {
  canNext: PropTypes.bool.isRequired,
  canPrevious: PropTypes.bool.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
};
