import Table from 'assets/files/products/table.jpg';
import Sculpture from 'assets/files/products/sculpture.jpg';
import Knife from 'assets/files/products/knife.jpg';
import KnifeBushcraft from 'assets/files/products/knife_bushcraft.jpg';

const ProductsElements = [
  {
    id: 1,
    path: Table,
    name: 'Stół z żywicy',
    price: '1800,00',
    is_favorie: false,
    uuid: '8712uyasdhiu127iy3hasudkas',
    nick: 'Patalczyki',
  },
  {
    id: 2,
    path: Sculpture,
    name: 'Rz eźba tw ar zy ma ni fent ure by Ka rina Mali now ski poziomy rozmowy ',
    price: '2000,00',
    is_favorie: true,
    uuid: 'asidji1827uhiasdgh78UI@Hdkuasj',
    nick: 'Krinsky',
  },
  {
    id: 3,
    path: Knife,
    name: 'Nóz kuchenny',
    price: '144,00',
    is_favorie: false,
    uuid: '1289731uiHDASUJKnkjnmzxHdkuasj',
    nick: 'GoodVibes',
  },
  {
    id: 4,
    path: KnifeBushcraft,
    name: 'Nóz prawdziwego bushcraftera',
    price: '209,00',
    is_favorie: false,
    uuid: 'asijdhuih7@i89jasdjbkzxnmcb',
    nick: 'Stolarz',
  },
  {
    id: 4,
    path: KnifeBushcraft,
    name: 'Nóz prawdziwego bushcraftera',
    price: '209,00',
    is_favorie: false,
    uuid: 'asijdhuih7@i89jasdjbkzxnmcb',
    nick: 'Stolarz',
  },
];

export default ProductsElements;
