import React from "react";
import Slider from "react-slick";
import { MyAccountBadge } from "shared/components";

const settings = {
  infinite: true,
  arrows: false,
  speed: 1000,
  autoplay: true,
  autoplaySpeed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const CraftsNewUsers = () => {
  return (
    <div className="crafts-new-user__wrapper">
      <div className="crafts-new-user__heading">Nowi rzemieślnicy</div>
      <div className="crafts-new-user">
        <Slider {...settings}>
          <div>
            <MyAccountBadge authorizedUser={{ user_name: "Jan Kowalski" }} />
          </div>
          <div>
            <MyAccountBadge
              authorizedUser={{ user_name: "Piotr Pietkiewicz" }}
            />
          </div>
          <div>
            <MyAccountBadge authorizedUser={{ user_name: "Mariusz Puszka" }} />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default CraftsNewUsers;
