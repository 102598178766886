import React from 'react';
import BasketItem from '../basket-item';
import ProductsElements from '../const';

const BasketItems = () => (
  <div className="basket--items">
    {ProductsElements.map(item => (
      <BasketItem data={item} />
    ))}
  </div>
);

export default BasketItems;
