import React from "react";
import { CurvesSection } from "shared/components";

const Crafts = () => (
  <CurvesSection className="home-page__crafts">
    <div className="home-page__crafts">
      <p className="heading heading--center heading--crafts">
        Sprawdź, co przygotowaliśmy dla danego rzemiosła
      </p>
    </div>
  </CurvesSection>
);

export default Crafts;
