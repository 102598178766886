import React from "react";
import { CurvesSection, Button } from "shared/components";

const JoinForum = () => (
  <CurvesSection className="home-page__forum">
    <div className="home-page__forum">
      <p className="heading heading--center"> Dołącz do dyskusji </p>
      <div className="paragraph">
        Znajdź odpowiedzi na nurtujące Cię pytania, dziel się swoimi
        doświadczeniami i poznaj nowych ludzi w naszej społeczności. Dołącz do
        dyskusji już teraz!
      </div>
      <Button label="Odkryj nasze forum" className="btn--normal" />
    </div>
  </CurvesSection>
);

export default JoinForum;
