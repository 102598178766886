import { connect } from "react-redux";
import * as ViewManagementActions from "store/actions/viewManagementActions";
import { getIsModalOpened } from "store/selectors/viewManagementSelector";
import { isMobile as checkIsMobile } from "shared/utils";
import ShopPage from "./ShopPage.main";
import { bindActionCreators } from "redux";
import { getAuthorizedUser as getAuthorizedUserSelector } from "store/selectors/userSelector";
import * as UserActions from "store/actions/user";

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getAuthorizedUser: UserActions.getAuthorizedUser,
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
    },
    dispatch
  ),
});

function mapStateToProps(state) {
  return {
    authorizedUser: getAuthorizedUserSelector(state),
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShopPage);
