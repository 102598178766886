/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { bool, func, shape, object } from "prop-types";
import { LoginManage } from "shared/components";
import { useHistory } from "react-router-dom";
import { ROUTES, LOGIN_MANAGE_TYPE } from "shared/consts";
import { HeaderMain, Basket } from "./components";

const NavBar = ({
  isModalOpened,
  isMobile,
  authorizedUser,
  actions: {
    getAuthorizedUser,
    logout,
    openModal,
    closeModal,
    blurredOn,
    blurredOff,
    checkToken,
    activateAccount,
  },
}) => {
  const [loginManageActions, setLoginManageActions] = useState(false);
  const [isBasketOpen, setIsBasketOpen] = useState(false);
  const isUserLogged = authorizedUser;
  const history = useHistory();
  useEffect(() => {
    !authorizedUser && getAuthorizedUser();
  }, [authorizedUser, getAuthorizedUser]);

  useEffect(async () => {
    const { search } = window.location;
    if (search.split("=")[0] === "?token") {
      const activateToken = JSON.stringify(search.split("=")[1]);
      const sendToken = activateToken.replace(/['"]+/g, "");
      const { token } = await checkToken(sendToken, "token");
      if (token) {
        const response = await activateAccount(activateToken);
        response?.user?.activate && history.push(ROUTES.ACTIVATE_PAGE);
      } else {
        history.push(ROUTES.INACTIVE_TOKEN);
      }
    } else if (search.split("=")[0] === "?reset_token") {
      const resetToken = JSON.stringify(search.split("=")[1]);
      const sendToken = resetToken.replace(/['"]+/g, "");
      const { token } = checkToken(sendToken, "reset_token");
      if (token) {
        setLoginManageActions(LOGIN_MANAGE_TYPE.NEW_PASSWORD);
        openModal();
        sessionStorage.setItem(
          "resetToken",
          JSON.stringify(search.split("=")[1])
        );
      } else {
        history.push(ROUTES.TOKEN_EXPIRED);
      }
    }
  }, [activateAccount, checkToken, history, openModal]);

  const handleOpenModal = (whichOne) => {
    setLoginManageActions(whichOne);
    openModal();
  };

  const closeBasket = () => {
    blurredOff();
    closeModal();
    setIsBasketOpen(false);
    const pageWrapper = document.getElementById("page__wrapper");
    pageWrapper.classList.remove("page__wrapper--stick");
  };

  return (
    <>
      <div className={`navBar__wrapper `}>
        <HeaderMain
          setIsBasketOpen={setIsBasketOpen}
          openModal={openModal}
          blurredOn={blurredOn}
          blurredOff={blurredOff}
          isMobile={isMobile}
          handleOpenModal={handleOpenModal}
          isUserLogged={isUserLogged}
          logout={logout}
          authorizedUser={authorizedUser}
        />
      </div>
      {isModalOpened && loginManageActions && (
        <LoginManage
          isModalOpened={isModalOpened}
          closeModal={closeModal}
          loginManageActions={loginManageActions}
        />
      )}
      {isModalOpened && isBasketOpen && <Basket closeBasket={closeBasket} />}
    </>
  );
};

export default NavBar;

NavBar.propTypes = {
  actions: shape({
    activateAccount: func.isRequired,
    getAuthUser: func.isRequired,
    logout: func.isRequired,
  }).isRequired,
  authorizedUser: object.isRequired,
  blurredOff: func.isRequired,
  blurredOn: func.isRequired,
  closeModal: func.isRequired,
  isMobile: bool.isRequired,
  isModalOpened: bool.isRequired,
  openModal: func.isRequired,
};
