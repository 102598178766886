import React from 'react';

const ClientHeaderTable = () => (
  <div className="table-header--wrapper client">
    <p className="table-header nickname">nick</p>
    <p className="table-header update-at">dołączył</p>
    <p className="table-header last-login">ostatnie logowanie</p>
    <p className="table-header buy-elements">
      kupionych <br />
      przedmiotów
    </p>
  </div>
);

export default ClientHeaderTable;
