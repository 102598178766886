import React, { useState } from 'react';
import { array, func } from 'prop-types';
import { SVG, Modal, ModalTitle, Button } from 'shared/components';
import { SVG_TYPE } from 'shared/consts';
import {
  CategoryItem,
  NewSubcategoryForm,
  SubcategoryItem,
  EditCategory,
  EditSubcategoryForm,
} from 'screens/category-page/components/category/components';

const CategoriesList = ({
  allCategories,
  removeCategory,
  createSubcategory,
  removeSubcategory,
  editCategory,
  editSubcategory,
}) => {
  const [modalManage, setModalManage] = useState({ isModalOpen: false });
  const [manageSubcategoryForm, setManageSubcategoryForm] = useState(false);

  const closeModal = async () => {
    setModalManage({ isModalOpen: false });
  };

  const openModal = (data, type) => {
    setModalManage({ isModalOpen: true, data, type });
  };

  const removeManage = async () => {
    if (modalManage.type === 'category') {
      await removeCategory(modalManage?.data?.uuid);
    } else {
      await removeSubcategory(modalManage?.data?.uuid, modalManage?.data?.category_uuid);
    }
    closeModal();
  };

  return (
    <>
      <Modal opened={modalManage.isModalOpen}>
        <ModalTitle title={`Czy na pewno chcesz usunąć ${modalManage.type === 'category' ? 'kategorię:' : 'subkategorię:'}`} />
        <div className="category-modal-remove">
          <p className="paragraph">
            <SVG type={SVG_TYPE.CATEGORY} />
            {modalManage?.data?.name}
          </p>
          <p className="paragraph">
            <SVG type={SVG_TYPE.URL} />
            {modalManage?.data?.link}
          </p>
        </div>
        <div className="modal-remove-buttons--wrapper">
          <div className="modal-remove-buttons--content">
            <Button label="usuń" className="btn--default" onClick={() => removeManage()} />
            <Button label="anuluj" className="btn--frame-dark" onClick={() => closeModal()} />
          </div>
        </div>
      </Modal>

      {allCategories?.length > 0 &&
        allCategories?.map((category, id) => {
          const { subcategories } = category;
          const isOpenForm = manageSubcategoryForm.isOpen && manageSubcategoryForm.id === id;
          const isEditOpen = manageSubcategoryForm?.isEditOpen && manageSubcategoryForm.id === id;
          return (
            <>
              {category && isEditOpen ? (
                <EditCategory
                  category={category}
                  editCategory={editCategory}
                  setManageSubcategoryForm={setManageSubcategoryForm}
                />
              ) : (
                <CategoryItem
                  id={id}
                  category={category}
                  openModal={openModal}
                  manageSubcategoryForm={manageSubcategoryForm}
                  setManageSubcategoryForm={setManageSubcategoryForm}
                />
              )}
              <ul className={`subcategory__list ${isOpenForm ? 'subcategory__list--active' : ''}`}>
                {isOpenForm && (
                  <>
                    <NewSubcategoryForm
                      setManageSubcategoryForm={setManageSubcategoryForm}
                      category={category}
                      createSubcategory={createSubcategory}
                      id={id}
                    />
                    {subcategories?.map((subcategory, index) => {
                      const isEditSubcategoryForm =
                        manageSubcategoryForm?.isEditSubcategory && manageSubcategoryForm.index === index;
                      return (
                        <>
                          {isEditSubcategoryForm ? (
                            <EditSubcategoryForm
                              editSubcategory={editSubcategory}
                              id={id}
                              subcategory={subcategory}
                              setManageSubcategoryForm={setManageSubcategoryForm}
                            />
                          ) : (
                            <SubcategoryItem
                              id={id}
                              subcategoryIndex={index}
                              subcategory={subcategory}
                              openModal={openModal}
                              setManageSubcategoryForm={setManageSubcategoryForm}
                            />
                          )}
                        </>
                      );
                    })}
                  </>
                )}
              </ul>
            </>
          );
        })}
    </>
  );
};

export default CategoriesList;

CategoriesList.propTypes = {
  allCategories: array.isRequired,
  createSubcategory: array.isRequired,
  editCategory: func.isRequired,
  editSubcategory: func.isRequired,
  removeCategory: func.isRequired,
  removeSubcategory: func.isRequired,
};
