import { connect } from "react-redux";
import * as ViewManagementActions from "store/actions/viewManagementActions";
import { getIsModalOpened } from "store/selectors/viewManagementSelector";
import { isMobile as checkIsMobile } from "shared/utils";
import CraftsPage from "./CraftsPage.main";
import { bindActionCreators } from "redux";
import { getAllCategoriesAndSubcategories } from "store/actions/category";
import { getCategoriesAndSubcategories as getCategoriesAndSubcategoriesSelector } from "store/selectors/categorySelector";

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      closeModal: ViewManagementActions.closeModal,
      openModal: ViewManagementActions.openModal,
      getAllCategoriesAndSubcategories: getAllCategoriesAndSubcategories,
    },
    dispatch
  ),
});

function mapStateToProps(state) {
  return {
    isModalOpened: getIsModalOpened(state),
    isMobile: checkIsMobile(),
    categoriesAndSubcategories: getCategoriesAndSubcategoriesSelector(state),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CraftsPage);
