import { useState } from "react";
import { SVG, LoginManage, Button } from "shared/components";
import { SVG_TYPE, LOGIN_MANAGE_TYPE } from "shared/consts";
import { string, object, func, bool } from "prop-types";
import { MyAccount } from "shared/components/menu-top/components";
import { useHistory } from "react-router-dom";

const DashboardTopBar = ({
  name,
  authorizedUser,
  logout,
  goBack,
  categoriesAndSubcategories,
  openModal,
  isModalOpened,
  closeModal,
}) => {
  const [loginManageActions, setLoginManageActions] = useState(false);
  const [loadingLogin, setLoadingLogin] = useState(false);

  const handleOpenModal = (whichOne) => {
    setLoginManageActions(whichOne);
    openModal();
  };

  const history = useHistory();
  return (
    <div className="dashboard-top-bar">
      <div className="dashboard-top-bar__content">
        <div className="dashboard-top-bar__left">
          <div className="dashboard-top-bar__left-heading">
            {goBack ? (
              <div onClick={() => history.goBack()}>
                {" "}
                <SVG
                  className="dashboard-top-bar__left--goBack"
                  type={SVG_TYPE.CHEVRONE}
                />
              </div>
            ) : (
              <SVG
                className="dashboard-top-bar__left--menu"
                type={SVG_TYPE.MENU}
              />
            )}
            <div className="dashboard-top-bar__left--name">{name}</div>
          </div>
        </div>
        <div className="dashboard-top-bar__right">
          {authorizedUser ? (
            <MyAccount
              authorizedUser={authorizedUser}
              logout={logout}
              className="dashboard-top-bar__profile"
              categoriesAndSubcategories={categoriesAndSubcategories}
            />
          ) : (
            <>
              {isModalOpened && loginManageActions && (
                <LoginManage
                  setLoadingLogin={setLoadingLogin}
                  isModalOpened={isModalOpened}
                  closeModal={closeModal}
                  loginManageActions={loginManageActions}
                />
              )}
              <Button
                onClick={() => handleOpenModal(LOGIN_MANAGE_TYPE.LOGIN)}
                className={`${
                  loadingLogin ? "btn--before" : "btn--after"
                } btn--frame-dark`}
                iconType={`${loadingLogin ? SVG_TYPE.LOADING : ""}`}
                label={`${loadingLogin ? "" : "Zaloguj się"}`}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default DashboardTopBar;

DashboardTopBar.defaultProps = {
  goBack: null,
};

DashboardTopBar.propTypes = {
  openModal: func.isRequired,
  isModalOpened: bool.isRequired,
  name: string.isRequired,
  authorizedUser: object.isRequired,
  logout: func.isRequired,
  goBack: bool,
};
