import { CATEGORY } from 'store/types';
// import { REQUEST_STATUS } from 'shared/consts';

const INITIAL_STATE = {
  categoriesAndSubcategories: [],
  categories: [],
  singleCategory: [],
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case CATEGORY.GET_SINGLE_CATEGORY_INIT: {
      return {
        ...state,
        singleCategory: [],
      };
    }
    case CATEGORY.GET_SINGLE_CATEGORY_SUCCESS: {
      return {
        ...state,
        singleCategory: payload.data,
      };
    }
    case CATEGORY.GET_SINGLE_CATEGORY_FAILURE: {
      return {
        ...state,
        singleCategory: [],
      };
    }
    case CATEGORY.GET_ALL_CATEGORIES_AND_SUBCATEGORIES_INIT: {
      return {
        ...state,
        categoriesAndSubcategories: [],
      };
    }
    case CATEGORY.GET_ALL_CATEGORIES_AND_SUBCATEGORIES_SUCCESS: {
      return {
        ...state,
        categoriesAndSubcategories: payload.data,
      };
    }
    case CATEGORY.GET_ALL_CATEGORIES_AND_SUBCATEGORIES_FAILURE: {
      return {
        ...state,
        categoriesAndSubcategories: [],
      };
    }
    case CATEGORY.GET_ALL_CATEGORIES_INIT: {
      return {
        ...state,
        categories: [],
      };
    }
    case CATEGORY.GET_ALL_CATEGORIES_SUCCESS: {
      return {
        ...state,
        categories: payload.data,
      };
    }
    case CATEGORY.GET_ALL_CATEGORIES_FAILURE: {
      return {
        ...state,
        categories: [],
      };
    }
    case CATEGORY.ADD_CATEGORY_SUCCESS: {
      state.categoriesAndSubcategories.push({ ...payload.data, subcategories: [] });
      return {
        ...state,
        categoriesAndSubcategories: state.categoriesAndSubcategories,
      };
    }
    case CATEGORY.ADD_SUBCATEGORY_SUCCESS: {
      const { category_uuid } = payload.data;
      const category = state.categoriesAndSubcategories.find(item => item.uuid === category_uuid);
      category.subcategories.push(payload.data);
      return {
        ...state,
        categoriesAndSubcategories: state.categoriesAndSubcategories,
      };
    }
    case CATEGORY.REMOVE_CATEGORY_SUCCESS: {
      return {
        ...state,
        categoriesAndSubcategories: state.categoriesAndSubcategories.filter(({ uuid }) => uuid !== payload.data),
      };
    }
    case CATEGORY.REMOVE_SUBCATEGORY_SUCCESS: {
      const category = state.categoriesAndSubcategories.find(({ uuid }) => uuid === payload.categoryUuid);
      const filterSubcategory = category.subcategories.filter(({ uuid }) => uuid !== payload.subcategoryUuid);
      category.subcategories = filterSubcategory;
      return {
        ...state,
        categoriesAndSubcategories: [...state.categoriesAndSubcategories],
      };
    }
    case CATEGORY.EDIT_CATEGORY_SUCCESS: {
      const category = state.categoriesAndSubcategories.find(({ uuid }) => uuid === payload.uuid);
      category.name = payload.data.category.name;
      category.link = payload.data.category.link;

      return {
        ...state,
        categoriesAndSubcategories: [...state.categoriesAndSubcategories],
      };
    }
    case CATEGORY.EDIT_SUBCATEGORY_SUCCESS: {
      const { category_uuid, uuid } = payload.data.subcategory;
      const category = state.categoriesAndSubcategories.find(item => item.uuid === category_uuid);
      const subcategory = category.subcategories.filter(item => item.uuid === uuid);
      subcategory[0].name = payload.data.subcategory.name;
      subcategory[0].link = payload.data.subcategory.link;
      return {
        ...state,
        categoriesAndSubcategories: [...state.categoriesAndSubcategories],
      };
    }
    default:
      return state;
  }
};

export default categoryReducer;
