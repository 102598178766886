import React from "react";
import Face from "assets/files/face.jpg";
import { SVG } from "shared/components";
import { SVG_TYPE } from "shared/consts";

const usersData = [
  { username: "Artsynp", comments: 67 },
  { username: "Perejr", comments: 42 },
  { username: "Kankul", comments: 35 },
  { username: "Juras", comments: 30 },
  { username: "Magda", comments: 20 },
  // Dodaj więcej użytkowników z ich komentarzami tutaj
];

const ForumTopUsers = () => {
  return (
    <div className="forum-top-users">
      <div className="forum-page__header">Najaktywniejsi</div>
      <div className="forum-top-users__content">
        {usersData.map((userData, index) => (
          <div key={index} className="forum-top-user__item">
            <div className="forum-top-user">
              <div className="forum-top-user__avatar">
                <img src={Face} alt="" />
              </div>
              <div className="forum-top-user__name">{userData.username}</div>
            </div>
            <div className="forum-top-user__comment">
              <div className="forum-top-user__comment-icon">
                <SVG type={SVG_TYPE.COMMENT} />
              </div>
              <div className="forum-top-user__comment-text">
                {userData.comments}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ForumTopUsers;
