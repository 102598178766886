import { func, array } from "prop-types";
import { useEffect, useState } from "react";
import ClientPhoto from "assets/files/client_3.png";
import CraftsmanPhoto from "assets/files/craftsman_1.png";
import PartnerPhoto from "assets/files/partner_1.png";
import { Button } from "shared/components";
import { UserTypeModal } from "screens/dashboard/components";
import { USER_TYPE } from "shared/consts";

const ProfileUserTypeView = ({
  setAccountType,
  categories,
  getAllCategories,
}) => {
  const [manageModal, setManageModal] = useState({ isOpen: false, type: null });
  const isCraftsmanSelected = manageModal.type === USER_TYPE.CRAFTSMAN;

  useEffect(() => {
    isCraftsmanSelected && categories.length <= 0 && getAllCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllCategories, manageModal, isCraftsmanSelected]);

  return (
    <>
      <UserTypeModal
        setAccountType={setAccountType}
        manageModal={manageModal}
        setManageModal={setManageModal}
      />
      <div className="profile-user-type-views">
        <div className="profile-user-type-view">
          <p className="profile-user-type-view__heading"> Witaj! </p>
          <p className="profile-user-type-view__text profile-user-type-view__text--mid">
            {" "}
            Witaj w naszej społeczności! Przed Tobą ważna decyzja, która pomoże
            nam lepiej poznać Twoje potrzeby. Proszę, wybierz jeden z trzech
            typów użytkowników, który najlepiej odpowiada Twoim celom:
          </p>
          <p className="profile-user-type-view__text profile-user-type-view__text--bottom">
            Wybierz swój typ użytkownika i zacznij korzystać z naszej strony na
            pełnej mocy!{" "}
          </p>
        </div>
        <div className="profile-user-type-carts">
          <div className="profile-user-type-cart">
            <div className="profile-user-type-cart__img-wrapper">
              <img
                className="profile-user-type-cart__img"
                src={ClientPhoto}
                alt="client-shop"
                border="0"
              />
            </div>
            <div className="profile-user-type-cart--descript">
              <div className="profile-user-type-cart__heading">Klient</div>
              <div className="profile-user-type-cart__text">
                Jeśli interesuje Cię robienie zakupów od rzemieślników oraz
                partnerów, wybierz tę opcję. Będziesz mógł przeglądać i nabywać
                unikalne wyroby rękodzielnicze oraz surowce.
              </div>
              <div className="profile-user-type-cart__button">
                <Button
                  className="btn--frame-dark"
                  label="zobacz szczegóły"
                  onClick={() =>
                    setManageModal({ isOpen: true, type: USER_TYPE.CLIENT })
                  }
                />{" "}
              </div>
            </div>
          </div>
          <div className="profile-user-type-cart">
            <div className="profile-user-type-cart__img-wrapper">
              <img
                className="profile-user-type-cart__img"
                src={CraftsmanPhoto}
                alt="client-shop"
                border="0"
              />
            </div>
            <div className="profile-user-type-cart--descript">
              <div className="profile-user-type-cart__heading">Rzemieślnik</div>
              <div className="profile-user-type-cart__text">
                Jesteś twórczym duchem i wykonujesz prace rękodzielnicze?
                Wybierz tę opcję, a będziesz mógł wystawić swoje wyroby w naszym
                sklepie oraz nabywać surowce od partnerów do swoich projektów.
              </div>
              <div className="profile-user-type-cart__button">
                <Button
                  className="btn--frame-dark"
                  label="zobacz szczegóły"
                  onClick={() =>
                    setManageModal({ isOpen: true, type: USER_TYPE.CRAFTSMAN })
                  }
                />{" "}
              </div>
            </div>
          </div>
          <div className="profile-user-type-cart">
            <div className="profile-user-type-cart__img-wrapper">
              <img
                className="profile-user-type-cart__img"
                src={PartnerPhoto}
                alt="client-shop"
                border="0"
              />
            </div>
            <div className="profile-user-type-cart--descript">
              <div className="profile-user-type-cart__heading">Partner</div>
              <div className="profile-user-type-cart__text">
                Jeżeli prowadzisz działalność handlową opartą na sprzedaży
                surowców potrzebnych do wykonywania prac przez rzemieślników, ta
                opcja jest dla Ciebie. Zyskasz dostęp do naszej platformy, aby
                promować swoje produkty i współpracować z rzemieślnikami.
              </div>
              <div className="profile-user-type-cart__button">
                <Button
                  className="btn--frame-dark"
                  label="zobacz szczegóły"
                  onClick={() =>
                    setManageModal({ isOpen: true, type: USER_TYPE.PARTNER })
                  }
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileUserTypeView;

ProfileUserTypeView.propTypes = {
  setAccountType: func.isRequired,
  getAllCategories: func.isRequired,
  categories: array.isRequired,
};
